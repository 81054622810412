import React from 'react'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, LabelList } from 'recharts'
import { BLUE, BLACK } from '../../shared/constants/pdf'

const DefectBarChart = ({ data, maxDefect, chartId, formatterPercentage }) => (
  <div className="chartContainer">
    <ResponsiveContainer id={chartId} minWidth={872.5} minHeight={400}>
      <BarChart width={800} height={200} data={data} layout="vertical">
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="percentage"
          type="number"
          style={{ fill: 'black', fontSize: '150%' }}
          tickSize={10}
          tickFormatter={(value) => `${Number(Number(value).toFixed(1))}%`}
          domain={[0, maxDefect]}
        />
        <YAxis dataKey="defect" type="category" width={300} style={{ fill: 'black', fontSize: '140%' }} />
        <Tooltip formatter={(value) => `${value}%`} />
        <Bar dataKey="percentage" fill={BLUE}>
          <LabelList
            dataKey="percentage"
            fill={BLACK}
            position="right"
            style={{ fill: 'black', fontSize: '150%' }}
            formatter={(value) => formatterPercentage(Number(Number(value).toFixed(1)))}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  </div>
)

export default DefectBarChart
