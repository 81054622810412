import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormControl, MenuItem, Typography, TextField, Grid } from '@material-ui/core'
import { DateTime } from 'luxon'
import PrimaryButton from '../button/button.component'
import SingleDatePicker from '../single-datepicker/simple-datepicker.component'
import { TIME_OF_UNIT } from '../../shared/utils/datetime-utils'
import useStyles from './downTimeCalculate.style'
import ExportExcelButton from '../export-excel-button/export-excel-button.component'

const DownTimeCalculate = React.memo(
  ({
    totalDownTimes,
    handleTimeOfUnitChange,
    selectedYear,
    setSelectedYear,
    handleSelectedYearChange,
    printfReportExcel,
    showReport,
    loadData,
    setIsFilter,
  }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const currentYear = new Date().getFullYear().toString()
    const [isLoading, setIsLoading] = useState(false)
    const [startDateISODate, setStartDateISODate] = useState(DateTime.now().startOf('day').toISODate())
    const [endDateISODate, setEndDateISODate] = useState(DateTime.now().endOf('day').toISODate())
    const [startDate, setStartDate] = useState(DateTime.now().startOf('day').toISO({ includeOffset: false }))
    const [endDate, setEndDate] = useState(DateTime.now().endOf('day').toISO({ includeOffset: false }))
    // Create an array containing the years from the current year back 4 years
    const years = Array.from({ length: 4 }, (_, i) => (new Date().getFullYear() - i).toString())

    const handleChange = (event) => {
      if (handleTimeOfUnitChange) {
        handleTimeOfUnitChange(event.target.value)
      }
    }
    const handleYearChange = (event) => {
      setIsFilter(false)
      setSelectedYear(event.target.value)
      if (handleSelectedYearChange) {
        handleSelectedYearChange(event.target.value)
      }
    }
    return (
      <>
        <div className={classes.container2}>
          <SingleDatePicker
            disabled={isLoading}
            style={{ width: '100%' }}
            label={t('start_date')}
            name="startDate"
            value={startDate}
            disableFuture
            onChange={(date) => {
              const startDate = DateTime.fromJSDate(date)
              setStartDateISODate(startDate.toISODate())
              setStartDate(startDate.toISO({ includeOffset: false }))
            }}
          />
          <SingleDatePicker
            disabled={isLoading}
            style={{ width: '100%' }}
            label={t('end_date')}
            name="endDate"
            value={endDate}
            disableFuture
            onChange={(date) => {
              const endDate = DateTime.fromJSDate(date)
              setEndDateISODate(endDate.toISODate())
              setEndDate(endDate.toISO({ includeOffset: false }))
            }}
          />
          <PrimaryButton
            variant="contained"
            disabled={isLoading}
            onClick={() => {
              setIsFilter(true)
              if (endDateISODate >= startDateISODate) {
                loadData(startDateISODate, endDateISODate)
              } else {
                alert('Start date must be less than end date')
              }
            }}
          >
            {t('filter')}
          </PrimaryButton>
        </div>
        <div className={classes.container}>
          <Typography>{t('year')}:</Typography>
          <Box sx={{ minWidth: 100, marginRight: 30 }}>
            <FormControl fullWidth>
              <TextField
                size="small"
                variant="outlined"
                style={{ width: 'fit-content' }}
                select
                defaultValue={currentYear}
                onChange={handleYearChange}
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year === currentYear ? t('current') : year}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Box>
          <Typography>{t('total_downtime')}:</Typography>
          <TextField size="small" variant="outlined" value={totalDownTimes} style={{ width: 160 }} />
          <Box sx={{ minWidth: 100 }}>
            <FormControl fullWidth>
              <TextField
                id="outlined-select-currency"
                select
                variant="outlined"
                size="small"
                defaultValue={TIME_OF_UNIT.DAY}
                disabled={selectedYear !== currentYear}
                onChange={handleChange}
              >
                <MenuItem value={TIME_OF_UNIT.DAY}>{t('day')}</MenuItem>
                <MenuItem value={TIME_OF_UNIT.WEEK}>{t('week')}</MenuItem>
                <MenuItem value={TIME_OF_UNIT.SEASON}>{t('season')}</MenuItem>
              </TextField>
            </FormControl>
          </Box>
        </div>
        {!showReport ? null : <ExportExcelButton printfReportExcel={printfReportExcel} />}
      </>
    )
  }
)

export default DownTimeCalculate
