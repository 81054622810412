import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  lotText: {
    marginTop: 5,
    marginBottom: 15,
  },
  videoStreamContainer: {
    border: '2px solid black',
    marginTop: 12,
  },
  videoStreamHeader: {
    padding: '8px 12px',
    borderBottom: '2px solid black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  videoStreamBody: {
    height: 400,
    background: '#000000',
  },
  statisticContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
  },
  inboundContainer: {
    flex: '1',
  },
  outboundContainer: {
    flex: '1',
  },
  statisticValueContainer: {
    height: 275,
    borderRadius: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 70,
    fontWeight: 700,
    background: '#2a3858',
    color: '#ffffff',
  },
})

export default useStyles
