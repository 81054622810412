// label will use to access key from translation.json

export const MENU_ITEMS = [
  { name: 'dashboard', label: 'dash_board', icon: 'dashboard', url: '/dashboard' },
  {
    name: 'packman_dashboard',
    label: 'packman_dash_board',
    icon: 'dashboard',
    url: '/packman_dashboard',
  },
  {
    name: 'defect_parameters',
    label: 'defect_parameters',
    icon: 'assessment',
    url: '/defect_parameters',
  },
  {
    name: 'quality_matrix',
    label: 'quality_matrix',
    icon: 'grid_on',
    url: '/quality_matrix',
  },
  {
    name: 'sizing_matrix',
    label: 'sizing_matrix',
    icon: 'grid_on',
    url: '/sizing_matrix',
  },
  {
    name: 'image_capture',
    label: 'image_capture',
    icon: 'grid_on',
    url: '/image_capture',
  },
  {
    name: 'tracking_report',
    label: 'tracking_report',
    icon: 'grid_on',
    url: '/tracking_report',
  },
  {
    name: 'staff',
    label: 'staff_performance',
    icon: 'people',
    url: '/staff',
  },
  {
    name: 'lot_information',
    label: 'lot_information',
    icon: 'description',
    url: '/lot_information',
  },
  { name: 'downtimes', label: 'down_time', icon: 'history_toggle_off', url: '/downtimes' },
  { name: 'reports', label: 'reports', icon: 'pie_chart', url: '/reports' },
  { name: 'lottimes', label: 'lot_times', icon: 'av_timer', url: '/lottimes' },
  {
    name: 'params',
    label: 'general_parameters',
    icon: 'vertical_split',
    children: [
      {
        name: 'category_type',
        label: 'category_type',
        url: '/category_type',
      },
      {
        name: 'category',
        label: 'category',
        url: '/category',
      },
      {
        name: 'unit_type',
        label: 'unit_type',
        url: '/unit_type',
      },
      {
        name: 'unit',
        label: 'unit',
        url: '/unit',
      },
    ],
    open: false,
  },
  {
    name: 'business',
    label: 'business_parameters',
    icon: 'vertical_split',
    open: false,
    children: [
      { name: 'packman', label: 'Packman', url: '/packman' },
      {
        name: 'region',
        label: 'region',
        url: '/region',
      },
      {
        name: 'facility',
        label: 'facility',
        url: '/facility',
      },
      {
        name: 'location',
        label: 'location',
        url: '/location',
      },
      {
        name: 'outlet',
        label: 'outlet',
        url: '/outlet',
      },
      {
        name: 'grower',
        label: 'grower',
        url: '/grower',
      },
      {
        name: 'size',
        label: 'size',
        url: '/size',
      },
      {
        name: 'defect',
        label: 'defect',
        url: '/defect',
      },
      {
        name: 'variety',
        label: 'variety',
        url: '/variety',
      },
      {
        name: 'color',
        label: 'color',
        url: '/color',
      },
      {
        name: 'stemcondition',
        label: 'stem_condition',
        url: '/stemcondition',
      },
      {
        name: 'sampletype',
        label: 'sample_type',
        url: '/sampletype',
      },
      {
        name: 'packtype',
        label: 'pack_type',
        url: '/packtype',
      },
      {
        name: 'machine',
        label: 'machine',
        url: '/machine',
      },
      {
        name: 'user',
        label: 'users',
        url: '/user',
      },
      {
        name: 'shift',
        label: 'shifts',
        url: '/shift',
      },
    ],
  },
  { name: 'alertconfig', label: 'alert_config', icon: 'speaker_notes', url: '/alertconfig' },
  { name: 'logs', label: 'logs', icon: 'notes', url: '/logs' },
  {
    name: 'help',
    label: 'help',
    icon: 'question_mark_icon ',
    url: '/help',
  },
]
