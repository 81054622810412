import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import * as XLSXStyle from 'xlsx-js-style'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'

import PrintButton from '../../../components/print-button/print-button.component'
import ReportInputs from '../../../components/report-inputs/report-inputs.component'
import SimpleBackdrop from '../../../components/simple-backdrop/simple-backdrop.component'
import AppInsightsTrackingContext from '../../../context/app-insights-tracking/AppInsightsTrackingContext'

import PdfMakerService from '../../../services/pdf-maker/pdf-maker-service'
import RequestService from '../../../services/request/request-service'

import makeStyles from '../reports.styles'
import { numberToPercentage } from '../../../shared/utils/utils'

import {
  GRADE_BY_SIZE,
  LOTS_FACILITIES_QUERY,
  LOTS_VARIETIES_QUERY,
  MACHINE_QUERY,
  FACILITY_QUERY,
  SIZE_QUERY,
} from '../../../shared/constants/queries'

const GradeBySize = ({
  currentFacility,
  selectedLot,
  selectedLotName,
  selectedVariety,
  selectedVarietyName,
  selectedGrowerName,
  selectedStartDate,
  selectedEndDate,
  selectedMachine,
  desTemplates,
  userEmail,
}) => {
  const classes = makeStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const { trackEvent } = useContext(AppInsightsTrackingContext)
  const [showReport, setShowReport] = useState(false)
  const [showBackdrop, setShowBackdrop] = useState(false)
  const [reportDate] = useState(DateTime.now().toFormat('LL-dd-yy'))
  const [generalData, setGeneralData] = useState({})
  const [lots, setLots] = useState([])
  const [varieties, setVarieties] = useState([])
  const [machines, setMachines] = useState([])
  const [facilities, setFacilities] = useState([])
  const [sizes, setSizes] = useState([])
  const [finishedBox, setFinishedBox] = useState(true)
  const [totalFinishedBox, setTotalFinishedBox] = useState(0)
  const [totalInline, setTotalInline] = useState(0)

  const tableCellHeaderClassNames = `${classes.tableCell} ${classes.tableCellLabel}`
  const tableCellClassNames = `${classes.tableCell}`
  useEffect(() => {
    if (selectedVariety === 0 || selectedLot === 0) {
      setShowReport(false)
    }
  }, [selectedLot, selectedVariety])

  const loadReport = async () => {
    try {
      setShowBackdrop(true)
      trackEvent('FILTE_BUTTON_CLICK', { email: userEmail })
      // eslint-disable-next-line max-len
      const url = `${GRADE_BY_SIZE}?id_lot=${selectedLot}&id_variety=${selectedVariety}&id_facility=${currentFacility.id}&start_date=${selectedStartDate}Z&end_date=${selectedEndDate}Z&id_machine=${selectedMachine.id}`
      const response = await RequestService.Get(url, history)
      const { finish_box_quality, inline_quality } = response.data
      setGeneralData({
        general: {
          varietyName: selectedVarietyName,
          id_machine: selectedMachine.id,
          lotName: selectedLotName,
          id_facility: currentFacility.id,
          finish_box_quality,
          inline_quality,
          date_ranger: `${DateTime.fromISO(selectedStartDate).toFormat('MM-dd-yy')} to ${DateTime.fromISO(
            selectedEndDate
          ).toFormat('MM-dd-yy')} `,
        },
      })
      let totalFinishedBox = 0
      if (finish_box_quality.length > 0) {
        finish_box_quality.forEach((x) => {
          totalFinishedBox += x.piece ?? 0
        })
      }
      let totalInline = 0
      if (inline_quality.length > 0) {
        inline_quality.forEach((x) => {
          totalInline += x.piece ?? 0
        })
      }

      setTotalFinishedBox(totalFinishedBox)
      setTotalInline(totalInline)
      setShowBackdrop(false)
      setShowReport(true)
    } catch (error) {
      console.log(error)
    }
  }

  const printDocument = () => {
    if (!generalData?.general) return
    trackEvent('PRINT_GRADE_BY_SIZE_REPORT_CLICK', { email: userEmail })
    PdfMakerService.generateReport(
      `GradeBySize_${lots.find((x) => x.id === generalData.general?.id_lot)?.name ?? ''}_${
        varieties.find((x) => x.id === generalData.general?.id_variety)?.name ?? ''
      }_${generalData.general?.date_ranger}`,
      'GradeBySizeSummary',
      {
        ...generalData,
        facilities,
        machines,
        lots,
        varieties,
        sizes,
        totalInline,
        totalFinishedBox,
        desTemplates,
      }
    )
  }

  useEffect(async () => {
    const loadData = async () => {
      try {
        // eslint-disable-next-line max-len
        let url = `${LOTS_FACILITIES_QUERY}?id=${currentFacility.id}&filterByFirst=false&startRowIndex=0&pageSize=100000&includeInactive=false`
        const tskLots = RequestService.Get(url, history)

        url = `${MACHINE_QUERY}/getmachinesbyidfacility?id_facility=${currentFacility.id}`
        const tskMachine = RequestService.Get(url, history)

        url = `${FACILITY_QUERY}`
        const tskFacility = RequestService.Get(url, history)

        url = `${SIZE_QUERY}`
        const tskSize = RequestService.Get(url, history)

        const [resLots, resMachine, resFacility, resSize] = await Promise.all([
          tskLots,
          tskMachine,
          tskFacility,
          tskSize,
        ])

        await setLots(resLots.data.map((lot) => lot.first).filter((x) => x.active === true))
        setMachines(resMachine.data)
        setFacilities(resFacility.data)
        setSizes(resSize.data)
      } catch (error) {
        console.error(error)
      }
    }
    await loadData()
  }, [])

  useEffect(async () => {
    const loadData = async () => {
      try {
        const url = `${LOTS_VARIETIES_QUERY}?id=${selectedLot}&startRowIndex=0&pageSize=100000`
        const response = await RequestService.Get(url, history)
        setVarieties(response.data.map((variety) => variety.second).filter((x) => x.active === true))
      } catch (error) {
        console.error(error)
      }
    }
    if (selectedLot) await loadData()
  }, [selectedLot])

  const printfReportExcel = () => {
    trackEvent('EXPORT-EXCEL_GRADE-BY_SIZE_REPORT_CLICK', { email: userEmail })
    const inlineQuality = []
    const finishBoxQuality = []
    if (generalData.general.inline_quality.length > 0) {
      const header = [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Total Sample',
        },
      ]
      generalData.general.inline_quality.forEach((item) => {
        header.push({
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: sizes.find((x) => x.id === item.id_sample_size)?.description ?? '-',
        })
      })
      inlineQuality.push(header)

      const body = [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: totalInline || 0,
        },
      ]
      generalData.general.inline_quality.forEach((item) => {
        body.push({
          t: 's',
          s: { font: { sz: 11 } },
          v: numberToPercentage(((item?.correct_percentage || 0) * 100).toFixed(2)),
        })
      })
      inlineQuality.push(body)
    }
    if (generalData.general.finish_box_quality.length > 0) {
      const header = [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Total Sample',
        },
      ]
      generalData.general.finish_box_quality.forEach((item) => {
        header.push({
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: sizes.find((x) => x.id === item.id_sample_size)?.description ?? '-',
        })
      })
      finishBoxQuality.push(header)

      const body = [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: totalFinishedBox || 0,
        },
      ]
      generalData.general.finish_box_quality.forEach((item) => {
        body.push({
          t: 's',
          s: { font: { sz: 11 } },
          v: numberToPercentage(((item?.correct_percentage || 0) * 100).toFixed(2)),
        })
      })
      finishBoxQuality.push(body)
    }

    const sizeDistributionData = [
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Facility',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: facilities.find((x) => x.id === currentFacility.id)?.description ?? '-',
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Machine',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: machines.find((x) => x.id === generalData.general?.id_machine)?.name ?? '-',
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Lot #',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: generalData.general.lotName,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Variety',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: generalData.general?.varietyName,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Date Range',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: generalData.general?.date_ranger,
        },
      ],
      [],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: `Grade by Size ${desTemplates.TEMPLATE_LOC_INL || 'Inline'} Summary`,
        },
      ],
      ...inlineQuality,
      [],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: `Grade by Size ${desTemplates.TEMPLATE_LOC_FIN || 'Finished Box'} Summary`,
        },
      ],
      ...finishBoxQuality,
    ]

    const sizeDistribution = XLSXStyle.utils.aoa_to_sheet(sizeDistributionData)
    const sizeDistributionCols = [{ wch: 20 }, { wch: 20 }]
    sizeDistribution['!cols'] = sizeDistributionCols
    const excelFile = XLSXStyle.utils.book_new()
    XLSXStyle.utils.book_append_sheet(excelFile, sizeDistribution, 'Grade By Size Summary')
    XLSXStyle.writeFile(
      excelFile,
      `GradeBySize_${lots.find((x) => x.id === generalData.general?.id_lot)?.name ?? ''}_${
        varieties.find((x) => x.id === generalData.general?.id_variety)?.name ?? ''
      }_${generalData.general?.date_ranger}.xlsx`
    )
  }

  return (
    <Grid container>
      {!showReport ? null : <PrintButton printDocument={printDocument} printfReportExcel={printfReportExcel} />}
      <ReportInputs loadData={loadReport} isLoading={showBackdrop} />
      {!showReport ? null : (
        <>
          <Grid xs={12} item>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow key="facility">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('facility')}</TableCell>
                    <TableCell className={classes.tableCell} colSpan={3}>
                      {facilities.find((x) => x.id === currentFacility.id)?.description ?? '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow key="machine">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('machine')}</TableCell>
                    <TableCell className={classes.tableCell}>
                      {machines.find((x) => x.id === generalData.general?.id_machine)?.name ?? '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow key="lotNumber">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('lot')} #</TableCell>
                    <TableCell className={classes.tableCell}>{generalData.general.lotName}</TableCell>
                  </TableRow>

                  <TableRow key="variety">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('variety')}</TableCell>
                    <TableCell className={classes.tableCell}>{generalData.general?.varietyName}</TableCell>
                  </TableRow>
                  <TableRow key="reportDate">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('date_range')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{generalData.general?.date_ranger}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid xs={12} item>
            <Typography align="center" variant="h6">
              {t('grade_by_size')} {desTemplates.TEMPLATE_LOC_INL || t('in_line')} {t('summary')}
            </Typography>
          </Grid>

          <Grid xs={12} item className={classes.grid}>
            <TableContainer>
              {generalData.general.inline_quality.length > 0 ? (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell className={`${classes.tableCell}`}>Total Sample</TableCell>
                      {generalData.general.inline_quality.map((item, index) => {
                        const classNames = tableCellHeaderClassNames

                        return (
                          <TableCell key={`${index}`} className={classNames} align="center">
                            {sizes.find((x) => x.id === item.id_sample_size)?.description ?? '-'}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                        {totalInline || 0}
                      </TableCell>
                      {generalData.general.inline_quality.map((item, index) => {
                        return (
                          <TableCell
                            key={`${index}-${item.correct_percentage}`}
                            className={tableCellClassNames}
                            align="center"
                          >
                            {numberToPercentage(((item?.correct_percentage || 0) * 100).toFixed(2))}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableBody>
                </Table>
              ) : (
                <div className={classes.noDataContainer}>
                  <Typography align="center" variant="h6">
                    {t('no_data')}.
                  </Typography>
                </div>
              )}
            </TableContainer>
          </Grid>
          <Grid xs={12} item>
            <Typography align="center" variant="h6">
              {t('grade_by_size')} {desTemplates.TEMPLATE_LOC_FIN || t('finished_box')} {t('summary')}
            </Typography>
          </Grid>
          <Grid xs={12} item className={classes.grid}>
            <TableContainer>
              {generalData.general.finish_box_quality.length > 0 ? (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell className={`${classes.tableCell}`}>{t('total_samples')}</TableCell>
                      {generalData.general.finish_box_quality.map((item, index) => {
                        const classNames = tableCellHeaderClassNames

                        return (
                          <TableCell key={`${index}`} className={classNames} align="center">
                            {sizes.find((x) => x.id === item.id_sample_size)?.description ?? '-'}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                        {totalFinishedBox || 0}
                      </TableCell>
                      {generalData.general.finish_box_quality.map((item, index) => {
                        return (
                          <TableCell
                            key={`${index}-${item.correct_percentage}`}
                            className={tableCellClassNames}
                            align="center"
                          >
                            {numberToPercentage(((item?.correct_percentage || 0) * 100).toFixed(2))}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableBody>
                </Table>
              ) : (
                <div className={classes.noDataContainer}>
                  <Typography align="center" variant="h6">
                    {t('no_data')}.
                  </Typography>
                </div>
              )}
            </TableContainer>
          </Grid>
        </>
      )}
      <SimpleBackdrop open={showBackdrop} />
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  selectedLot: state.reports.selectedLot,
  selectedLotName: state.reports.selectedLotName,
  selectedVariety: state.reports.selectedVariety,
  selectedVarietyName: state.reports.selectedVarietyName,
  selectedGrowerName: state.reports.selectedGrowerName,
  selectedStartDate: state.reports.selectedStartDate,
  selectedEndDate: state.reports.selectedEndDate,
  currentFacility: state.facility.currentFacility,
  selectedMachine: state.reports.selectedMachine,
  userEmail: state.user.email,
})

export default connect(mapStateToProps)(GradeBySize)
