/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { ServiceBusClient } from '@azure/service-bus'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import ReactHlsPlayer from 'react-hls-player'
import { ImageAnnotator, useImageAnnotator } from 'react-image-label'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LinearProgressWithLabel from '../../components/linear-progress-with-label/linear-progress-with-label'
import imageCaptureStyles from './image_capture.styles'
import RequestService from '../../services/request/request-service'
import { AB_SUBSCRIPTION_QUERY, UPLOAD_PACKMAN_QUERY } from '../../shared/constants/queries'

const categories = ['Double', 'Spur', 'Major Defect', 'Good Fruit']

const Dialog = ({ items, itemsChanged, onEdit, onDelete, onClose, offset }) => {
  const classes = imageCaptureStyles()

  const handleCheck = (event) => {
    let selected = items
    if (event.target.checked) selected = [...selected, event.target.value]
    else selected = selected.filter((item) => item !== event.target.value)
    selected.sort((c1, c2) => categories.indexOf(c1) - categories.indexOf(c2))
    itemsChanged(selected)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClose()
    }
  }

  const handleInnerKeyDown = (e) => {
    if (e.key === 'Escape') {
      e.stopPropagation()
    }
  }

  return (
    <div
      className={classes.dialog_bg}
      onClick={onClose}
      onKeyDown={handleKeyDown}
      role="dialog"
      aria-modal="true"
      tabIndex={0}
    >
      <div
        className={classes.dialog}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={handleInnerKeyDown}
        style={{ left: offset.X, top: offset.Y }}
        role="document"
      >
        <button type="button" onClick={onEdit}>
          Edit
        </button>
        <button type="button" onClick={onDelete}>
          Delete
        </button>
        {categories.map((category, index) => (
          <div key={index}>
            <input
              id={`chb${index}`}
              value={category}
              type="checkbox"
              onChange={handleCheck}
              checked={items.includes(category)}
            />
            <label htmlFor={`chb${index}`}>{category}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

const ImageLabeler = () => {
  const history = useHistory()
  const { setHandles, annotator } = useImageAnnotator()
  const [shapes, setShapes] = useState(null)
  const [dialog, setDialog] = useState({ show: false, shape: undefined })
  const [img, setImg] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const [isCapturing, setIsCapturing] = useState(false)
  const [capturedImages, setCapturedImages] = useState([])
  const [selectedImageIndex, setSelectedImageIndex] = useState(null)
  const [modelDetectionResults, setModelDetectionResults] = useState([])
  const [annotatorKey, setAnnotatorKey] = useState(0)
  const { t } = useTranslation()
  const [status, setStatus] = useState('')
  const [progress, setProgress] = useState({})
  const [showProgress, setShowProgress] = useState(false)
  const classes = imageCaptureStyles()
  const videoRef = useRef(null)
  const [serviceBusClient, setServiceBusClient] = useState(null)
  const [sender, setSender] = useState(null)
  const [isServiceBusReady, setIsServiceBusReady] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [autoTrainEnabled, setAutoTrainEnabled] = useState(false)
  const [queueName] = useState(process.env.REACT_APP_SERVICE_BUS_DA_SERVER_QUEUE_NAME)
  const [topicName, setTopicName] = useState('')
  const [subscriptionName, setSubscriptionName] = useState('')
  const LIVE_CAMERA_URL = process.env.REACT_APP_LIVE_CAMERA_URL

  useEffect(() => {
    const startVideo = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true })
        if (videoRef.current) {
          videoRef.current.srcObject = stream
        }
      } catch (error) {
        console.error('Error accessing webcam:', error)
      }
    }
    startVideo()
  }, [])

  useEffect(() => {
    return async () => {
      if (sender) {
        await sender.close()
      }
      if (serviceBusClient) {
        await serviceBusClient.close()
      }
    }
  }, [])

  useEffect(() => {
    if (topicName && subscriptionName) {
      setupServiceBus()
    }
  }, [topicName, subscriptionName])

  useEffect(() => {
    const createQueueAndTopicAndSubscription = async () => {
      try {
        const url = `${AB_SUBSCRIPTION_QUERY}?topic_name=5000`
        const response = await RequestService.Post(url, history, null, { isBlankInstance: true })
        console.log('response.data:', response.data)
        setTopicName(response.data.topic_name)
        setSubscriptionName(response.data.subscription_name)
      } catch (error) {
        console.error(error)
      }
    }

    createQueueAndTopicAndSubscription()
  }, [history])

  useEffect(() => {
    if (isServiceBusReady) {
      checkAutoTrainStatus()
    }
  }, [isServiceBusReady])

  useEffect(() => {
    let intervalId
    if (isCapturing) {
      intervalId = setInterval(() => {
        if (videoRef.current) {
          const video = videoRef.current
          const canvas = document.createElement('canvas')
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight
          const ctx = canvas.getContext('2d')
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
          const dataURL = canvas.toDataURL()
          setCapturedImages((prevImages) => [...prevImages, dataURL])
        }
      }, 1000)
    } else {
      clearInterval(intervalId)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [isCapturing])

  useEffect(() => {
    if (progress !== null && progress >= 0 && progress < 100) {
      setShowProgress(true)
    } else if (progress === 100) {
      setShowProgress(true)
      setTimeout(() => {
        setShowProgress(false)
        setAutoTrainEnabled(true)
      }, 15000)
    } else {
      setShowProgress(false)
    }
  }, [progress])

  const checkAutoTrainStatus = async () => {
    try {
      await sendServiceBusMessage('trainingprogress')
    } catch (error) {
      console.error('Error fetching auto train status:', error)
      setAutoTrainEnabled(false)
    }
  }

  const mapProgress = {
    Starting: 0,
    Preparing: 10,
    Queued: 20,
    Running: 40,
    Finalizing: 90,
    Completed: 100,
    Failed: 100,
    Canceled: 100,
  }

  const setupServiceBus = async () => {
    try {
      console.log('queueName, topicName, subscriptionName:', queueName, topicName, subscriptionName)
      const client = new ServiceBusClient(process.env.REACT_APP_SERVICE_BUS_CONNECTION_STRING)
      const sender = client.createSender(queueName)
      const receiver = client.createReceiver(topicName, subscriptionName)

      setServiceBusClient(client)
      setSender(sender)

      receiver.subscribe({
        processMessage: async (message) => {
          console.log('Received message:', message)
          try {
            if (message.subject === 'post_model') {
              handleModelDetectionResults(message.body.detection)
              await receiver.completeMessage(message)
            } else if (message.subject === 'run_training') {
              if (message.body?.key_status && message.body?.progress) {
                const { key_status, progress } = message.body
                enqueueSnackbar(`Received message: ${key_status} ${progress}`, { variant: 'info' })

                const statusValue = key_status
                const progressValue = mapProgress[statusValue] || 0
                setStatus(statusValue)
                setProgress(progressValue)
              } else if (message.body?.message) {
                enqueueSnackbar(`Received message: ${message.body.message}`, { variant: 'success' })
              }
              await receiver.completeMessage(message)
            } else if (message.subject === 'trainingprogress') {
              if (message.body.messages === 'No job run') {
                setAutoTrainEnabled(true)
              } else {
                setAutoTrainEnabled(false)
                const statusValue = message.body.progress
                const progressValue = mapProgress[statusValue] || 0

                setStatus(statusValue)
                setProgress(progressValue)
              }
              await receiver.completeMessage(message)
            }
          } catch (error) {
            console.error('Error processing message:', error)
          }
        },
        processError: async (err) => {
          console.error('Error receiving message:', err)
        },
      })
      setIsServiceBusReady(true)
    } catch (error) {
      console.error('Error setting up Service Bus:', error)
      setIsServiceBusReady(false)
    }
  }

  const sendServiceBusMessage = async (subject, body) => {
    if (!sender) {
      console.error('Sender not initialized')
      return
    }

    try {
      const message = {
        body,
        subject,
      }
      await sender.sendMessages(message)
      console.log(`Message sent: ${subject}`)
    } catch (error) {
      console.error(`Error sending message: ${subject}`, error)
    }
  }

  const selectedCategoriesChanged = useCallback(
    (items) => {
      if (dialog.shape) {
        dialog.shape.categories = items
        setDialog({ ...dialog })
      }
    },
    [dialog.shape]
  )

  const hideDialog = () => setDialog({ show: false, shape: undefined })

  const hideAndUpdateCategories = () => {
    if (dialog.show && dialog.shape) {
      annotator.updateCategories(dialog.shape.id, dialog.shape.categories)
      hideDialog()
    }
  }

  const toggleCapture = () => setIsCapturing((prev) => !prev)

  const handleImageClick = (index) => {
    setImg(capturedImages[index])
    setShapes([])
    setSelectedImageIndex(index)
  }

  const handleSave = async () => {
    if (!annotator) return

    setIsSaving(true)
    const shapes = annotator.getShapes()
    const imgElement = document.createElement('img')
    imgElement.src = img

    imgElement.onload = async () => {
      const canvas = document.createElement('canvas')
      canvas.width = imgElement.width
      canvas.height = imgElement.height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(imgElement, 0, 0)

      canvas.toBlob(async (imageBlob) => {
        const labelData = shapes
          .map((shape, index) => {
            if (shape.type === 'rectangle') {
              const [x_min, y_min] = shape.points[0]
              const [x_max, y_max] = shape.points[2]
              return `${index + 1} ${x_min} ${y_min} ${x_min} ${y_max} ${x_max} ${y_max} ${x_max} ${y_min}`
            }
            return null
          })
          .filter((shape) => shape !== null)
          .join('\n')

        const labelBlob = new Blob([labelData], { type: 'text/plain' })

        const formData = new FormData()
        const timestamp = Date.now()
        formData.append('imageFile', imageBlob, `original_image_${timestamp}.png`)
        formData.append('labelFile', labelBlob, `original_image_${timestamp}.txt`)

        try {
          await RequestService.Post(`${UPLOAD_PACKMAN_QUERY}uploadimageslabels`, history, formData)
        } catch (error) {
          console.error('Error saving labeled image:', error)
        } finally {
          setIsSaving(false)
        }
      })
    }

    imgElement.onerror = () => {
      console.error('Failed to load image.')
    }
  }

  const handleTrainModel = async () => {
    try {
      setAutoTrainEnabled(false)
      await sendServiceBusMessage('run_training')
    } catch (error) {
      setAutoTrainEnabled(true)
      enqueueSnackbar('Error starting training. Please try again.', { variant: 'error' })
    }
  }

  const handleTestModel = async () => {
    if (!img) {
      alert('Please select an image to test the model.')
      return
    }

    const canvas = document.createElement('canvas')
    const imgElement = document.createElement('img')
    imgElement.src = img

    imgElement.onload = async () => {
      canvas.width = imgElement.width
      canvas.height = imgElement.height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(imgElement, 0, 0)

      canvas.toBlob(async (blob) => {
        if (!blob) {
          console.error('Failed to create a blob from the canvas.')
          return
        }

        const formData = new FormData()
        formData.append('imageFile', blob, `test_image_${Date.now()}.png`)

        try {
          const uploadResponse = await RequestService.Post(
            `${UPLOAD_PACKMAN_QUERY}uploadimageslabels`,
            history,
            formData
          )
          const imageUrl = uploadResponse.data[0]

          await sendServiceBusMessage('post_model', { img_url: imageUrl })
        } catch (error) {
          console.error('Error testing model:', error)
        }
      }, 'image/png')
    }

    imgElement.onerror = () => {
      console.error('Failed to load image.')
    }
  }

  const handleModelDetectionResults = (detectionResults) => {
    if (detectionResults && detectionResults.length > 0) {
      const newShapes = detectionResults.map((detection, index) => {
        const [x_min, y_min] = detection.bbox[0]
        const [x_max, y_max] = detection.bbox[2]

        return {
          id: index,
          type: 'rectangle',
          points: [
            [x_min, y_min],
            [x_min, y_max],
            [x_max, y_max],
            [x_max, y_min],
          ],
          categories: [detection.class_name],
          conf_score: detection.conf_score,
        }
      })

      setShapes(newShapes)
      setAnnotatorKey((prevKey) => prevKey + 1)
      setModelDetectionResults([{ detection: detectionResults }])
    } else {
      console.error('No detection results found.')
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.sidebar}>
        <div
          className={classes.videoStreamBody}
          style={{
            position: 'relative',
            width: '100%',
            overflow: 'hidden',
            border: '2px solid black',
            borderRadius: '8px',
            marginBottom: '10px',
          }}
        >
          <ReactHlsPlayer playerRef={videoRef} src={LIVE_CAMERA_URL} muted autoPlay width="100%" height="100%" />
        </div>
        <Button variant="contained" color="primary" onClick={toggleCapture}>
          {isCapturing ? t('stop_capture') : t('start_capture')}
        </Button>
        <Button onClick={() => annotator.drawRectangle()} title="Draw Rectangle">
          {t('start_draw')}
        </Button>
        <Button onClick={() => annotator.stop()}>{t('stop_draw')}</Button>
        <Button onClick={() => annotator.stopEdit()}>{t('edit_done')}</Button>
        <Button onClick={() => annotator.zoom(1.25)}>{t('zoom_in')}</Button>
        <Button onClick={() => annotator.zoom(0.8)}>{t('zoom_out')}</Button>
        <Button onClick={() => setShapes(annotator.getShapes())}>{t('get_shapes')}</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleTrainModel}
          style={{ margin: '20px 0' }}
          disabled={!autoTrainEnabled}
        >
          {t('train_model')}
        </Button>
        {showProgress && (
          <Box>
            <h3>{t('training_progress')}</h3>
            <LinearProgressWithLabel value={progress} status={status} />
          </Box>
        )}
        <Button variant="contained" color="primary" onClick={handleTestModel} style={{ margin: '20px 0' }}>
          {t('test_model')}
        </Button>
      </div>
      <div className={classes.imageArea}>
        <div className={classes.imageScrollArea}>
          {capturedImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`capture-${index}`}
              className={index === selectedImageIndex ? classes.selectedImage : ''}
              style={{ width: '100px', height: '100px', cursor: 'pointer', marginRight: '10px' }}
              onClick={() => handleImageClick(index)}
            />
          ))}
        </div>
        <div style={{ marginTop: '20px' }}>
          {img && (
            <ImageAnnotator
              key={annotatorKey}
              setHandles={setHandles}
              shapes={shapes}
              imageUrl={img}
              naturalSize
              onAdded={(shape) => setDialog({ show: true, shape })}
              onContextMenu={(shape) => setDialog({ show: true, shape })}
              onReady={(annotator) => annotator.zoom(1)}
            />
          )}
        </div>
        <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
          <Button variant="contained" color="secondary" onClick={handleSave} disabled={isSaving}>
            {isSaving ? `${t('saving')}...` : t('save_labeled_image')}
          </Button>
        </Grid>
        {shapes != null && (
          <div style={{ marginTop: '20px', fontFamily: 'monospace', fontSize: '14px' }}>
            {JSON.stringify(shapes, null, 2)}
          </div>
        )}
        <div style={{ marginTop: '20px' }}>
          <h3>{t('model_detection_results')}</h3>
          {modelDetectionResults.map((result, index) => (
            <div key={index}>
              <ul>
                {result.detection.map((detection, detIndex) => (
                  <li key={detIndex}>
                    Class: {detection.class_name}, Confidence: {detection.conf_score}, BBox:{' '}
                    {JSON.stringify(detection.bbox)}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      {dialog.show && (
        <Dialog
          items={dialog.shape.categories}
          itemsChanged={selectedCategoriesChanged}
          onEdit={() => {
            annotator.edit(dialog.shape.id)
            hideAndUpdateCategories()
          }}
          onDelete={() => {
            annotator.delete(dialog.shape.id)
            hideDialog()
          }}
          onClose={hideAndUpdateCategories}
          offset={dialog.shape.getCenterWithOffset()}
        />
      )}
    </div>
  )
}

export default ImageLabeler
