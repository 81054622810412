import React, { useEffect, useState, useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Typography, Grid, Button } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { DateTime } from 'luxon'
import html2canvas from 'html2canvas'
import { ROW_PER_PAGE_OPTIONS, DEFAULT_TABLE_CONFIGURATION } from '../../../shared/constants/table'
import SearchableInput from '../../../components/searchable-input/searchable-input.component'
import { PACKED_INVENTORY } from '../../../shared/constants/queries'
import RequestService from '../../../services/request/request-service'
import SearchBar from '../../../components/SearchBar/SearchBar.component'
import PrimaryButton from '../../../components/button/button.component'
import AppInsightsTrackingContext from '../../../context/app-insights-tracking/AppInsightsTrackingContext'
import SingleDateTimePicker from '../../../components/single-datetimepicker/simple-datetimepicker.component'
import usePackedInventoryReportQuery from '../../../shared/hooks/usePackedInventoryQuery'
import useSizeQuery from '../../../shared/hooks/useSizeQuery'
import useLotsFacilityQuery from '../../../shared/hooks/useLotsFacilityQuery'
import useShiftQuery from '../../../shared/hooks/useShiftQuery'
import useMachineQuery from '../../../shared/hooks/useMachineQuery'
import useVarietyQuery from '../../../shared/hooks/useVarietyQuery'
import useUserQuery from '../../../shared/hooks/useUserQuery'
import makeStyles from '../reports.styles'
import { numberToPercentage, searchFullColumns } from '../../../shared/utils/utils'
import EditableCell from '../../../components/editable-cell/editable-cell.component'
import PdfMakerService from '../../../services/pdf-maker/pdf-maker-service'
import { DARKBLUE } from '../../../shared/constants/pdf'
import DefectBarChart from '../../../components/defect-bar-chart/defect-bar-chart.component'

const generateChartData = (data, chartId, maxDefect, formatterPercentage) => {
  return (
    <DefectBarChart data={data} maxDefect={maxDefect} chartId={chartId} formatterPercentage={formatterPercentage} />
  )
}

const PackedInventory = ({ currentFacility, userEmail }) => {
  const classes = makeStyles()
  const history = useHistory()
  const [rows, setRows] = useState([])
  const [selectedShift, setSelectedShift] = useState([])
  const [selectedMachine, setSelectedMachine] = useState([])
  const [updatedRowIds, setUpdatedRowIds] = useState(new Set())
  const [packedInventoryQueryResult, setPackedInventoryQueryResult] = useState([])
  const { trackEvent } = useContext(AppInsightsTrackingContext)
  const [isLoading, setIsLoading] = useState(false)
  const { t, i18n } = useTranslation()
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [showDatesError, setShowDatesError] = useState(false)
  const [dateReport, setDateReport] = useState('')
  const [startDate, setStartDate] = useState(DateTime.now().startOf('day').toISO({ includeOffset: false }))
  const [endDate, setEndDate] = useState(DateTime.now().endOf('day').toISO({ includeOffset: false }))
  const [startDateISODate, setStartDateISODate] = useState(DateTime.now().startOf('day').toISODate())
  const [endDateISODate, setEndDateISODate] = useState(DateTime.now().endOf('day').toISODate())
  const [isEdit, setIsEdit] = useState(false)
  const [defectGraphData, setDefectGraphData] = useState({})
  const sizeQuery = useSizeQuery()
  const userQuery = useUserQuery()
  const shiftQuery = useShiftQuery()
  const varietyQuery = useVarietyQuery()
  const packedInventoryQuery = usePackedInventoryReportQuery({
    filter: { id_facility: currentFacility.id },
    start_date: startDate,
    end_date: endDate,
    machine_id: selectedMachine?.id,
    start_time: selectedShift?.start_time,
    end_time: selectedShift?.end_time,
  })
  const lotsFacilityQuery = useLotsFacilityQuery({
    filter: { id: currentFacility.id },
    optional: '&filterByFirst=false&all=true',
  })
  const machineQuery = useMachineQuery({
    byFacility: true,
    filter: { id_facility: currentFacility.id },
  })
  const isLoadingQuery = packedInventoryQuery.isFetching || sizeQuery.isFetching

  useEffect(() => {
    let isMounted = true
    ;(async () => {
      if (isMounted) {
        try {
          await sizeQuery.refetch()
          await userQuery.refetch()
          await shiftQuery.refetch()
          await machineQuery.refetch()
        } catch (error) {
          console.log(error)
        }
      }
    })()
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    let DateReport = ''
    if (DateTime.fromISO(startDate).toFormat('MMM-dd-yy') === DateTime.fromISO(endDate).toFormat('MMM-dd-yy')) {
      DateReport = DateTime.fromISO(startDate).toFormat('MMM-dd-yy')
    } else {
      DateReport = `${DateTime.fromISO(startDate).toFormat('MMM-dd-yy')} to ${DateTime.fromISO(endDate).toFormat(
        'MMM-dd-yy'
      )}`
    }
    setDateReport(DateReport)
  }, [])

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setRows(parseDataToRows(packedInventoryQueryResult) || [])
    }
    return () => {
      isMounted = false
    }
  }, [packedInventoryQueryResult])

  const infoColumns = [
    { field: 'lot_number', headerName: `${t('lot')} #`, minWidth: 110 },
    { field: 'grower', headerName: t('grower'), width: 150 },
    { field: 'variety_name', headerName: t('variety'), minWidth: 125 },
    { field: 'bin_count', headerName: t('bin_count'), minWidth: 140 },
    { field: 'pick_date', headerName: t('pick_date'), minWidth: 140 },
    { field: 'packDate', headerName: t('pack_date'), width: 110 },
    { field: 'net_weight', headerName: t('net_weight'), minWidth: 145 },
    { field: 'estimated_po', headerName: 'Est. PO', minWidth: 125 },
    { field: 'peak_size', headerName: t('peak_size'), minWidth: 125 },
    {
      field: 'packed_weight',
      headerName: t('pack_weight'),
      width: 110,
      renderCell: (params) => (
        <EditableCell
          type="number"
          value={params.value}
          isEdit={isEdit}
          onValueChange={(newValue) => handleCellValueChange(params.id, 'packed_weight', newValue)}
        />
      ),
    },
    {
      field: 'actual_pack_out',
      headerName: t('actual_pack_out'),
      width: 130,
      renderCell: (params) => (
        <EditableCell
          type="number"
          value={params.value}
          isEdit={isEdit}
          onValueChange={(newValue) => handleCellValueChange(params.id, 'actual_pack_out', newValue)}
        />
      ),
    },
    {
      field: 'actual_peak_size',
      headerName: t('actual_peak_size'),
      width: 130,
      renderCell: (params) => (
        <EditableCell
          type="text"
          value={params.value}
          isEdit={isEdit}
          onValueChange={(newValue) => handleCellValueChange(params.id, 'actual_peak_size', newValue)}
        />
      ),
    },
    {
      field: 'variance',
      headerName: t('variance'),
      width: 90,
      valueGetter: (params) => params.row.variance,
    },
  ]

  const handleCellValueChange = (id, field, newValue) => {
    setRows((prevRows) => {
      const updatedRows = prevRows.map((row) => {
        if (row.id === id) {
          return { ...row, [field]: newValue }
        }
        return row
      })
      let variance = updatedRows[id].variance ?? 0
      if (field === 'actual_pack_out') {
        const rowToUpdate = updatedRows.find((row) => row.id === id)
        const actualPackout = parseFloat(rowToUpdate.actual_pack_out)
        const estimatedPackout = parseFloat(rowToUpdate.estimated_po)
        if (!Number.isNaN(actualPackout)) {
          variance = (actualPackout - estimatedPackout).toFixed(1)
          updatedRows[id] = { ...updatedRows[id], variance }
        }
      }
      return updatedRows.map((row) => {
        if (row.id === id) {
          return { ...row, variance }
        }
        return row
      })
    })
    setUpdatedRowIds((prevUpdatedRowIds) => {
      const newUpdatedRowIds = new Set(prevUpdatedRowIds)
      newUpdatedRowIds.add(id)
      return newUpdatedRowIds
    })
  }

  const defectTypes = [
    { key: 'receiving', label: 'Receiving' },
    { key: 'upstream', label: 'Upstream' },
    { key: 'inline', label: 'Inline' },
    { key: 'finishedBox', label: 'Finished Box' },
  ]

  const parseResponseToHeaders = (data, type = 'color') => {
    return [...data]
      .map((size) => {
        const { description, value } = size
        return {
          field: description,
          headerName: description,
          renderCell: (params) => params?.row[params.field]?.percentage || '',
          value: description === 'Undersize' ? 15 : description === 'Oversize' ? 1 : value,
          minWidth: 160,
        }
      })
      .sort((a, b) => b.value - a.value)
  }

  const columns = useMemo(() => {
    const parsedSizes = parseResponseToHeaders(sizeQuery.data, 'size')
    return [...infoColumns, ...parsedSizes]
  }, [sizeQuery.data, isEdit, i18n, i18n.language])

  const handleGeneratePDF = async () => {
    if (rows.length === 0) return

    const generalColumns = [
      'lot_number',
      'grower',
      'variety_name',
      'bin_count',
      'pick_date',
      'packDate',
      'net_weight',
      'estimated_po',
      'peak_size',
      'packed_weight',
      'actual_pack_out',
      'actual_peak_size',
      'variance',
    ]

    const sizeColumns = [
      'lot_number',
      'variety_name',
      '12 Row',
      '11.5 Row',
      '11 Row',
      '10.5 Row',
      '10 Row',
      '9.5 Row',
      '9 Row',
      '8.5 Row',
      '8 Row',
    ]

    const createHeaderRow = (columns) => {
      return columns.map((column) => {
        const headerText =
          column === 'lot_number'
            ? 'Lot'
            : column === 'packDate'
            ? 'Packed'
            : column === 'estimated_po'
            ? 'Est. PO'
            : column === 'peak_size'
            ? 'Est. PS'
            : column === 'packed_weight'
            ? 'Packed Weight'
            : column === 'actual_pack_out'
            ? 'Act. PO'
            : column === 'actual_peak_size'
            ? 'Act. PS'
            : column.includes('Row')
            ? column
            : column.charAt(0).toUpperCase() + column.slice(1).replace(/_/g, ' ')
        return { text: headerText, style: 'tableSumaryLabel', fillColor: DARKBLUE }
      })
    }

    const createDataRows = (data, columns) => {
      return data.map((row) => {
        return columns.map((column) => {
          let value = row[column] || ''
          if (column === 'net_weight' || column === 'bin_count') {
            value = Number(value).toLocaleString('en-US')
          } else if (column.includes('Row')) {
            value = row[column]?.percentage || ''
          }
          return { text: value.toString(), style: 'tableLabelValue7' }
        })
      })
    }

    const generalTableBody = [createHeaderRow(generalColumns), ...createDataRows(rows, generalColumns)]
    const sizeTableBody = [createHeaderRow(sizeColumns), ...createDataRows(rows, sizeColumns)]

    const firstRow = packedInventoryQueryResult[0]

    const processDefectData = (defects) => {
      const majorDefects = []
      const minorDefects = []
      defects.forEach((defect) => {
        const { name, percentage, description } = defect
        const defectInformation = { defect: name, percentage: (percentage * 100).toFixed(2) }
        if (description === 'Major') majorDefects.push(defectInformation)
        if (description === 'Minor') minorDefects.push(defectInformation)
      })
      return { majorDefects, minorDefects }
    }
    const defectLocations = []
    const defectPromises = defectTypes.map(async (type) => {
      const defects = firstRow[`${type.key}_defects`]

      if (defects && defects.length > 0) {
        const { majorDefects, minorDefects } = processDefectData(defects)

        setDefectGraphData((prevData) => ({
          ...prevData,
          [`majorDefects${type.label}`]: majorDefects,
          [`minorDefects${type.label}`]: minorDefects,
        }))

        // Ensure that the chart elements are rendered before capturing
        return new Promise((resolve) => {
          setTimeout(async () => {
            const minorChart = document.getElementById(`minorDefect${type.label}Graph`)
            const majorChart = document.getElementById(`majorDefect${type.label}Graph`)

            if (minorChart && majorChart) {
              try {
                const minorImage = (await html2canvas(minorChart)).toDataURL('image/png')
                const majorImage = (await html2canvas(majorChart)).toDataURL('image/png')
                defectLocations.push(type.label)
                resolve([minorImage, majorImage])
              } catch (error) {
                console.error('Error capturing charts:', error)
                resolve([])
              }
            } else {
              resolve([])
            }
          }, 1000) // Delay for 1 second to ensure the charts are fully rendered
        })
      }

      return []
    })

    const defectImagesArrays = await Promise.all(defectPromises)
    const defectImages = defectImagesArrays.flat()

    const filename = `Packed_Inventory_Report_${dateReport}`
    const reportType = 'PackedInventory'

    PdfMakerService.generateReport(
      filename,
      reportType,
      {
        generalTableBody,
        sizeTableBody,
        defectLocations,
      },
      defectImages
    )
  }

  const formatterPercentege = (value) => (+value > 0 ? `${value}% ` : '')

  const parseDataToRows = (data) => {
    return data?.map((item, index) => {
      let maxObj = null
      let maxValue = 0
      let peakSize = ''
      const sizeData = {}
      const {
        id_lot,
        id_variety,
        grower,
        pick_date,
        pack_date,
        bin_count,
        net_weight,
        estimated_packout,
        packed_weight,
        actual_pack_out,
        actual_peak_size,
        variance,
        size,
      } = item

      const lotNumber = lotsFacilityQuery.data.map((lot) => lot.first)?.find((lot) => id_lot === lot.id)?.name
      const varietyName = varietyQuery.data?.find((variety) => id_variety === variety.id)?.description
      let estimated_po = estimated_packout || 0
      estimated_po = numberToPercentage((estimated_packout * 100).toFixed(1))
      const binCount = Number((Number(bin_count) || 0).toFixed()).toLocaleString('en-US')
      const netWeight = Number((Number(net_weight) || 0).toFixed()).toLocaleString('en-US')

      if (size) {
        size.forEach((sizeItem) => {
          const { percentage, description, pieces } = sizeItem
          if (percentage > maxValue) {
            maxObj = sizeItem
            maxValue = percentage
            peakSize = description
          }
          let data = {
            description,
            percentage: numberToPercentage((percentage * 100).toFixed(1)),
            pieces: +pieces,
          }
          if (description === 'Undersize') {
            data.number = 1
          } else if (description === 'Oversize') {
            data.number = 13
          } else {
            data = {
              ...data,
              number: +description.split(' ')[0],
            }
          }
          sizeData[description] = data
        })
      }
      return {
        id: index,
        id_lot,
        id_variety,
        grower,
        peakSize: maxObj?.description || '',
        lot_number: lotNumber,
        variety_name: varietyName,
        bin_count: binCount,
        pick_date: pick_date ? DateTime.fromISO(pick_date).toUTC().toFormat('LL-dd-yy') : '',
        pack_date: pack_date ? DateTime.fromISO(pack_date).toUTC().toFormat('LL-dd-yy') : '',
        net_weight: netWeight,
        estimated_po,
        peak_size: peakSize,
        packed_weight: packed_weight ?? 0,
        actual_pack_out: actual_pack_out ?? 0,
        actual_peak_size: actual_peak_size ?? '',
        variance: variance ?? 0,
        ...sizeData,
      }
    })
  }

  const onPageSizeChange = (event) => {
    setTableConf({ page: 1, pageSize: event })
  }

  const handleFilterClick = async () => {
    try {
      if (endDateISODate >= startDateISODate) {
        setShowDatesError(false)
        trackEvent('FILTE_BUTTON_CLICK', { email: userEmail })
        const res = await packedInventoryQuery.refetch()
        setPackedInventoryQueryResult(res.data.result)
      } else {
        setShowDatesError(true)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
      // Handle error
    }
  }

  const handleChangeSearch = (event) => {
    const text = event.target.value
    searchFullColumns(rows, text, setRows, columns)
  }

  const handleEditClick = () => {
    setIsEdit(true)
  }

  const handleSaveClick = async () => {
    setIsEdit(false)
    try {
      const packedInventoryDTOs = rows
        .filter((row) => updatedRowIds.has(row.id))
        .map((row) => ({
          id_facility: currentFacility.id,
          id_variety: row.id_variety,
          id_lot: row.id_lot,
          start_date: startDate,
          end_date: endDate,
          variance: parseFloat(row.variance),
          packed_weight: parseFloat(row.packed_weight),
          actual_pack_out: parseFloat(row.actual_pack_out),
          actual_peak_size: row.actual_peak_size,
        }))
      const url = `${PACKED_INVENTORY}`
      await RequestService.Post(url, history, packedInventoryDTOs)
      setUpdatedRowIds(new Set())
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid xs={12} item>
        <Typography align="center" variant="h6">
          {t('packed_inventory')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={2}>
        <SearchableInput
          disabled={machineQuery.data.length === 0}
          value={machineQuery?.data?.find((machine) => machine.id === selectedMachine?.id) ?? 0}
          options={machineQuery.data}
          onChange={(event) => {
            const { value } = event.target
            const machine = machineQuery?.data?.find((x) => x.id === value)
            setSelectedMachine(machine)
          }}
          label={t('machine')}
          id="id_machine"
          name="id_machine"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <SearchableInput
          disabled={shiftQuery.data.length === 0}
          value={shiftQuery?.data?.find((shift) => shift.id === selectedShift?.id) ?? 0}
          options={shiftQuery.data}
          onChange={(event) => {
            const { value } = event.target
            const shift = shiftQuery?.data?.find((x) => x.id === value)
            setSelectedShift(shift)
          }}
          label={t('shift')}
          id="id_shift"
          name="id_shift"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <SingleDateTimePicker
          disabled={isLoading}
          style={{ width: '100%' }}
          label={t('start_date')}
          name="startDate"
          value={startDate}
          disableFuture
          onChange={(date) => {
            const startDate = DateTime.fromJSDate(date)
            setStartDateISODate(startDate.toISODate())
            setStartDate(startDate.toISO({ includeOffset: false }))
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <SingleDateTimePicker
          disabled={isLoading}
          style={{ width: '100%' }}
          label={t('end_date')}
          name="endDate"
          value={endDate}
          disableFuture
          onChange={(date) => {
            const endDate = DateTime.fromJSDate(date)
            setEndDateISODate(endDate.toISODate())
            setEndDate(endDate.toISO({ includeOffset: false }))
          }}
        />
      </Grid>
      <Grid item xs={6} md={1} style={{ display: 'flex', alignItems: 'end' }}>
        <PrimaryButton variant="contained" disabled={isLoading} onClick={handleFilterClick}>
          {t('filter')}
        </PrimaryButton>
      </Grid>

      {showDatesError ? (
        <Grid item xs={12} md={6}>
          <Typography align="left" variant="subtitle2" color="error">
            {t('end_date_must_be_after_or_the_same_as_start_date_please_adjust_and_try_again')}
          </Typography>
        </Grid>
      ) : null}
      <Grid container>
        <Grid item xs={6} md={6}>
          <Button
            variant="contained"
            style={{ width: '110px', marginLeft: '5px' }}
            color="primary"
            onClick={isEdit ? handleSaveClick : handleEditClick}
            startIcon={isEdit ? <SaveIcon /> : null}
            disabled={rows.length === 0}
          >
            {t(isEdit ? 'save' : 'edit')}
          </Button>

          <Button
            variant="contained"
            style={{ width: '110px', marginLeft: '10px' }}
            disabled={rows.length === 0}
            onClick={handleGeneratePDF}
          >
            {t('generate')}
          </Button>
        </Grid>
        <Grid item xs={6} md={6}>
          <div className={classes.searchBox}>
            <SearchBar onChange={handleChangeSearch} />
          </div>
        </Grid>
      </Grid>
      <Grid container>
        {defectTypes.map((type) => {
          const minorData = defectGraphData[`minorDefects${type.label}`] || []
          const majorData = defectGraphData[`majorDefects${type.label}`] || []

          if (minorData.length === 0 && majorData.length === 0) return null

          const maxMinorDefect = minorData.length
            ? Math.ceil(Math.max(...minorData.map((x) => +x.percentage))) * 1.25
            : 5

          const maxMajorDefect = majorData.length
            ? Math.ceil(Math.max(...majorData.map((x) => +x.percentage))) * 1.25
            : 5

          return (
            <React.Fragment key={type.key}>
              <Grid xs={12} md={6} item className={classes.hideChart}>
                {generateChartData(minorData, `minorDefect${type.label}Graph`, maxMinorDefect, formatterPercentege)}
              </Grid>
              <Grid xs={12} md={6} item className={classes.hideChart}>
                {generateChartData(majorData, `majorDefect${type.label}Graph`, maxMajorDefect, formatterPercentege)}
              </Grid>
            </React.Fragment>
          )
        })}
      </Grid>
      <Grid xs={12} item>
        <div
          style={{
            height: '80vh',
          }}
        >
          <DataGridPro
            rows={rows}
            columns={columns}
            rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
            pageSize={tableConf.pageSize}
            disableSelectionOnClick
            loading={isLoadingQuery}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  currentFacility: state.facility.currentFacility,
  userEmail: state.user.email,
})

export default connect(mapStateToProps)(PackedInventory)
