import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import FormDialog from '../../components/form-dialog/form-dialog.component'
import ConfirmDialog from '../../components/confirm-dialog/confirm-dialog.component'
import ActionButtons from '../../components/action-buttons/action-buttons.component'
import TableContainer from '../../components/table-container/table-container'
import ActiveToggle from '../../components/active-toggle/active-toggle.component'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import RequestService from '../../services/request/request-service'

import {
  ACTIONS_COLUMN,
  BASE_TABLE_COLUMNS,
  DEFAULT_ROW_DATA,
  DEFAULT_TABLE_CONFIGURATION,
} from '../../shared/constants/table'
import { DEFAULT_COMFIRM_INACTIVE_MESSAGE } from '../../shared/constants/messages'

import { POST_METHOD, PUT_METHOD } from '../../shared/constants/requests'
import { GROWER_QUERY } from '../../shared/constants/queries'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'
import useHttpInactive from '../../shared/hooks/useHttpInactive'
import useGrowerQuery from '../../shared/hooks/useGrowerQuery'
import { SUPER_ADMIN_ROLE, QUALITY_CONTROL_MANAGER_ROLE } from '../../shared/constants/roles'

const Grower = ({ userEmail, role }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const [openDialog, setOpen] = useState(false)
  const [openDelDialog, setDelOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalType, setModalType] = useState('Add new')
  const [rowData, setRowData] = useState(DEFAULT_ROW_DATA)
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [showActive, setShowActive] = useState(true)
  const growerQuery = useGrowerQuery({ showActive })
  const isLoadingQuery = growerQuery.isFetching
  // columns definition for table
  const translatedColumns = BASE_TABLE_COLUMNS.map((column) => ({
    ...column,
    headerName: t(column.headerName),
  }))

  const columns = [
    ...translatedColumns,
    {
      ...ACTIONS_COLUMN,
      headerName: t(ACTIONS_COLUMN.headerName),
      hide: role !== SUPER_ADMIN_ROLE && role !== QUALITY_CONTROL_MANAGER_ROLE,
      renderCell: (params) => ActionButtons(params.row, handleClickOpen, handleClickDelOpen),
    },
  ]

  useEffect(() => {
    trackEvent(LOG_PAGE_LOADS.LOAD_GROWER_PAGE, { email: userEmail })
  }, [])

  // insert and edit
  const insertEditData = async (method, data) => {
    setLoading(true)
    setOpen(false)
    const url = `${GROWER_QUERY}`
    try {
      if (method === POST_METHOD) await RequestService.Post(url, history, data)
      if (method === PUT_METHOD) await RequestService.Put(url, history, data)
      setLoading(false)
      growerQuery.refetch()
    } catch (error) {
      console.error(error.errorMessage)
    } finally {
      setLoading(false)
    }
  }

  // Form Dialog handler
  const handleClickOpen = (type, params) => () => {
    if (type === 'Edit') {
      trackEvent('EDIT_CLICK', { email: userEmail })
      setRowData({
        id: params.id,
        name: params.name,
        description: params.description,
        active: params.active,
      })
    } else {
      trackEvent('ADD_CLICK', { email: userEmail })
      setRowData({ ...DEFAULT_ROW_DATA, active: true })
    }
    setModalType(type)
    setOpen(true)
  }

  // Submit form
  const handleSubmit = (event) => {
    trackEvent('SUBMIT_CLICK', { email: userEmail })
    event.preventDefault()
    const data = { ...rowData }
    const method = modalType === 'Edit' ? PUT_METHOD : POST_METHOD
    insertEditData(method, data)
  }

  // Confirm Dialog handlers
  const handleClickDelOpen = (params) => () => {
    trackEvent('DELETE_CLICK', { email: userEmail })
    setRowData({
      id: params.id,
      name: params.name,
      description: params.description,
      active: !params.active,
    })
    setDelOpen(true)
  }
  const { executeInactive, loadingInactive } = useHttpInactive(GROWER_QUERY)
  // Confirm delete handler
  const onClickDelConfirmation = async () => {
    const { success } = await executeInactive(rowData.id)
    if (success) {
      setDelOpen(false)
      growerQuery.refetch()
    }
  }

  // Form input on change
  const handleChange = (event) => {
    const { name, value } = event.target
    setRowData({
      ...rowData,
      [name]: value,
    })
  }
  return (
    <div>
      <Box pt={5} pb={3}>
        <Typography component="h1" variant="h5" color="primary">
          {t('growers')}
        </Typography>
      </Box>
      <TableContainer
        haveSearchInput="true"
        handleClickOpen={() => handleClickOpen('Add new', null)}
        columns={columns}
        rows={growerQuery.data}
        tableConfiguration={tableConf}
        setTableConf={setTableConf}
        onToggleClick={() => setShowActive(!showActive)}
        showActive={showActive}
        loading={isLoadingQuery}
      />
      <FormDialog
        title={`${modalType} grower`}
        open={openDialog}
        onClose={() => {
          setOpen(false)
        }}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <TextField
          defaultValue={rowData.name}
          autoFocus
          variant="outlined"
          margin="normal"
          name="name"
          label={t('name')}
          type="text"
          fullWidth
          onChange={handleChange}
          required
        />
        <TextField
          defaultValue={rowData.description}
          variant="outlined"
          margin="normal"
          name="description"
          label={t('description')}
          type="text"
          fullWidth
          onChange={handleChange}
        />
        {modalType === 'Edit' && (
          <ActiveToggle value={rowData.active} onToggle={() => setRowData({ ...rowData, active: !rowData.active })} />
        )}
      </FormDialog>
      <ConfirmDialog
        title={t('deactivate_grower')}
        okButton="Deactivate"
        open={openDelDialog}
        loading={loadingInactive}
        onClose={() => {
          setDelOpen(false)
        }}
        onOk={onClickDelConfirmation}
      >
        {t(DEFAULT_COMFIRM_INACTIVE_MESSAGE)} {rowData.name}?
      </ConfirmDialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
  role: state.user.role,
})

export default connect(mapStateToProps, null)(Grower)
