import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'

import FormDialog from '../../components/form-dialog/form-dialog.component'
import ConfirmDialog from '../../components/confirm-dialog/confirm-dialog.component'
import ActionButtons from '../../components/action-buttons/action-buttons.component'
import TableContainer from '../../components/table-container/table-container'
import ActiveToggle from '../../components/active-toggle/active-toggle.component'
import MultipleSelect from '../../components/multi-select/multi-select'
import ManyToManyInput from '../../components/many-many-input/many-many-input.component'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import RequestService from '../../services/request/request-service'

import { buildPayload, getManyToManyData } from '../../shared/utils/utils'

import {
  ACTIONS_COLUMN,
  BASE_TABLE_COLUMNS,
  DEFAULT_ROW_DATA,
  DEFAULT_TABLE_CONFIGURATION,
} from '../../shared/constants/table'
import { DEFAULT_DEPENDENCIES_ERROR_MESSAGE, DEFAULT_COMFIRM_INACTIVE_MESSAGE } from '../../shared/constants/messages'
import { DELETE_METHOD, POST_METHOD, PUT_METHOD } from '../../shared/constants/requests'
import { PACKTYPE_QUERY, PACKTYPES_FACILITIES_QUERY } from '../../shared/constants/queries'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'
import useHttpInactive from '../../shared/hooks/useHttpInactive'
import usePackTypeQuery from '../../shared/hooks/usePackTypeQuery'
import useFacilityQuery from '../../shared/hooks/useFacilityQuery'
import useVarietyQuery from '../../shared/hooks/useVarietyQuery'

const SampleType = ({ userEmail }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const [openDialog, setOpen] = useState(false)
  const [openDelDialog, setDelOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalType, setModalType] = useState('Add new')
  const [rowData, setRowData] = useState(DEFAULT_ROW_DATA)

  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [deletable, setDeletable] = useState(true)
  const [showActive, setShowActive] = useState(true)
  const [selectedFacilities, setSelectedFacilities] = useState([])
  const [openFacilities, setOpenFacilities] = useState(false)
  const packTypeQuery = usePackTypeQuery({ showActive })
  const facilityQuery = useFacilityQuery()
  const varietyQuery = useVarietyQuery()
  const isLoadingQuery = packTypeQuery.isFetching || facilityQuery.isFetching || varietyQuery.isFetching

  // columns definition for table
  const translatedColumns = BASE_TABLE_COLUMNS.map((column) => ({
    ...column,
    headerName: t(column.headerName),
  }))

  const columns = [
    ...translatedColumns,
    {
      field: 'facility',
      headerName: t('facilities'),
      flex: 1,
      minWidth: 135,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id })
            setOpenFacilities(true)
          }}
          style={{ textTransform: 'none' }}
        >
          {t('see_facilities')}
        </Button>
      ),
    },
    {
      ...ACTIONS_COLUMN,
      headerName: t(ACTIONS_COLUMN.headerName),
      renderCell: (params) => ActionButtons(params.row, handleClickOpen, handleClickDelOpen),
    },
  ]

  useEffect(() => {
    trackEvent(LOG_PAGE_LOADS.LOAD_SAMPLE_TYPE, { email: userEmail })
  }, [])

  // insert and edit
  const insertEditData = async (method, data) => {
    try {
      setLoading(true)

      let id = ''
      if (method === DELETE_METHOD) {
        id = `?id=${data.id}`
      }
      let url = `${PACKTYPE_QUERY}${id}`
      let response
      if (method === POST_METHOD) response = await RequestService.Post(url, history, data)
      if (method === PUT_METHOD) response = await RequestService.Put(url, history, data)
      if (method === DELETE_METHOD) response = await RequestService.Delete(url, history, data)

      setOpen(false)
      // Created stem condition id
      id = response.data.id

      const lotsFacilitiesPayload = buildPayload(id, selectedFacilities, null, true)
      url = `${PACKTYPES_FACILITIES_QUERY}?filterByFirst=false`
      response = await RequestService.Put(url, history, lotsFacilitiesPayload, { isBlankInstance: true })
      if (response?.hasError) {
        onError(response.errorMessage)
        return
      }

      setTableConf({ ...tableConf })
      setLoading(false)
      setOpen(false)
      packTypeQuery.refetch()
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onError = (errorMessage) => {
    console.error(errorMessage)
    setLoading(false)
  }

  // Form Dialog handler
  const handleClickOpen = (type, params) => async () => {
    if (type === 'Edit') {
      trackEvent('EDIT_CLICK', { email: userEmail })
      const lotsFacilities = await getManyToManyData(
        `${PACKTYPES_FACILITIES_QUERY}?id=${params.id}&filterByFirst=false`,
        history,
        facilityQuery.data
      )

      setSelectedFacilities(lotsFacilities)

      setRowData({
        id: params.id,
        name: params.name,
        description: params.description,
        active: params.active,
      })
    } else {
      trackEvent('ADD_CLICK', { email: userEmail })
      setRowData({ ...DEFAULT_ROW_DATA })
      setSelectedFacilities([])
    }
    setModalType(type)
    setOpen(true)
  }

  const handleSubmit = (event) => {
    trackEvent('SUBMIT_CLICK', { email: userEmail })
    event.preventDefault()
    const data = { ...rowData }
    const method = modalType === 'Edit' ? PUT_METHOD : POST_METHOD
    insertEditData(method, data)
  }

  // Confirm Dialog handlers
  const handleClickDelOpen = (params) => () => {
    trackEvent('DELETE_CLICK', { email: userEmail })
    setRowData({
      id: params.id,
      name: params.name,
      description: params.description,
      active: !params.active,
    })
    setDelOpen(true)
  }
  const { executeInactive, loadingInactive } = useHttpInactive(PACKTYPE_QUERY)
  const onClickDelConfirmation = async () => {
    const { success } = await executeInactive(rowData.id)
    if (success) {
      setDelOpen(false)
      packTypeQuery.refetch()
    }
  }

  // Form on change
  const handleChange = (event) => {
    const { name, value } = event.target
    setRowData({
      ...rowData,
      [name]: value,
    })
  }

  return (
    <div>
      <MultipleSelect
        open={openFacilities}
        onClose={() => {
          setOpenFacilities(false)
        }}
        title={t('facilities')}
        getUrl="facility"
        getRelationUrl="packtypesfacilities"
        useLast
        mainId={rowData.id}
        lefttoright
      />
      <Box pt={5} pb={3}>
        <Typography component="h1" variant="h5" color="primary">
          {t('pack_type')}
        </Typography>
      </Box>
      <TableContainer
        haveSearchInput="true"
        handleClickOpen={() => handleClickOpen('Add new', null)}
        columns={columns}
        rows={packTypeQuery.data}
        tableConfiguration={tableConf}
        setTableConf={setTableConf}
        onToggleClick={() => setShowActive(!showActive)}
        showActive={showActive}
        loading={isLoadingQuery}
      />
      <FormDialog
        title={`${modalType} pack-type`}
        open={openDialog}
        onClose={() => {
          setOpen(false)
        }}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <TextField
          defaultValue={rowData.name}
          autoFocus
          variant="outlined"
          margin="normal"
          name="name"
          label={t('name')}
          type="text"
          fullWidth
          onChange={handleChange}
          required
        />
        <TextField
          defaultValue={rowData.description}
          variant="outlined"
          margin="normal"
          name="description"
          label={t('description')}
          type="text"
          fullWidth
          onChange={handleChange}
        />
        <ManyToManyInput
          inputLabel={t('facilities')}
          name="id_facility"
          selectedValues={selectedFacilities}
          allValues={facilityQuery.data}
          setSelectedValues={setSelectedFacilities}
        />
        {modalType === 'Edit' && (
          <ActiveToggle value={rowData.active} onToggle={() => setRowData({ ...rowData, active: !rowData.active })} />
        )}
      </FormDialog>
      <ConfirmDialog
        title={t('deactivate_pack_type')}
        okButton="Deactivate"
        open={openDelDialog}
        loading={loadingInactive}
        onClose={() => {
          setDelOpen(false)
          setDeletable(true)
        }}
        onOk={onClickDelConfirmation}
      >
        {t(DEFAULT_COMFIRM_INACTIVE_MESSAGE)} {rowData.name}?
        {!deletable && (
          <Alert style={{ marginTop: 10 }} severity="error">
            {t(DEFAULT_DEPENDENCIES_ERROR_MESSAGE)}
          </Alert>
        )}
      </ConfirmDialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
})

export default connect(mapStateToProps, null)(SampleType)
