import React, { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'

const EditableCell = ({ value, isEdit, onValueChange, type }) => {
  const [cellValue, setCellValue] = useState(value)

  const handleChange = (event) => {
    const newValue = event.target.value
    setCellValue(newValue)
    onValueChange(newValue)
  }

  return isEdit ? (
    <TextField variant="outlined" size="small" type={type} fullWidth value={cellValue} onChange={handleChange} />
  ) : (
    <span>{cellValue}</span>
  )
}

export default EditableCell
