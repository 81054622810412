import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as XLSXStyle from 'xlsx-js-style'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'

import { DateTime } from 'luxon'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'

import ActionButtons from '../../components/action-buttons/action-buttons.component'
import ActiveToggle from '../../components/active-toggle/active-toggle.component'
import ConfirmDialog from '../../components/confirm-dialog/confirm-dialog.component'
import DownTimeCalculate from '../../components/DownTimeCalculate/DownTimeCalculate.component'
import FormDialog from '../../components/form-dialog/form-dialog.component'
import TableContainer from '../../components/table-container/table-container'

import RequestService from '../../services/request/request-service'

import { DELETE_METHOD, POST_METHOD, PUT_METHOD } from '../../shared/constants/requests'
import { ALERT_TYPES, DATE_FORMAT, INCLUDE_INACTIVE_FLAG } from '../../shared/constants/general'
import { DEFAULT_COMFIRM_INACTIVE_MESSAGE, DEFAULT_DEPENDENCIES_ERROR_MESSAGE } from '../../shared/constants/messages'
import { ACTIONS_COLUMN, DEFAULT_ROW_DATA, DEFAULT_TABLE_CONFIGURATION } from '../../shared/constants/table'
import { DOWNTIME_QUERY } from '../../shared/constants/queries'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'
import { OPERATOR_ROLE, SUPER_ADMIN_ROLE } from '../../shared/constants/roles'

import useHttpInactive from '../../shared/hooks/useHttpInactive'
import useMachineQuery from '../../shared/hooks/useMachineQuery'

import {
  convertMinutesToHoursAndMinutes,
  getDateFromByTimeOfUnit,
  TIME_OF_UNIT,
} from '../../shared/utils/datetime-utils'

import useStyles from '../bussines_params/users.styles'

const Downtime = ({ userEmail, role }) => {
  const TimeUnit = {
    Hours: 'hours',
    Minutes: 'minutes',
  }

  const history = useHistory()
  const { t } = useTranslation()
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const [openDialog, setOpen] = useState(false)
  const [openDelDialog, setDelOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isFilter, setIsFilter] = useState(false)
  const [modalType, setModalType] = useState('Add new')
  const [rowData, setRowData] = useState(DEFAULT_ROW_DATA)
  const [rows, setRows] = useState([])
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [openAlert, setOpenAlert] = useState(false)
  const [showReport, setShowReport] = useState(false)
  const [severity, setSeverity] = useState(ALERT_TYPES.success)
  const [deletable, setDeletable] = useState(true)
  const [showActive, setShowActive] = useState(true)
  const [timeUnit, setTimeUnit] = useState(TimeUnit.Minutes)
  const { executeInactive, loadingInactive } = useHttpInactive(DOWNTIME_QUERY)
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString())
  const [totalDownTimes, setTotalDownTimes] = useState('0 hrs, 0 mins.')
  const [totalTimeUnit, setTotalTimeUnit] = useState(TIME_OF_UNIT.DAY)
  const [rowFilter, setRowFilter] = useState(rows)
  const [machines, setMachines] = useState([])
  const [machinesSelectedId, setMachinesSelectedId] = useState(0)
  const [openMachine, setOpenMachine] = useState(false)
  const classes = useStyles()
  const machineQuery = useMachineQuery()

  const columns = [
    {
      field: 'date',
      headerName: t('date_stop'),
      minWidth: 140,
      flex: 1,
      valueFormatter: ({ value }) => DateTime.fromISO(value).toFormat(DATE_FORMAT),
    },
    { field: 'createdBy', headerName: t('username'), minWidth: 150, flex: 1 },
    { field: 'component', headerName: t('component'), minWidth: 150, flex: 1 },
    {
      field: 'id_machine',
      headerName: t('machine'),
      flex: 1,
      minWidth: 135,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={async () => {
            await loadMachines(params?.row?.id_machine || 1)
            setOpenMachine(true)
            setRowData(params?.row)
          }}
          style={{ textTransform: 'none' }}
        >
          {t('see_machine')}
        </Button>
      ),
    },
    { field: 'resolution', headerName: t('resolution'), minWidth: 150, flex: 1 },
    { field: 'description', headerName: t('description'), minWidth: 150, flex: 1 },
    { field: 'downtimeMinutes', headerName: t('minutes_down'), minWidth: 150, flex: 1 },
    {
      field: 'active',
      headerName: t('status'),
      minWidth: 120,
      renderCell: (params) => (params.value ? 'Active' : 'Inactive'),
      flex: 1,
    },
    {
      ...ACTIONS_COLUMN,
      headerName: t(ACTIONS_COLUMN.headerName),
      hide: role !== SUPER_ADMIN_ROLE && role !== OPERATOR_ROLE,
      renderCell: (params) => ActionButtons(params.row, handleClickOpen, handleClickDelOpen),
    },
  ]

  useEffect(() => {
    trackEvent(LOG_PAGE_LOADS.LOAD_LOCATION_PAGE, { email: userEmail })
    loadMachines(machinesSelectedId)
  }, [])

  useEffect(() => {
    loadData()
  }, [tableConf, showActive, selectedYear])

  const onError = (errorMessage) => {
    console.error(errorMessage)
    setOpenAlert(true)
    setSeverity(ALERT_TYPES.error)
    setLoading(false)
  }

  const loadData = async (startDate = null, endDate = null) => {
    try {
      setLoading(true)
      setRows([])
      let url = `${DOWNTIME_QUERY}?${!showActive ? INCLUDE_INACTIVE_FLAG : ''}&startRowIndex=${
        tableConf.page - 1
      }&pageSize=10000`

      if (startDate && endDate) {
        url += `&start_date=${startDate}&end_date=${endDate}`
      } else {
        url += `&selectedYear=${selectedYear}`
      }

      const response = await RequestService.Get(url, history)
      setRows(response.data)
    } catch (error) {
      onError(error)
    } finally {
      setLoading(false)
    }
  }

  const loadMachines = async (id) => {
    try {
      setLoading(true)
      setMachinesSelectedId(id)
      machineQuery.refetch().then((res) => {
        setMachines(res.data)
      })
      setLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmitMachineDowntime = async (event) => {
    event.preventDefault()
    try {
      const payload = {
        ...rowData,
        id_machine: machinesSelectedId,
      }

      setLoading(true)
      insertEditData(PUT_METHOD, payload)

      setOpenMachine(false)
      setLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  // insert and edit
  const insertEditData = async (method, data) => {
    setLoading(true)

    let url = `${DOWNTIME_QUERY}`
    let response
    try {
      if (method === DELETE_METHOD) {
        url = `${url}?id=${data.id}`
        response = await RequestService.Delete(url, history)
        if (response?.hasError) {
          onError(response.errorMessage)
          return
        }
      } else {
        if (method === POST_METHOD) {
          response = await RequestService.Post(url, history, data)
        }
        if (method === PUT_METHOD) {
          response = await RequestService.Put(url, history, data)
        }
      }
      setTableConf({ ...tableConf })
      setSeverity(ALERT_TYPES.success)
      setOpenAlert(true)
      setOpen(false)
    } catch (error) {
      onError(error.errorMessage)
    } finally {
      setLoading(false)
    }
  }

  // Form Dialog handler
  const handleClickOpen = (type, params) => async () => {
    setLoading(true)
    if (type === 'Edit') {
      trackEvent('EDIT_CLICK', { email: userEmail })
      if (params.downtimeMinutes > 60) {
        setTimeUnit(TimeUnit.Hours)
        const times = parseFloat(params.downtimeMinutes) / 60
        setRowData({
          id: params.id,
          date: params.date,
          name: params.name,
          description: params.description,
          id_machine: params.id_machine,
          component: params.component,
          resolution: params.resolution,
          downtimeMinutes: times.toFixed(1),
          active: params.active,
        })
      } else {
        trackEvent('ADD_CLICK', { email: userEmail })
        setTimeUnit(TimeUnit.Minutes)
        setRowData({
          id: params.id,
          date: params.date,
          name: params.name,
          description: params.description,
          id_machine: params.id_machine,
          component: params.component,
          resolution: params.resolution,
          downtimeMinutes: params.downtimeMinutes,
          active: params.active,
        })
      }
    } else {
      setRowData(DEFAULT_ROW_DATA)
    }
    setModalType(type)
    setOpen(true)
    setLoading(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    trackEvent('SUBMIT_CLICK', { email: userEmail })
    const data = { ...rowData }

    if (timeUnit === TimeUnit.Hours) {
      data.downtimeMinutes = parseFloat(data.downtimeMinutes) * 60
    }
    const method = modalType !== 'Edit' ? POST_METHOD : PUT_METHOD
    insertEditData(method, data)
  }

  // Confirm Dialog handlers
  const handleClickDelOpen = (params) => () => {
    setRowData({
      id: params.id,
      date: params.date,
      name: params.name,
      id_machine: params.id_machine,
      component: params.component,
      resolution: params.resolution,
      downtime: params.downtime,
      description: params.description,
      active: !params.active,
    })
    setDelOpen(true)
  }

  const onClickDelConfirmation = async () => {
    const { success } = await executeInactive(rowData.id)
    if (success) {
      setDelOpen(false)
      loadData()
    }
  }

  // Form on change
  const handleChange = (event) => {
    const { name, value } = event.target
    setRowData({
      ...rowData,
      [name]: value,
    })
    switch (name) {
      case 'id_machine':
        setMachinesSelectedId(value)
        break
      default:
        break
    }
  }
  const handleChangeTime = (event) => {
    const { name, value } = event.target

    setRowData({
      ...rowData,
      [name]: value,
    })
  }
  const handleChangeMH = (event) => {
    setTimeUnit(event.target.value)
    trackEvent('EDIT_CLICK', { email: userEmail })
  }

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') return
    setOpenAlert(false)
  }

  const handleChangeMachineSelected = (event) => {
    const { value } = event.target
    setMachinesSelectedId(value)
  }

  useEffect(() => {
    caculateDowntime(totalTimeUnit)
  }, [rows])

  useEffect(() => {
    if (rowFilter?.length > 0) {
      setShowReport(true)
    }
  }, [rowFilter])

  const caculateDowntime = (timeOfUnit) => {
    trackEvent('CHANGE_TYPE_CLICK', { email: userEmail })
    const currentYear = new Date().getFullYear().toString()

    if (isFilter) {
      const totalDownTimeMinutes = rows.reduce((acc, x) => acc + x.downtimeMinutes, 0)
      const textHoursAndMins = convertMinutesToHoursAndMinutes(totalDownTimeMinutes)
      setTotalDownTimes(textHoursAndMins)
      setTotalTimeUnit(timeOfUnit)
      setRowFilter(rows)
    } else {
      const rowFilter =
        selectedYear === currentYear
          ? rows.filter((x) => DateTime.fromISO(x.date).year.toString() === currentYear)
          : rows
      const totalDownTimeMinutes = rowFilter.reduce((acc, x) => acc + x.downtimeMinutes, 0)
      const textHoursAndMins = convertMinutesToHoursAndMinutes(totalDownTimeMinutes)
      setTotalDownTimes(textHoursAndMins)
      setTotalTimeUnit(timeOfUnit)
      setRowFilter(rowFilter)
    }
  }

  const printfReportExcel = () => {
    const headerExcel = []
    const bodyExcel = []
    columns.forEach((header) => {
      if (header.field !== 'action' && header.field !== 'active' && header.field !== 'id') {
        headerExcel.push({
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: header.headerName,
        })
      }
    })

    rowFilter.forEach((row) => {
      bodyExcel.push([
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: DateTime.fromISO(row.date).toFormat(DATE_FORMAT),
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: row.createdBy,
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: row.id_machine,
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: row.component,
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: row.resolution,
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: row.description,
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: row.downtimeMinutes,
        },
      ])
    })

    const downtimeData = [[], headerExcel, ...bodyExcel]
    const downtime = XLSXStyle.utils.aoa_to_sheet(downtimeData)
    const downtimeCols = [{ wch: 15 }, { wch: 20 }, { wch: 20 }, { wch: 70 }, { wch: 70 }, { wch: 15 }]
    downtime['!cols'] = downtimeCols
    const excelFile = XLSXStyle.utils.book_new()
    XLSXStyle.utils.book_append_sheet(excelFile, downtime, 'Downtime')
    XLSXStyle.writeFile(excelFile, `Downtime.xlsx`)
  }

  return (
    <div>
      <FormDialog
        open={openMachine}
        onClose={() => {
          setOpenMachine(false)
        }}
        title={t('machine')}
        loading={loading}
        handleSubmit={handleSubmitMachineDowntime}
      >
        <div>
          <FormControl className={classes.formControl} variant="outlined" fullWidth>
            <Select
              required
              label={t('machine')}
              labelId="select-machine-label"
              onChange={handleChangeMachineSelected}
              defaultValue={machinesSelectedId}
            >
              {machines.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </FormDialog>
      <Box pt={5} pb={3}>
        <Typography component="h1" variant="h5" color="primary">
          {t('down_time_log')}
        </Typography>
      </Box>
      <TableContainer
        header={
          <DownTimeCalculate
            totalDownTimes={totalDownTimes}
            handleTimeOfUnitChange={caculateDowntime}
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
            printfReportExcel={printfReportExcel}
            showReport={showReport}
            loadData={loadData}
            setIsFilter={setIsFilter}
          />
        }
        haveSearchInput="true"
        handleClickOpen={() => handleClickOpen('Add new', null)}
        disableNew={role !== SUPER_ADMIN_ROLE && role !== OPERATOR_ROLE}
        columns={columns}
        rows={rowFilter}
        tableConfiguration={tableConf}
        setTableConf={setTableConf}
        onToggleClick={() => setShowActive(!showActive)}
        showActive={showActive}
        loading={loading}
        role={role}
      />
      <FormDialog
        title={`${modalType} downtime`}
        open={openDialog}
        onClose={() => {
          setTimeUnit(TimeUnit.Minutes)
          setOpen(false)
        }}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <TextField
          defaultValue={rowData.id_machine}
          variant="outlined"
          margin="normal"
          name="id_machine"
          label={t('machine')}
          type="text"
          fullWidth
          select
          onChange={handleChange}
          required
        >
          {machines.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          defaultValue={rowData.component}
          variant="outlined"
          margin="normal"
          name="component"
          label={t('component')}
          type="text"
          fullWidth
          required
          onChange={handleChange}
        />
        <TextField
          defaultValue={rowData.description}
          variant="outlined"
          margin="normal"
          name="description"
          label={t('description')}
          type="text"
          fullWidth
          required
          onChange={handleChange}
        />
        <TextField
          defaultValue={rowData.resolution}
          variant="outlined"
          margin="normal"
          name="resolution"
          label={t('resolution')}
          type="text"
          fullWidth
          required
          onChange={handleChange}
        />
        <Grid container>
          <Grid item xs={6}>
            <TextField
              defaultValue={rowData.downtimeMinutes}
              variant="outlined"
              label={t('down_time')}
              name="downtimeMinutes"
              placeholder="Time"
              margin="normal"
              type="number"
              fullWidth
              required
              inputProps={{ min: 0, step: 'any', pattern: 'd*' }}
              onChange={handleChangeTime}
              onKeyPress={(event) => {
                if (event?.key === '-' || event?.key === '+' || event?.key === '.' || event?.key === 'e') {
                  event.preventDefault()
                }
              }}
              onInput={(event) => {
                event.target.value = Math.max(0, parseInt(event.target.value, 10)).toString().slice(0, 3)
              }}
            />
          </Grid>
          <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
            <TextField
              variant="outlined"
              label={t('time_unit')}
              margin="normal"
              select
              onChange={handleChangeMH}
              value={timeUnit}
            >
              <MenuItem value={TimeUnit.Minutes}>Minutes</MenuItem>
              <MenuItem value={TimeUnit.Hours}>Hours</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        {modalType === 'Edit' && (
          <ActiveToggle value={rowData.active} onToggle={() => setRowData({ ...rowData, active: !rowData.active })} />
        )}
      </FormDialog>
      <ConfirmDialog
        title={t('deactivate_down_time')}
        okButton="Deactivate"
        open={openDelDialog}
        loading={loadingInactive}
        onClose={() => {
          setDelOpen(false)
          setDeletable(true)
        }}
        onOk={onClickDelConfirmation}
      >
        {t(DEFAULT_COMFIRM_INACTIVE_MESSAGE)} this Downtime record?
        {!deletable && (
          <Alert style={{ marginTop: 10 }} severity="error">
            {t(DEFAULT_DEPENDENCIES_ERROR_MESSAGE)}
          </Alert>
        )}
      </ConfirmDialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
  role: state.user.role,
})

export default connect(mapStateToProps, null)(Downtime)
