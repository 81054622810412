import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import FormDialog from '../../components/form-dialog/form-dialog.component'
import ConfirmDialog from '../../components/confirm-dialog/confirm-dialog.component'
import ActionButtons from '../../components/action-buttons/action-buttons.component'
import TableContainer from '../../components/table-container/table-container'
import MultipleSelect from '../../components/multi-select/multi-select'
import ActiveToggle from '../../components/active-toggle/active-toggle.component'
import SearchableInput from '../../components/searchable-input/searchable-input.component'
import ManyToManyInput from '../../components/many-many-input/many-many-input.component'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import RequestService from '../../services/request/request-service'

import { buildPayload, getManyToManyData } from '../../shared/utils/utils'

import {
  ACTIONS_COLUMN,
  BASE_TABLE_COLUMNS,
  DEFAULT_ROW_DATA,
  DEFAULT_TABLE_CONFIGURATION,
} from '../../shared/constants/table'
import { DEFAULT_COMFIRM_INACTIVE_MESSAGE } from '../../shared/constants/messages'
import { POST_METHOD, PUT_METHOD } from '../../shared/constants/requests'
import { ALERT_TYPES, INCLUDE_INACTIVE_FLAG } from '../../shared/constants/general'
import { CATEGORIES_DEFECTS_QUERY, CATEGORIES_VARIETIES_QUERY, CATEGORY_QUERY } from '../../shared/constants/queries'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'
import useHttpInactive from '../../shared/hooks/useHttpInactive'
import useCategoryTypeQuery from '../../shared/hooks/useCategoryTypeQuery'
import useVarietyQuery from '../../shared/hooks/useVarietyQuery'
import useDefectQuery from '../../shared/hooks/useDefectQuery'
import useCategoryQuery from '../../shared/hooks/useCategoryQuery'

const Category = ({ userEmail }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const [openDialog, setOpen] = useState(false)
  const [openDelDialog, setDelOpen] = useState(false)
  const [defectsOpen, setDefectsOpen] = useState(false)
  const [varietiesOpen, setVarietiesOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalType, setModalType] = useState('Add new')
  const [rowData, setRowData] = useState(DEFAULT_ROW_DATA)
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [openAlert, setOpenAlert] = useState(false)
  const [severity, setSeverity] = useState(ALERT_TYPES.success)
  const [filterValue, setFilterValue] = useState(0)
  const [showActive, setShowActive] = useState(true)
  const [selectedDefects, setSelectedDefects] = useState([])
  const [selectedVarieties, setSelectedVarieties] = useState([])
  const { executeInactive, loadingInactive } = useHttpInactive(CATEGORY_QUERY)

  const categoryQuery = useCategoryQuery({
    filter: { id_category_type: filterValue === 0 ? 0 : filterValue },
    showActive,
    startRowIndex: tableConf.page - 1,
    pageSize: 10000,
  })
  const categoryTypeQuery = useCategoryTypeQuery()
  const varietyQuery = useVarietyQuery()
  const defectQuery = useDefectQuery()
  // columns definition for table

  const translatedColumns = BASE_TABLE_COLUMNS.map((column) => ({
    ...column,
    headerName: t(column.headerName),
  }))

  const columns = [
    ...translatedColumns,
    {
      field: 'defects',
      headerName: t('defects'),
      flex: 1,
      minWidth: 135,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id, id_category_type: params.row.id_category_type })
            setDefectsOpen(true)
          }}
          style={{ textTransform: 'none' }}
        >
          {t('see_defects')}
        </Button>
      ),
    },
    {
      field: 'varieties',
      headerName: t('varieties'),
      flex: 1,
      minWidth: 135,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id, id_category_type: params.row.id_category_type })
            setVarietiesOpen(true)
          }}
          style={{ textTransform: 'none' }}
        >
          {t('see_varieties')}
        </Button>
      ),
    },
    {
      ...ACTIONS_COLUMN,
      headerName: t(ACTIONS_COLUMN.headerName),
      renderCell: (params) => ActionButtons(params.row, handleClickOpen, handleClickDelOpen),
    },
  ]

  // load category types
  useEffect(async () => {
    const loadData = async () => {
      try {
        setLoading(true)
        categoryTypeQuery.refetch()
        defectQuery.refetch()
        varietyQuery.refetch()

        setLoading(false)
      } catch (error) {
        onError(error)
      }
    }
    await loadData()
    trackEvent(LOG_PAGE_LOADS.LOAD_CATEGORY_PAGE, { email: userEmail })
  }, [])

  // load data
  useEffect(() => {
    loadData()
  }, [tableConf, filterValue, showActive])

  const onError = (errorMessage) => {
    console.error(errorMessage)
    setOpenAlert(true)
    setSeverity(ALERT_TYPES.error)
    setLoading(false)
  }

  const loadData = async () => {
    try {
      setLoading(true)
      categoryQuery.refetch()
    } catch (error) {
      console.error(error)
      setSeverity(ALERT_TYPES.error)
      setOpenAlert(true)
    } finally {
      setLoading(false)
    }
  }

  // insert and edit
  const insertEditData = async (method, data) => {
    setLoading(true)
    setOpen(false)

    let url = `${CATEGORY_QUERY}`
    let response
    if (method === PUT_METHOD) response = await RequestService.Put(url, history, data)
    if (method === POST_METHOD) response = await RequestService.Post(url, history, data)

    if (response?.hasError) {
      onError(response.errorMessage)
      return
    }

    let id_category
    let id_category_type
    if (method === PUT_METHOD) {
      id_category = response.data.id
      id_category_type = response.data.id_category_type
    }
    if (method === POST_METHOD) {
      id_category = response.data.id
      id_category_type = response.data.id_category_type
    }

    const categoryDefectsPayload = buildPayload(id_category, selectedDefects, { id_category_type })
    url = `${CATEGORIES_DEFECTS_QUERY}`
    response = await RequestService.Put(url, history, categoryDefectsPayload, { isBlankInstance: true })
    if (response?.hasError) {
      onError(response.errorMessage)
      return
    }

    const categoryVarietiesPayload = buildPayload(id_category, selectedVarieties, { id_category_type })
    url = `${CATEGORIES_VARIETIES_QUERY}`
    response = await RequestService.Put(url, history, categoryVarietiesPayload, { isBlankInstance: true })
    if (response?.hasError) {
      onError(response.errorMessage)
      return
    }

    setTableConf({ ...tableConf })
    setSeverity(ALERT_TYPES.success)
    setSelectedDefects([])
    setSelectedVarieties([])
    setOpenAlert(true)
    setLoading(false)
  }

  // Form Dialog handler
  const handleClickOpen = (type, params) => async () => {
    setLoading(true)
    if (type === 'Edit') {
      trackEvent('EDIT_CLICK', { email: userEmail })
      const categoriesDefects = await getManyToManyData(
        `${CATEGORIES_DEFECTS_QUERY}?id=${params.id}`,
        history,
        defectQuery.data,
        true
      )
      const categoriesVarieties = await getManyToManyData(
        `${CATEGORIES_VARIETIES_QUERY}?id=${params.id}`,
        history,
        varietyQuery.data,
        true
      )
      setSelectedDefects(categoriesDefects)
      setSelectedVarieties(categoriesVarieties)

      setRowData({
        id: params.id,
        name: params.name,
        description: params.description,
        active: params.active,
        id_category_type: params.id_category_type,
      })
    } else {
      trackEvent('ADD_CLICK', { email: userEmail })
      setSelectedVarieties([])
      setSelectedDefects([])
      setRowData(DEFAULT_ROW_DATA)
    }
    setModalType(type)
    setOpen(true)
    setLoading(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = { ...rowData }
    const method = modalType !== 'Edit' ? POST_METHOD : PUT_METHOD
    trackEvent('SUBMIT_CLICK', { email: userEmail })
    insertEditData(method, data)
  }

  // Confirm Dialog handlers
  const handleClickDelOpen = (params) => () => {
    trackEvent('DELETE_CLICK', { email: userEmail })
    setRowData({
      id: params.id,
      name: params.name,
      description: params.description,
      active: !params.active,
      id_category_type: params.id_category_type,
    })
    setDelOpen(true)
  }

  const onClickDelConfirmation = async () => {
    const { success } = await executeInactive(rowData.id)
    if (success) {
      setDelOpen(false)
      loadData()
    }
  }

  // Form on change
  const handleChange = (event) => {
    const { name, value } = event.target
    setRowData({
      ...rowData,
      [name]: value,
    })
  }

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') return
    setOpenAlert(false)
  }

  return (
    <div>
      <Box pt={5} pb={3}>
        <Typography component="h1" variant="h5" color="primary">
          {t('categories')}
        </Typography>
      </Box>
      <TableContainer
        haveSearchInput="true"
        filterValues={categoryTypeQuery.data}
        filterValue={filterValue}
        filter={t('category_type')}
        filterChange={(event) => {
          setFilterValue(event.target.value)
        }}
        handleClickOpen={() => handleClickOpen('Add new', null)}
        columns={columns}
        rows={categoryQuery.data}
        tableConfiguration={tableConf}
        setTableConf={setTableConf}
        onToggleClick={() => setShowActive(!showActive)}
        showActive={showActive}
        loading={loading}
      />
      <MultipleSelect
        open={defectsOpen}
        onClose={() => {
          setDefectsOpen(false)
        }}
        title={t('defects')}
        getUrl="defect"
        getRelationUrl="categoriesdefects"
        mainId={rowData.id}
        lefttoright
        additionalData={{
          id_category_type: rowData.id_category_type,
        }}
      />
      <MultipleSelect
        open={varietiesOpen}
        onClose={() => {
          setVarietiesOpen(false)
        }}
        title={t('varieties')}
        getUrl="variety"
        getRelationUrl="categoriesvarieties"
        mainId={rowData.id}
        lefttoright
        additionalData={{
          id_category_type: rowData.id_category_type,
        }}
      />
      <FormDialog
        title={`${modalType} category`}
        open={openDialog}
        onClose={() => {
          setOpen(false)
        }}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <TextField
          defaultValue={rowData.name}
          autoFocus
          variant="outlined"
          margin="normal"
          name="name"
          label={t('name')}
          type="text"
          fullWidth
          onChange={handleChange}
          required
        />
        <TextField
          defaultValue={rowData.description}
          variant="outlined"
          margin="normal"
          name="description"
          label={t('description')}
          type="text"
          fullWidth
          onChange={handleChange}
        />
        <SearchableInput
          value={categoryTypeQuery.data?.find((gr) => gr.id === rowData.id_category_type) ?? 0}
          options={categoryTypeQuery.data}
          onChange={(event) => handleChange(event)}
          label={t('category_type')}
          id="id_category_type"
          name="id_category_type"
        />
        <ManyToManyInput
          inputLabel={t('defects')}
          name="id_defects"
          selectedValues={selectedDefects}
          allValues={defectQuery.data}
          setSelectedValues={setSelectedDefects}
        />
        <ManyToManyInput
          inputLabel={t('varieties')}
          name="id_variety"
          selectedValues={selectedVarieties}
          allValues={varietyQuery.data}
          setSelectedValues={setSelectedVarieties}
        />
        {modalType === 'Edit' && (
          <ActiveToggle value={rowData.active} onToggle={() => setRowData({ ...rowData, active: !rowData.active })} />
        )}
      </FormDialog>
      <ConfirmDialog
        title={t('deactivate_category')}
        okButton="Deactivate"
        open={openDelDialog}
        loading={loadingInactive}
        onClose={() => {
          setDelOpen(false)
        }}
        onOk={onClickDelConfirmation}
      >
        {t(DEFAULT_COMFIRM_INACTIVE_MESSAGE)} {rowData.name}?
      </ConfirmDialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
})

export default connect(mapStateToProps, null)(Category)
