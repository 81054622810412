import { useQuery } from 'react-query'
import RequestService from '../../services/request/request-service'
import { INCLUDE_INACTIVE_FLAG } from '../constants/general'
import { PACKMAN_QUERY } from '../constants/queries'

const usePackmanQuery = ({
  byFacility = false,
  showActive = true,
  page = 0,
  pageZize = 10000,
  filter = { id_facility: 0 },
} = {}) => {
  return useQuery({
    queryKey: [PACKMAN_QUERY, showActive, page, pageZize],
    queryFn: async ({ queryKey }) => {
      let url = ''
      if (!byFacility) {
        url = `${queryKey[0]}${!queryKey[1] ? `?${INCLUDE_INACTIVE_FLAG}&` : '?'}startRowIndex=${
          queryKey[2]
        }&pageSize=${queryKey[3]}`
      } else {
        url = `${queryKey[0]}/getmachinesbyidfacility?id_facility=${filter.id_facility}`
      }

      const { data } = await RequestService.Get(url)
      return data
    },
    initialData: [],
  })
}
export default usePackmanQuery
