import { useQuery } from 'react-query'
import RequestService from '../../services/request/request-service'
import { RECEIVING_SUMMARY_QUERY } from '../constants/queries'

const useReceivingReportQuery = ({
  start_date = '1970-01-01T00:00:00.000',
  end_date = '1970-01-01T23:59:59.999',
  machine_id = 0,
  start_time = '',
  end_time = '',
  filter = { id_facility: 0, isPackedInventory: false },
} = {}) => {
  return useQuery({
    queryKey: [RECEIVING_SUMMARY_QUERY, filter, start_date, end_date, machine_id, start_time, end_time],
    queryFn: async ({ queryKey }) => {
      const [query, filter, startDate, endDate, machine_id, start_time, end_time] = queryKey

      const url = `${query}?${filter.id_facility !== 0 ? `id_facility=${filter.id_facility}&` : ''}${
        machine_id !== 0 ? `machine_id=${machine_id}&` : ''
      }start_date=${startDate}Z&end_date=${endDate}Z${start_time !== '' ? `&start_time=${start_time}` : ''}${
        end_time !== '' ? `&end_time=${end_time}` : ''
      }${filter.isPackedInventory ? `&isPackedInventory=${filter.isPackedInventory}` : ''}`
      const { data } = await RequestService.Get(url)
      return data
    },
    initialData: [],
    refetchOnWindowFocus: false,
    enabled: false,
  })
}

export default useReceivingReportQuery
