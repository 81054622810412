import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  alertInformation: {
    topicName: null,
    subscriptionName: null,
  },
  packmanInformation: {
    topicName: null,
    subscriptionName: null,
  },
  dashboardInformation: {
    topicName: null,
    subscriptionName: null,
  },
  notificationInformation: {
    topicName: null,
    subscriptionName: null,
  },
  isLoading: false,
}

export default createSlice({
  name: 'subscription',
  initialState: INITIAL_STATE,
  reducers: {
    setCurrentDashboardInformationAction: (state, action) => {
      state.dashboardInformation = {
        topicName: action.payload.topicName,
        subscriptionName: action.payload.subscriptionName,
      }
    },
    setCurrentPackmanInformationAction: (state, action) => {
      state.packmanInformation = {
        topicName: action.payload.topicName,
        subscriptionName: action.payload.subscriptionName,
      }
    },
    setCurrentAlertInformationAction: (state, action) => {
      state.alertInformation = {
        topicName: action.payload.topicName,
        subscriptionName: action.payload.subscriptionName,
      }
    },
    setCurrentNotificationInformationAction: (state, action) => {
      state.notificationInformation = {
        topicName: action.payload.topicName,
        subscriptionName: action.payload.subscriptionName,
      }
    },
    setIsLoadingAction: (state, action) => {
      state.isLoading = action.payload
    },
  },
})
