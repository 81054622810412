import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import * as XLSXStyle from 'xlsx-js-style'

import Grid from '@material-ui/core/Grid'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import AppInsightsTrackingContext from '../../../context/app-insights-tracking/AppInsightsTrackingContext'
import SimpleBackdrop from '../../../components/simple-backdrop/simple-backdrop.component'
import SingleDateTimePicker from '../../../components/single-datetimepicker/simple-datetimepicker.component'
import PrimaryButton from '../../../components/button/button.component'
import SearchableInput from '../../../components/searchable-input/searchable-input.component'
import PrintButton from '../../../components/print-button/print-button.component'

import RequestService from '../../../services/request/request-service'
import { PACK_TYPE_REPORT_QUERY, PACKTYPE_QUERY, MACHINE_QUERY, VARIETY_QUERY } from '../../../shared/constants/queries'
import { numberToPercentage } from '../../../shared/utils/utils'

import PdfMakerService from '../../../services/pdf-maker/pdf-maker-service'
import makeStyles from '../reports.styles'
import reportsSlice from '../../../redux/reports/reports.slice'

const PackTypeSummary = ({
  currentFacility,
  currentMachine,
  setSelectedMachine,
  selectedMachine,
  desTemplates,
  userEmail,
}) => {
  const classes = makeStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const { trackEvent } = useContext(AppInsightsTrackingContext)
  const [showBackdrop, setShowBackdrop] = useState(false)
  const [showReport, setShowReport] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [packTypeSummaryData, setPackTypeSummaryData] = useState({
    packTypeQualitySummary: [],
    packTypeSizingSummary: [],
  })
  const [packTypes, setPackTypes] = useState([])
  const [varieties, setVarieties] = useState([])
  const [dateReport, setDateReport] = useState('')
  const [totalFinishedBoxSizing, setTotalFinishedBoxSizing] = useState([])
  const [totalFinishedBoxQuality, setTotalFinishedBoxQuality] = useState({})
  const [startDate, setStartDate] = useState(DateTime.now().startOf('day').toISO({ includeOffset: false }))
  const [endDate, setEndDate] = useState(DateTime.now().endOf('day').toISO({ includeOffset: false }))
  const [startDateISODate, setStartDateISODate] = useState(DateTime.now().startOf('day').toISODate())
  const [endDateISODate, setEndDateISODate] = useState(DateTime.now().endOf('day').toISODate())
  const [machines, setMachines] = useState([])
  const [machineSelected, setMachineSelected] = useState({})
  const [machineName, setMachineName] = useState('')
  const [sizeSum, setSizeSum] = useState(true)
  const tableCellClassNames = `${classes.tableCell}`

  useEffect(async () => {
    await initLoadData()
  }, [])

  useEffect(() => {
    if (
      packTypes.length === 0 ||
      (packTypeSummaryData.packTypeSizingSummary?.length === 0 &&
        packTypeSummaryData.packTypeQualitySummary?.length === 0)
    ) {
      setShowReport(false)
    } else {
      let DateReport = ''
      if (DateTime.fromISO(startDate).toFormat('MMM-dd-yy') === DateTime.fromISO(endDate).toFormat('MMM-dd-yy')) {
        DateReport = DateTime.fromISO(startDate).toFormat('MMM-dd-yy')
      } else {
        DateReport = `${DateTime.fromISO(startDate).toFormat('MMM-dd-yy')} to ${DateTime.fromISO(endDate).toFormat(
          'MMM-dd-yy'
        )}`
      }
      setDateReport(DateReport)
      caculateTotal()
      setShowReport(true)
    }
  }, [packTypes, packTypeSummaryData])

  useEffect(() => {
    if (selectedMachine) {
      if (selectedMachine.id === 0) {
        if (currentMachine) {
          setSelectedMachine(currentMachine)
        }
      }
    }
  }, [currentMachine])

  const loadData = async () => {
    trackEvent('FILTE_BUTTON_CLICK', { email: userEmail })
    if (endDateISODate >= startDateISODate) {
      try {
        setPackTypeSummaryData({
          packTypeQualitySummary: [],
          packTypeSizingSummary: [],
        })
        setIsLoading(true)

        const filter = [`id_facility=${currentFacility.id}`, `start_date=${startDate}`, `end_date=${endDate}`]
        if (machineSelected?.id > 0) {
          filter.push(`id_machine=${machineSelected.id}`)
          setMachineName(
            machineSelected.name ? machineSelected.name : machineSelected.description ? machineSelected.description : ''
          )
        } else {
          setMachineName('')
        }

        const url = `${PACK_TYPE_REPORT_QUERY}?${filter.join('&')}`
        const response = await RequestService.Get(url, history)
        await setPackTypeSummaryData(response.data)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const initLoadData = async () => {
    let url = `${PACKTYPE_QUERY}?startRowIndex=0&pageSize=10000`
    const tskPacktype = RequestService.Get(url, history)

    url = `${VARIETY_QUERY}?startRowIndex=0&pageSize=10000`
    const tskVariety = RequestService.Get(url, history)

    url = `${MACHINE_QUERY}/getmachinesbyidfacility?id_facility=${currentFacility.id}`
    const tskMachine = RequestService.Get(url, history)

    const [resPackType, resVariety, resMachine] = await Promise.all([tskPacktype, tskVariety, tskMachine])

    setVarieties(resVariety.data)
    setPackTypes(resPackType.data)
    setMachines(resMachine.data)
  }

  const packTypeIdToName = (id_packtype) => {
    const packName = packTypes.find((x) => x.id === id_packtype)
    if (packName) return packName.name
    return ''
  }
  const varietyIdToName = (id_variety) => {
    const varietyName = varieties.find((x) => x.id === id_variety)
    if (varietyName) return varietyName.name
    return ''
  }
  const caculateTotal = () => {
    setTotalFinishedBoxQuality({})
    setTotalFinishedBoxSizing([])
    const TotalFinishedBoxSizing = []
    const TotalFinishedBoxQuality = { totalEpo: 0, totalMinDef: 0, totalMajDef: 0, totalCount: 0 }
    if (packTypeSummaryData.packTypeSizingSummary !== null) {
      packTypeSummaryData?.packTypeSizingSummary.forEach((item) => {
        if (item?.id_packtype !== null) {
          const TotalFinishBoxPerPackType = { packtype: item.id_packtype, variety: item?.variery_sizing[0]?.id_variety }
          let TotalFinishBoxCorrectSizing = 0
          let TotalFinishBoxUnderSizing = 0
          let TotalFinishBoxOverSizing = 0
          let TotalFinishedBoxSizingCount = 0
          item?.variery_sizing?.forEach((itemVariety, index) => {
            if (
              itemVariety?.name_sample_size !== 'Undersize' &&
              itemVariety?.name_sample_size !== 'Oversize' &&
              (itemVariety?.under?.percentage !== 0 ||
                itemVariety?.correct?.percentage !== 0 ||
                itemVariety?.over?.percentage !== 0)
            ) {
              TotalFinishedBoxSizingCount++
              TotalFinishBoxCorrectSizing += itemVariety?.correct?.percentage || 0
              TotalFinishBoxUnderSizing += itemVariety?.under?.percentage || 0
              TotalFinishBoxOverSizing += itemVariety?.over?.percentage || 0
            }
            TotalFinishBoxPerPackType.correct = TotalFinishBoxCorrectSizing
            TotalFinishBoxPerPackType.under = TotalFinishBoxUnderSizing
            TotalFinishBoxPerPackType.over = TotalFinishBoxOverSizing
            TotalFinishBoxPerPackType.count = TotalFinishedBoxSizingCount
          })
          TotalFinishedBoxSizing.push(TotalFinishBoxPerPackType)
        }
      })

      TotalFinishedBoxSizing.forEach((item) => {
        item.correct /= item.count
        item.under /= item.count
        item.over /= item.count
      })
      if (TotalFinishedBoxSizing.length > 0) setTotalFinishedBoxSizing(TotalFinishedBoxSizing)
    }
    if (packTypeSummaryData.packTypeQualitySummary !== null) {
      packTypeSummaryData?.packTypeQualitySummary?.forEach((item) => {
        if (item?.id_packtype !== null) {
          TotalFinishedBoxQuality.totalEpo += item?.estimatedPackOut || 0
          TotalFinishedBoxQuality.totalMinDef += item?.minorDefect || 0
          TotalFinishedBoxQuality.totalMajDef += item?.majorDefect || 0
          TotalFinishedBoxQuality.totalCount++
        }
      })
      TotalFinishedBoxQuality.totalEpo /= TotalFinishedBoxQuality.totalCount
      TotalFinishedBoxQuality.totalMinDef /= TotalFinishedBoxQuality.totalCount
      TotalFinishedBoxQuality.totalMajDef /= TotalFinishedBoxQuality.totalCount
      if (
        !Number.isNaN(TotalFinishedBoxQuality.totalEpo) &&
        !Number.isNaN(TotalFinishedBoxQuality.totalMinDef) &&
        !Number.isNaN(TotalFinishedBoxQuality.totalMajDef)
      ) {
        setTotalFinishedBoxQuality(TotalFinishedBoxQuality)
      }
    }
  }

  const printDocument = () => {
    if (packTypeSummaryData.length === 0) return
    trackEvent('PRINT_REPORT_PACK_TYPESIZING_SUMMARY_CLICK', { email: userEmail })
    if (sizeSum) {
      PdfMakerService.generateReport(`Pack-TypeSizingSummary_${dateReport}`, 'PackTypeSizingSummary', {
        packTypeSummaryData,
        totalFinishedBoxSizing,
        totalFinishedBoxQuality,
        packTypes,
        varieties,
        dateReport,
        machineName,
        desTemplates,
      })
    } else {
      PdfMakerService.generateReport(`Pack-TypeQualitySummary_${dateReport}`, 'PackTypeQualitySummary', {
        packTypeSummaryData,
        totalFinishedBoxSizing,
        totalFinishedBoxQuality,
        packTypes,
        varieties,
        dateReport,
        machineName,
        desTemplates,
      })
    }
  }

  const printfReportExcel = () => {
    trackEvent('EXPORT_EXCEL_PACK_TYPESIZING_SUMMARY_CLICK', { email: userEmail })
    if (sizeSum) {
      const finishBoxSizing = []
      packTypeSummaryData?.packTypeSizingSummary?.forEach((item) => {
        const body = item?.variery_sizing?.map((itemVariety, index) => {
          if (
            itemVariety?.name_sample_size !== 'Undersize' &&
            itemVariety?.name_sample_size !== 'Oversize' &&
            (itemVariety?.under?.percentage !== 0 ||
              itemVariety?.correct?.percentage !== 0 ||
              itemVariety?.over?.percentage !== 0)
          ) {
            return [
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: itemVariety?.name_sample_size,
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(((itemVariety?.under?.percentage || 0) * 100).toFixed(2)),
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(((itemVariety?.correct?.percentage || 0) * 100).toFixed(2)),
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(((itemVariety?.over?.percentage || 0) * 100).toFixed(2)),
              },
            ]
          }
          return null
        })

        finishBoxSizing.push(
          [],
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `${desTemplates.TEMPLATE_LOC_FIN || 'Finished Box'} Size Accuracy ${packTypeIdToName(
                item.id_packtype
              )}`,
            },
          ],
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: varietyIdToName(item?.variery_sizing[0]?.id_variety),
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: 'Under',
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: 'Correct',
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: 'Correct',
            },
          ],
          ...body,
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: 'Total',
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage(
                (
                  (totalFinishedBoxSizing.find(
                    (x) => x.packtype === item.id_packtype && x.variety === item?.variery_sizing[0]?.id_variety
                  )?.under || 0) * 100
                ).toFixed(2)
              ),
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage(
                (
                  (totalFinishedBoxSizing.find(
                    (x) => x.packtype === item.id_packtype && x.variety === item?.variery_sizing[0]?.id_variety
                  )?.correct || 0) * 100
                ).toFixed(2)
              ),
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage(
                (
                  (totalFinishedBoxSizing.find(
                    (x) => x.packtype === item.id_packtype && x.variety === item?.variery_sizing[0]?.id_variety
                  )?.over || 0) * 100
                ).toFixed(2)
              ),
            },
          ]
        )
      })
      const sizeDistributionData = [
        [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: 'Pack-Type Sizing Summary',
          },
        ],
        [],
        ...finishBoxSizing,
      ]

      const sizeDistribution = XLSXStyle.utils.aoa_to_sheet(sizeDistributionData)
      const excelFile = XLSXStyle.utils.book_new()
      XLSXStyle.utils.book_append_sheet(excelFile, sizeDistribution, 'Pack-Type Sizing Summary')
      XLSXStyle.writeFile(excelFile, `Pack-TypeSizingSummary_${dateReport}.xlsx`)
    } else {
      const finishBoxQuality = []
      if (packTypeSummaryData?.packTypeQualitySummary?.length > 0 && totalFinishedBoxQuality?.totalCount) {
        const body = packTypeSummaryData.packTypeQualitySummary.map((item, index) => {
          if (item?.id_packtype !== null) {
            return [
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: packTypeIdToName(item.id_packtype),
              },

              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(((item?.estimatedPackOut || 0) * 100).toFixed(2)),
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(((item?.minorDefect || 0) * 100).toFixed(2)),
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(((item?.majorDefect || 0) * 100).toFixed(2)),
              },
            ]
          }
          return null
        })
        finishBoxQuality.push(
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `${desTemplates.TEMPLATE_LOC_FIN || 'Finished Box'} Quality`,
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: 'Correct',
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: 'Minor Defect',
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: 'Major Defect',
            },
          ],
          ...body,
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: 'Total',
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((totalFinishedBoxQuality.totalEpo * 100).toFixed(2)),
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((totalFinishedBoxQuality.totalMinDef * 100).toFixed(2)),
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((totalFinishedBoxQuality.totalMajDef * 100).toFixed(2)),
            },
          ]
        )
      }

      const sizeDistributionData = [
        [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: 'Pack-Type Quality Summary',
          },
        ],
        [],
        ...finishBoxQuality,
      ]

      const sizeDistribution = XLSXStyle.utils.aoa_to_sheet(sizeDistributionData)
      const sizeDistributionCols = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }]
      sizeDistribution['!cols'] = sizeDistributionCols
      const excelFile = XLSXStyle.utils.book_new()
      XLSXStyle.utils.book_append_sheet(excelFile, sizeDistribution, 'Pack-Type Quality Summary')
      XLSXStyle.writeFile(excelFile, `Pack-TypeQualitySummary_${dateReport}.xlsx`)
    }
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <SearchableInput
            disabled={machines.length === 0}
            value={machines.find((machine) => machine.id === selectedMachine?.id) ?? 0}
            options={machines}
            onChange={(event) => {
              const { value } = event.target
              const machine = machines.find((x) => x.id === value)
              setMachineSelected(machine)
              setSelectedMachine(machine)
            }}
            label={t('machine')}
            id="id_machine"
            name="id_machine"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SingleDateTimePicker
            disabled={isLoading}
            style={{ width: '100%' }}
            label={t('start_date')}
            name="startDate"
            value={startDate}
            disableFuture
            onChange={(date) => {
              const startDate = DateTime.fromJSDate(date)
              setStartDateISODate(startDate.toISODate())
              setStartDate(startDate.toISO({ includeOffset: false }))
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SingleDateTimePicker
            disabled={isLoading}
            style={{ width: '100%' }}
            label={t('end_date')}
            name="endDate"
            value={endDate}
            disableFuture
            onChange={(date) => {
              const endDate = DateTime.fromJSDate(date)
              setEndDateISODate(endDate.toISODate())
              setEndDate(endDate.toISO({ includeOffset: false }))
            }}
          />
        </Grid>
        <Grid xs={6} md={2} item style={{ display: 'flex', alignItems: 'end' }}>
          <PrimaryButton variant="contained" disabled={isLoading} onClick={() => loadData()}>
            {t('filter')}
          </PrimaryButton>
        </Grid>
        <Grid item xs={6} md={1} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
          <FormControlLabel
            control={<Switch checked={sizeSum} onChange={() => setSizeSum(!sizeSum)} name="sizeSum" color="primary" />}
            label={sizeSum ? t('sizing') : t('quality')}
          />
        </Grid>
        {!showReport ? null : <PrintButton printDocument={printDocument} printfReportExcel={printfReportExcel} />}
      </Grid>

      <Grid container id="report">
        {!showReport ? null : (
          <>
            {sizeSum ? (
              <>
                <Grid xs={12} item>
                  {machineSelected?.id > 0 ? (
                    <Typography align="right" variant="subtitle2">
                      {t('machine')}: {machineName}
                    </Typography>
                  ) : null}

                  <Typography align="right" variant="subtitle2">
                    {dateReport}
                  </Typography>
                </Grid>
                <Grid xs={12} item>
                  <Typography align="center" variant="h6">
                    {t('pack_type_sizing_summary')}
                  </Typography>
                </Grid>
                {packTypeSummaryData?.packTypeSizingSummary?.map((item) => {
                  if (item?.id_packtype !== null) {
                    return (
                      <Grid
                        key={`Finishbox PackType ${item?.id_packtype}`}
                        xs={12}
                        md={6}
                        xl={3}
                        item
                        className={classes.grid}
                      >
                        <Typography align="left" variant="h6">
                          {desTemplates.TEMPLATE_LOC_FIN || t('finished_box')} {t('size_accuracy')}{' '}
                          {packTypeIdToName(item.id_packtype)}
                        </Typography>
                        <TableContainer style={{ width: '98%' }}>
                          <Table size="small" key={`Finished Box Size Accuracy ${packTypeIdToName(item.id_packtype)}`}>
                            <TableBody>
                              <TableRow key={`Finished Box Size Accuracy ${packTypeIdToName(item.id_packtype)} Header`}>
                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                  {varietyIdToName(item?.variery_sizing[0]?.id_variety)}
                                </TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                  {t('under')}
                                </TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                  {t('correct')}
                                </TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                  {t('over')}
                                </TableCell>
                              </TableRow>
                              {item?.variery_sizing?.map((itemVariety, index) => {
                                if (
                                  itemVariety?.name_sample_size !== 'Undersize' &&
                                  itemVariety?.name_sample_size !== 'Oversize' &&
                                  (itemVariety?.under?.percentage !== 0 ||
                                    itemVariety?.correct?.percentage !== 0 ||
                                    itemVariety?.over?.percentage !== 0)
                                ) {
                                  return (
                                    <TableRow
                                      key={`Finished Box Size Accuracy ${packTypeIdToName(item.id_packtype)} row`}
                                    >
                                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                        {itemVariety?.name_sample_size}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {numberToPercentage(((itemVariety?.under?.percentage || 0) * 100).toFixed(2))}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {numberToPercentage(((itemVariety?.correct?.percentage || 0) * 100).toFixed(2))}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {numberToPercentage(((itemVariety?.over?.percentage || 0) * 100).toFixed(2))}
                                      </TableCell>
                                    </TableRow>
                                  )
                                }
                                return null
                              })}
                              <TableRow key={`Finished Box Size Accuracy ${packTypeIdToName(item.id_packtype)} Total`}>
                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                  {t('total')}
                                </TableCell>

                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                  {numberToPercentage(
                                    (
                                      (totalFinishedBoxSizing.find(
                                        (x) =>
                                          x.packtype === item.id_packtype &&
                                          x.variety === item?.variery_sizing[0]?.id_variety
                                      )?.under || 0) * 100
                                    ).toFixed(2)
                                  )}
                                </TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                  {numberToPercentage(
                                    (
                                      (totalFinishedBoxSizing.find(
                                        (x) =>
                                          x.packtype === item.id_packtype &&
                                          x.variety === item?.variery_sizing[0]?.id_variety
                                      )?.correct || 0) * 100
                                    ).toFixed(2)
                                  )}
                                </TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                  {numberToPercentage(
                                    (
                                      (totalFinishedBoxSizing.find(
                                        (x) =>
                                          x.packtype === item.id_packtype &&
                                          x.variety === item?.variery_sizing[0]?.id_variety
                                      )?.over || 0) * 100
                                    ).toFixed(2)
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )
                  }
                  return null
                })}
              </>
            ) : (
              <>
                <Grid xs={12} item>
                  {machineSelected?.id > 0 ? (
                    <Typography align="right" variant="subtitle2">
                      {t('machine')}: {machineName}
                    </Typography>
                  ) : null}

                  <Typography align="right" variant="subtitle2">
                    {dateReport}
                  </Typography>
                </Grid>
                <Grid xs={12} item>
                  <Typography align="center" variant="h6">
                    {t('pack_type_quality_summary')}
                  </Typography>
                </Grid>

                {packTypeSummaryData?.packTypeQualitySummary?.length > 0 && totalFinishedBoxQuality?.totalCount > 0 ? (
                  <Grid xs={12} md={6} xl={4} item className={classes.grid}>
                    <Typography align="left" variant="h6">
                      {desTemplates.TEMPLATE_LOC_FIN || 'Finished Box'} Quality
                    </Typography>
                    <TableContainer style={{ width: '98%' }}>
                      <Table key="Finished Box Quality">
                        <TableHead>
                          <TableRow key="Finished Box Quality Header">
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}> </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                              {t('correct')}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                              {t('minor_defect')}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                              {t('major_defect')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody key="Finished Box Quality Body">
                          {packTypeSummaryData.packTypeQualitySummary.map((item, index) => {
                            if (item?.id_packtype !== null) {
                              return (
                                <TableRow key={`Finished Box Quality ${packTypeIdToName(item.id_packtype)} Row`}>
                                  <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                    {packTypeIdToName(item.id_packtype)}
                                  </TableCell>

                                  <TableCell key={`${index}`} className={tableCellClassNames} align="center">
                                    {numberToPercentage(((item?.estimatedPackOut || 0) * 100).toFixed(2))}
                                  </TableCell>
                                  <TableCell key={`${index}`} className={tableCellClassNames} align="center">
                                    {numberToPercentage(((item?.minorDefect || 0) * 100).toFixed(2))}
                                  </TableCell>
                                  <TableCell key={`${index}`} className={tableCellClassNames} align="center">
                                    {numberToPercentage(((item?.majorDefect || 0) * 100).toFixed(2))}
                                  </TableCell>
                                </TableRow>
                              )
                            }
                            return null
                          })}

                          <TableRow key="Finished Box Quality Total">
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Total</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {numberToPercentage((totalFinishedBoxQuality.totalEpo * 100).toFixed(2))}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {numberToPercentage((totalFinishedBoxQuality.totalMinDef * 100).toFixed(2))}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {numberToPercentage((totalFinishedBoxQuality.totalMajDef * 100).toFixed(2))}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                ) : (
                  <Grid xs={12} item className={classes.grid}>
                    <TableContainer>
                      <div className={classes.noDataContainer} />
                    </TableContainer>
                  </Grid>
                )}
              </>
            )}
            <div />
          </>
        )}
        <SimpleBackdrop open={showBackdrop} />
      </Grid>
    </>
  )
}

const mapStateToProps = (state) => ({
  selectedLot: state.reports.selectedLot,
  selectedLotName: state.reports.selectedLotName,
  selectedVariety: state.reports.selectedVariety,
  selectedVarietyName: state.reports.selectedVarietyName,
  selectedGrowerName: state.reports.selectedGrowerName,
  selectedStartDate: state.reports.selectedStartDate,
  selectedEndDate: state.reports.selectedEndDate,
  currentFacility: state.facility.currentFacility,
  currentMachine: state.machine,
  selectedMachine: state.reports.selectedMachine,
  userEmail: state.user.email,
})

const mapDispatchToProps = (dispatch) => ({
  setSelectedMachine: (machine) => dispatch(reportsSlice.actions.updateSelectedMachine(machine)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PackTypeSummary)
