import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  colorPrimary: {
    backgroundColor: '#e0e0e0',
  },
  bar: {
    transition: 'width 0.5s ease-in-out',
  },
  completed: {
    backgroundColor: '#4caf50',
  },
  failed: {
    backgroundColor: '#f44336',
  },
  canceled: {
    backgroundColor: '#ff9800',
  },
})

function LinearProgressWithLabel({ value, status }) {
  const classes = useStyles()
  let progressBarClass = ''

  if (value === 100) {
    if (status === 'Completed') progressBarClass = classes.completed
    if (status === 'Failed') progressBarClass = classes.failed
    if (status === 'Canceled') progressBarClass = classes.canceled
  }

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          value={value}
          classes={{
            root: classes.colorPrimary,
            bar: `${classes.bar} ${progressBarClass}`,
          }}
        />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  status: PropTypes.string,
}

LinearProgressWithLabel.defaultProps = {
  status: '',
}

export default LinearProgressWithLabel
