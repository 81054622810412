import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Icon from '@material-ui/core/Icon'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import Grid from '@material-ui/core/Grid'
import FormDialog from '../../components/form-dialog/form-dialog.component'
import ActionButtons from '../../components/action-buttons/action-buttons.component'
import TableContainer from '../../components/table-container/table-container'
import MultipleSelect from '../../components/multi-select/multi-select'
import ConfirmDialog from '../../components/confirm-dialog/confirm-dialog.component'
import ActiveToggle from '../../components/active-toggle/active-toggle.component'
import SearchableInput from '../../components/searchable-input/searchable-input.component'
import ManyToManyInput from '../../components/many-many-input/many-many-input.component'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import RequestService from '../../services/request/request-service'

import { buildPayload, getManyToManyData } from '../../shared/utils/utils'
import { DEFAULT_COMFIRM_INACTIVE_MESSAGE } from '../../shared/constants/messages'
import { POST_METHOD, PUT_METHOD } from '../../shared/constants/requests'
import { INCLUDE_INACTIVE_FLAG } from '../../shared/constants/general'
import { ACTIONS_COLUMN, DEFAULT_ROW_DATA, DEFAULT_TABLE_CONFIGURATION } from '../../shared/constants/table'
import {
  LOT_QUERY,
  LOTS_FACILITIES_QUERY,
  LOTS_VARIETIES_QUERY,
  USERS_FACILITIES_QUERY,
} from '../../shared/constants/queries'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'
import { OPERATOR_ROLE, SUPER_ADMIN_ROLE, QUALITY_CONTROL_MANAGER_ROLE } from '../../shared/constants/roles'
import useHttpInactive from '../../shared/hooks/useHttpInactive'

import useFacilityQuery from '../../shared/hooks/useFacilityQuery'
import useGrowerQuery from '../../shared/hooks/useGrowerQuery'
import useVarietyQuery from '../../shared/hooks/useVarietyQuery'
import { funcExportExcel } from '../../components/export-to-excel/exportexcel.component'

const Lots = ({ userEmail, role, userId }) => {
  const { trackEvent } = useContext(AppInsightsTrackingContext)
  const history = useHistory()
  const { t } = useTranslation()
  const [openDialog, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [facilitiesOpen, setFacilitiesOpen] = useState(false)
  const [varietiesOpen, setVarietiesOpen] = useState(false)
  const [rows, setRows] = useState([])
  const [lots, setLots] = useState([])
  const [rowData, setRowData] = useState({ ...DEFAULT_ROW_DATA, id_grower: 0 })
  const [modalType, setModalType] = useState('Add new')
  const [openDelDialog, setOpenDelDialog] = useState(false)
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [facilitiesCurrentUser, setFacilitiesCurrentUser] = useState([])

  const [selectedFacilities, setSelectedFacilities] = useState([])
  const [selectedVarieties, setSelectedVarieties] = useState([])
  const [showActive, setShowActive] = useState(true)
  const [isAdminShow, setIsAdminShow] = useState(true)
  const { executeInactive, loadingInactive } = useHttpInactive(LOT_QUERY)

  const facilityQuery = useFacilityQuery()
  const growerQuery = useGrowerQuery()
  const varietyQuery = useVarietyQuery()

  // columns definition for table
  const columns = [
    { field: 'name', headerName: t('name'), minWidth: 115, flex: 1 },
    { field: 'growerName', headerName: t('grower'), minWidth: 115, flex: 1 },
    { field: 'description', headerName: t('description'), minWidth: 150, flex: 1 },
    {
      field: 'active',
      headerName: t('status'),
      minWidth: 120,
      renderCell: (params) => (params.value ? 'Active' : 'Inactive'),
      flex: 1,
    },
    {
      field: 'facilities',
      headerName: t('facilities'),
      minWidth: 135,
      flex: 1,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id })
            setFacilitiesOpen(true)
          }}
          style={{ textTransform: 'none' }}
        >
          {t('see_facilities')}
        </Button>
      ),
    },
    {
      field: 'varieties',
      headerName: t('varieties'),
      minWidth: 135,
      flex: 1,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id })
            setVarietiesOpen(true)
          }}
          style={{ textTransform: 'none' }}
        >
          {t('see_varieties')}
        </Button>
      ),
    },
    {
      ...ACTIONS_COLUMN,
      headerName: t(ACTIONS_COLUMN.headerName),
      hide: role !== SUPER_ADMIN_ROLE && role !== QUALITY_CONTROL_MANAGER_ROLE,
      renderCell: (params) => {
        return ActionButtons(params.row, handleClickOpen, handleClickDelOpen)
      },
    },
  ]

  // load data
  useEffect(() => {
    loadData()
  }, [tableConf, showActive])

  useEffect(async () => {
    const loadData = async () => {
      setLoading(true)
      try {
        facilityQuery.refetch()
        growerQuery.refetch()
        varietyQuery.refetch()
        const url = `${USERS_FACILITIES_QUERY}?id=${userId}`
        if (role === OPERATOR_ROLE) {
          const response = await RequestService.Get(url, history)
          setFacilitiesCurrentUser(response.data?.map((x) => x.second) || [])
        }
      } catch (error) {
        onError(error)
      } finally {
        setLoading(false)
      }
    }
    await loadData()
    trackEvent(LOG_PAGE_LOADS.LOAD_LOT_INFORMATION_PAGE, { email: userEmail })
  }, [])

  useEffect(() => {
    if (growerQuery?.data?.length > 0) {
      setRows(
        lots.map((lot) => {
          const grower = growerQuery?.data?.find((grower) => grower.id === lot.id_grower)
          return { ...lot, growerName: grower?.description ? grower.description : grower?.name ? grower.name : '' }
        })
      )
    }
  }, [growerQuery.data, lots])

  const loadData = async () => {
    try {
      setLots([])
      setLoading(true)
      const url = `${LOT_QUERY}?${!showActive ? INCLUDE_INACTIVE_FLAG : ''}&startRowIndex=${
        tableConf.page - 1
      }&pageSize=10000`
      const response = await RequestService.Get(url, history, true)
      const lots = response.data.map((item) => {
        const dateS = item.startingDate ? new Date(item.startingDate).toLocaleString() : ''
        const dateE = item.endDate ? new Date(item.endDate).toLocaleString() : ''
        return { ...item, startingDate: dateS, endDate: dateE }
      })
      setLots(lots)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const onError = (errorMessage) => {
    console.error(errorMessage)
    setLoading(false)
  }

  const insertEditData = async (method, data) => {
    setLoading(true)
    setOpen(false)

    let response
    let url = `${LOT_QUERY}`

    if (method === PUT_METHOD) response = await RequestService.Put(url, history, data)
    if (method === POST_METHOD) response = await RequestService.Post(url, history, data)

    if (response?.hasError) {
      onError(response.errorMessage)
      return
    }

    let id_lot
    if (method === PUT_METHOD) id_lot = response.data.id
    if (method === POST_METHOD) id_lot = response.data.id

    const lotsFacilitiesPayload = buildPayload(id_lot, selectedFacilities)
    url = `${LOTS_FACILITIES_QUERY}`
    response = await RequestService.Put(url, history, lotsFacilitiesPayload, { isBlankInstance: true })
    if (response?.hasError) {
      onError(response.errorMessage)
      return
    }

    const lotsVarietiesPayload = buildPayload(id_lot, selectedVarieties)
    url = `${LOTS_VARIETIES_QUERY}`
    response = await RequestService.Put(url, history, lotsVarietiesPayload, { isBlankInstance: true })
    if (response?.hasError) {
      onError(response.errorMessage)
      return
    }

    setLoading(false)
    setTableConf({ ...tableConf })
    setSelectedFacilities([])
    setSelectedVarieties([])
  }

  // Form on change
  const handleChange = (event) => {
    const { name, value } = event.target
    setRowData({
      ...rowData,
      [name]: value,
    })
  }

  const handleClickOpen = (type, params) => async () => {
    setLoading(true)
    if (type === 'Edit') {
      trackEvent('EDIT_CLICK', { email: userEmail })
      const lotsVarieties = await getManyToManyData(
        `${LOTS_VARIETIES_QUERY}?id=${params.id}`,
        history,
        varietyQuery.data,
        true
      )
      const lotsFacilities = await getManyToManyData(
        `${LOTS_FACILITIES_QUERY}?id=${params.id}`,
        history,
        facilityQuery.data,
        true
      )

      setSelectedVarieties(lotsVarieties)
      setSelectedFacilities(lotsFacilities)
      setRowData({
        id: params.id,
        name: params.name,
        description: params.description,
        active: params.active,
        id_grower: params.id_grower,
      })
    } else {
      trackEvent('ADD_CLICK', { email: userEmail })
      setSelectedVarieties([])
      setSelectedFacilities([])
      setRowData({ ...DEFAULT_ROW_DATA, id_grower: 0 })
    }
    setModalType(type)
    setOpen(true)
    setLoading(false)
  }

  const handleClickDelOpen = (params) => () => {
    trackEvent('DELETE_CLICK', { email: userEmail })
    setRowData({
      id: params.id,
      name: params.name,
      description: params.description,
      active: !params.active,
      id_grower: params.id_grower,
    })
    setOpenDelDialog(true)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = { ...rowData }
    const method = modalType === 'Edit' ? PUT_METHOD : POST_METHOD
    insertEditData(method, data)
  }

  const onClickDelConfirmation = async () => {
    const { success } = await executeInactive(rowData.id)
    if (success) {
      setOpenDelDialog(false)
      loadData()
    }
  }

  const printfReportExcel = () => {
    const header = []
    columns.forEach((item) => {
      if (item.field === 'growerName' || item.field === 'name') header.push(item)
    })
    funcExportExcel(rows, header, `Lot Information`)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box pt={5} pb={3}>
          <Typography
            component="h1"
            variant="h5"
            color="primary"
            style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
          >
            {t('lot_information')} <Icon color="secondary">description</Icon>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TableContainer
          haveSearchInput="true"
          handleClickOpen={() => handleClickOpen('Add new', null)}
          disableNew={role !== SUPER_ADMIN_ROLE && role !== OPERATOR_ROLE && role !== QUALITY_CONTROL_MANAGER_ROLE}
          columns={columns}
          rows={rows}
          tableConfiguration={tableConf}
          setTableConf={setTableConf}
          onToggleClick={() => setShowActive(!showActive)}
          showActive={showActive}
          loading={loading}
          role={role}
          isExport
          printfReportExcel={printfReportExcel}
        />
      </Grid>
      <MultipleSelect
        open={facilitiesOpen}
        onClose={() => {
          setFacilitiesOpen(false)
        }}
        title={t('facilities')}
        getUrl="facility"
        getRelationUrl="lotsfacilities"
        mainId={rowData.id}
        lefttoright
        isAdminShow={isAdminShow}
      />
      <MultipleSelect
        open={varietiesOpen}
        onClose={() => {
          setVarietiesOpen(false)
        }}
        title={t('varieties')}
        getUrl="variety"
        getRelationUrl="lotsvarieties"
        mainId={rowData.id}
        lefttoright
        isAdminShow={isAdminShow}
      />
      <FormDialog
        title={`${modalType} lot`}
        open={openDialog}
        onClose={() => setOpen(false)}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <TextField
          defaultValue={rowData.name}
          autoFocus
          variant="outlined"
          margin="normal"
          name="name"
          label={t('name')}
          type="text"
          fullWidth
          onChange={handleChange}
          required
        />
        <TextField
          defaultValue={rowData.description}
          variant="outlined"
          margin="normal"
          name="description"
          label={t('description')}
          type="text"
          fullWidth
          onChange={handleChange}
        />
        <SearchableInput
          value={growerQuery?.data?.find((gr) => gr.id === rowData.id_grower) ?? 0}
          options={growerQuery.data}
          onChange={(event) => handleChange(event)}
          label={t('grower')}
          id="id_grower"
          name="id_grower"
          required
        />
        <ManyToManyInput
          inputLabel={t('varieties')}
          name="id_varieties"
          selectedValues={selectedVarieties}
          allValues={varietyQuery.data}
          setSelectedValues={setSelectedVarieties}
        />
        <ManyToManyInput
          inputLabel={t('facilities')}
          name="id_facility"
          selectedValues={selectedFacilities}
          allValues={role === OPERATOR_ROLE ? facilitiesCurrentUser : facilityQuery.data}
          setSelectedValues={setSelectedFacilities}
        />
        {modalType === 'Edit' && (
          <ActiveToggle value={rowData.active} onToggle={() => setRowData({ ...rowData, active: !rowData.active })} />
        )}
      </FormDialog>
      <ConfirmDialog
        title={t('deactivate_lot')}
        okButton="Deactivate"
        open={openDelDialog}
        loading={loadingInactive}
        onClose={() => {
          setOpenDelDialog(false)
        }}
        onOk={onClickDelConfirmation}
      >
        {t(DEFAULT_COMFIRM_INACTIVE_MESSAGE)} {rowData.name}?
      </ConfirmDialog>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
  role: state.user.role,
  userId: state.user.id,
})

export default connect(mapStateToProps, null)(Lots)
