import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import FormDialog from '../../components/form-dialog/form-dialog.component'
import ConfirmDialog from '../../components/confirm-dialog/confirm-dialog.component'
import ActionButtons from '../../components/action-buttons/action-buttons.component'
import TableContainer from '../../components/table-container/table-container'
import ActiveToggle from '../../components/active-toggle/active-toggle.component'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import RequestService from '../../services/request/request-service'

import { getManyToManyData } from '../../shared/utils/utils'

import {
  ACTIONS_COLUMN,
  BASE_TABLE_COLUMNS,
  DEFAULT_ROW_DATA,
  DEFAULT_TABLE_CONFIGURATION,
} from '../../shared/constants/table'
import { DEFAULT_DEPENDENCIES_ERROR_MESSAGE, DEFAULT_COMFIRM_INACTIVE_MESSAGE } from '../../shared/constants/messages'
import { DELETE_METHOD, POST_METHOD, PUT_METHOD } from '../../shared/constants/requests'
import {
  PACKMAN_QUERY,
  FACILITY_QUERY,
  OUTLET_QUERY,
  MACHINE_QUERY,
  LOTS_FACILITIES_QUERY,
  LOTS_VARIETIES_QUERY,
} from '../../shared/constants/queries'
import usePackmanQuery from '../../shared/hooks/usePackmanQuery'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'
import useHttpInactive from '../../shared/hooks/useHttpInactive'
import useStyles from './users.styles'

const Packman = ({ userEmail }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const classes = useStyles()
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const [openDialog, setOpen] = useState(false)
  const [openDelDialog, setDelOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalType, setModalType] = useState('Add new')
  const [rowData, setRowData] = useState({})
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [deletable, setDeletable] = useState(true)
  const [showActive, setShowActive] = useState(true)
  const [selectedFacilities, setSelectedFacilities] = useState([])
  const [lotsSelectedId, setLotsSelectedId] = useState(0)
  const [varietiesSelectedId, setVarietiesSelectedId] = useState(0)
  const [outletsSelectedId, setOutletsSelectedId] = useState(0)
  const [machinesSelectedId, setMachinesSelectedId] = useState(0)
  const [facilitySelectedId, setFacilitySelectedId] = useState(0)
  const [facilities, setFacilities] = useState([])
  const [lots, setLots] = useState([])
  const [varieties, setVarieties] = useState([])
  const [outlets, setOutlets] = useState([])
  const [outletsAll, setOutletsAll] = useState([])
  const [machines, setMachines] = useState([])
  const [openFacilities, setOpenFacilities] = useState(false)
  const [openMachine, setOpenMachine] = useState(false)
  const [openVariety, setOpenVariety] = useState(false)
  const [openLot, setOpenLot] = useState(false)
  const [openOutlet, setOpenOutlet] = useState(false)
  const [facilitySelected, setFacilitySelected] = useState(null)
  const packmanQuery = usePackmanQuery({ showActive, startRowIndex: tableConf.page - 1, pageSize: 10000 })
  // columns definition for table
  const translatedColumns = BASE_TABLE_COLUMNS.map((column) => ({
    ...column,
    headerName: t(column.headerName),
  }))

  const columns = [
    {
      field: 'id_packman',
      headerName: 'Id',
      minWidth: 115,
      flex: 1,
    },
    ...translatedColumns,
    {
      field: 'id_facility',
      headerName: t('facility'),
      flex: 1,
      minWidth: 135,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={async () => {
            setFacilitySelectedId(params?.row?.id_facility || 1)
            setOpenFacilities(true)
            setRowData(params?.row)
          }}
          style={{ textTransform: 'none' }}
        >
          {t('see_facility')}
        </Button>
      ),
    },
    {
      field: 'id_machine',
      headerName: t('see_machine'),
      flex: 1,
      minWidth: 135,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={async () => {
            await loadDataReplationMachine(params?.row?.id_machine || 1, params?.row?.id_facility || 1)
            setOpenMachine(true)
            setRowData(params?.row)
          }}
          style={{ textTransform: 'none' }}
        >
          {t('see_machine')}
        </Button>
      ),
    },
    {
      field: 'id_outlet',
      headerName: t('see_outlet'),
      flex: 1,
      minWidth: 135,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={async () => {
            await loadDataReplationOutlet()

            setOutletsSelectedId(params?.row?.id_outlet || 1)
            setOpenOutlet(true)
            setRowData(params?.row)
          }}
          style={{ textTransform: 'none' }}
        >
          {t('see_outlet')}
        </Button>
      ),
    },

    {
      ...ACTIONS_COLUMN,
      headerName: t(ACTIONS_COLUMN.headerName),
      renderCell: (params) => ActionButtons(params.row, handleClickOpen, handleClickDelOpen),
    },
  ]

  useEffect(() => {
    trackEvent(LOG_PAGE_LOADS.LOAD_SAMPLE_TYPE, { email: userEmail })
  }, [])

  const loadDataReplationMachine = async (id, facilityId) => {
    try {
      setLoading(true)
      setMachinesSelectedId(id)
      const url = `${MACHINE_QUERY}/getmachinesbyidfacility?id_facility=${facilityId}`
      const response = await RequestService.Get(url, history)
      setMachines(response.data)
      setLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    trackEvent(LOG_PAGE_LOADS.LOAD_SAMPLE_TYPE, { email: userEmail })
  }, [])
  const loadDataReplationOutlet = async () => {
    try {
      const urlOutlet = `${OUTLET_QUERY}?startRowIndex=0&pageSize=10000&isAvailablePackman=true`
      const responseOulet = await RequestService.Get(urlOutlet, history)
      setOutlets(responseOulet.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    trackEvent(LOG_PAGE_LOADS.LOAD_SAMPLE_TYPE, { email: userEmail })
  }, [])
  // load data
  useEffect(() => {
    loadData()
  }, [tableConf, showActive])

  useEffect(async () => {
    if (facilitySelectedId) {
      let url = `${MACHINE_QUERY}/getmachinesbyidfacility?id_facility=${facilitySelectedId}`
      const response = await RequestService.Get(url, history)
      setMachines(response.data)
      url = `${LOTS_FACILITIES_QUERY}?id=${facilitySelectedId}&filterByFirst=false&all=true`
      const lotResponse = await RequestService.Get(url, history)
      const newLots = lotResponse.data.map((lot) => lot.first).filter((x) => x.active === true)
      setLots(newLots)
    }
  }, [facilitySelectedId])

  useEffect(async () => {
    if (lotsSelectedId) {
      const url = `${LOTS_VARIETIES_QUERY}?id=${lotsSelectedId}`
      const response = await RequestService.Get(url, history)
      const varietiesData = response.data
        .map((item) => {
          return item.second
        })
        .filter((x) => x.active === true)
      setVarieties(varietiesData)
    }
  }, [lotsSelectedId])

  const loadData = async () => {
    try {
      setLoading(true)
      packmanQuery.refetch()
      const url = `${FACILITY_QUERY}?startRowIndex=0&pageSize=10000`
      const response = await RequestService.Get(url, history)
      setFacilities(response.data)
      const urlOutlet = `${OUTLET_QUERY}?startRowIndex=0&pageSize=10000&isAvailablePackman=true`
      const responseOulet = await RequestService.Get(urlOutlet, history)
      setOutlets(responseOulet.data)
      const urlOutletAll = `${OUTLET_QUERY}?startRowIndex=0&pageSize=10000`
      const responseOuletAll = await RequestService.Get(urlOutletAll, history)
      setOutletsAll(responseOuletAll.data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  // insert and edit
  const insertEditData = async (method, data) => {
    try {
      setLoading(true)
      let id = ''
      if (method === DELETE_METHOD) {
        id = `?id=${data.id}`
      }
      const url = `${PACKMAN_QUERY}${id}`
      let response
      if (method === POST_METHOD) response = await RequestService.Post(url, history, data)
      if (method === PUT_METHOD) response = await RequestService.Put(url, history, data)
      if (method === DELETE_METHOD) response = await RequestService.Delete(url, history, data)

      setOpen(false)
      packmanQuery.refetch()
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onError = (errorMessage) => {
    console.error(errorMessage)
    setLoading(false)
  }

  // Form Dialog handler
  const handleClickOpen = (type, params) => async () => {
    if (type === 'Edit') {
      setRowData(params)
      setFacilitySelectedId(params.id_facility)
      setMachinesSelectedId(params.id_machine)
      setLotsSelectedId(params.id_lot)
      setVarietiesSelectedId(params.id_variety)
      setOutletsSelectedId(params.id_outlet)
    } else {
      setRowData({ ...DEFAULT_ROW_DATA })
    }
    setModalType(type)
    setOpen(true)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = { ...rowData }
    const method = modalType === 'Edit' ? PUT_METHOD : POST_METHOD
    insertEditData(method, data)
  }

  // Confirm Dialog handlers
  const handleClickDelOpen = (params) => () => {
    setRowData({
      id: params.id,
      name: params.name,
      description: params.description,
      active: !params.active,
    })
    setDelOpen(true)
  }
  const { executeInactive, loadingInactive } = useHttpInactive(PACKMAN_QUERY)
  const onClickDelConfirmation = async () => {
    const { success } = await executeInactive(rowData.id)
    if (success) {
      setDelOpen(false)
      loadData()
    }
  }

  // Form on change
  const handleChange = (event) => {
    const { name, value } = event.target
    setRowData({
      ...rowData,
      [name]: value,
    })
    switch (name) {
      case 'id_facility':
        setFacilitySelectedId(value)
        break
      case 'id_machine':
        setMachinesSelectedId(value)
        break
      case 'id_outlet':
        setOutletsSelectedId(value)
        break
      default:
        break
    }
  }

  const handleChangeFacilitySelected = (event) => {
    const { value } = event.target
    setFacilitySelectedId(value)
  }
  const handleChangeMachineSelected = (event) => {
    const { value } = event.target
    setMachinesSelectedId(value)
  }
  const handleChangeOutletSelected = (event) => {
    const { value } = event.target
    setOutletsSelectedId(value)
  }
  const handleSubmitFacilityPackman = async (event) => {
    event.preventDefault()
    try {
      const payload = {
        ...rowData,
        id_facility: facilitySelectedId,
      }

      setLoading(true)
      const url = `${PACKMAN_QUERY}`
      const res = await RequestService.Put(url, history, payload, { isBlankInstance: true })
      if (res?.hasError) {
        onError(res.errorMessage)
        return
      }
      setOpenFacilities(false)
      packmanQuery.refetch()
      setLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const handleSubmitMachinePackman = async (event) => {
    event.preventDefault()
    try {
      const payload = {
        ...rowData,
        id_machine: machinesSelectedId,
      }

      setLoading(true)
      const url = `${PACKMAN_QUERY}`
      const res = await RequestService.Put(url, history, payload, { isBlankInstance: true })
      if (res?.hasError) {
        onError(res.errorMessage)
        return
      }

      setOpenMachine(false)
      packmanQuery.refetch()
      setLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const handleSubmitLotPackman = async (event) => {
    event.preventDefault()
    try {
      const payload = {
        ...rowData,
        id_lot: lotsSelectedId,
      }
      setLoading(true)
      const url = `${PACKMAN_QUERY}`
      const res = await RequestService.Put(url, history, payload, { isBlankInstance: true })
      if (res?.hasError) {
        onError(res.errorMessage)
        return
      }

      setOpenLot(false)
      packmanQuery.refetch()
      setLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const handleSubmitVarietyPackman = async (event) => {
    event.preventDefault()
    try {
      const payload = {
        ...rowData,
        id_variety: varietiesSelectedId,
      }
      setLoading(true)
      const url = `${PACKMAN_QUERY}`
      const res = await RequestService.Put(url, history, payload, { isBlankInstance: true })
      if (res?.hasError) {
        onError(res.errorMessage)
        return
      }

      openVariety(false)
      packmanQuery.refetch()
      setLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const handleSubmitOutletPackman = async (event) => {
    event.preventDefault()
    try {
      const payload = {
        ...rowData,
        id_outlet: outletsSelectedId,
      }
      setLoading(true)
      const url = `${PACKMAN_QUERY}`
      const res = await RequestService.Put(url, history, payload, { isBlankInstance: true })
      if (res?.hasError) {
        onError(res.errorMessage)
        return
      }

      setOpenOutlet(false)
      packmanQuery.refetch()
      setLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <div>
      <FormDialog
        open={openFacilities}
        onClose={() => {
          setOpenFacilities(false)
        }}
        title={t('facility')}
        loading={loading}
        handleSubmit={handleSubmitFacilityPackman}
      >
        <div>
          <FormControl className={classes.formControl} variant="outlined" fullWidth>
            <Select
              required
              label={t('facility')}
              labelId="select-facility-label"
              onChange={handleChangeFacilitySelected}
              defaultValue={facilitySelectedId}
            >
              {facilities.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </FormDialog>
      <FormDialog
        open={openMachine}
        onClose={() => {
          setOpenMachine(false)
        }}
        title={t('machine')}
        loading={loading}
        handleSubmit={handleSubmitMachinePackman}
      >
        <div>
          <FormControl className={classes.formControl} variant="outlined" fullWidth>
            <Select
              required
              label={t('machine')}
              labelId="select-machine-label"
              onChange={handleChangeMachineSelected}
              defaultValue={machinesSelectedId}
            >
              {machines.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </FormDialog>
      <FormDialog
        open={openOutlet}
        onClose={() => {
          setOpenOutlet(false)
        }}
        title={t('outlet')}
        loading={loading}
        handleSubmit={handleSubmitOutletPackman}
      >
        <div>
          <FormControl className={classes.formControl} variant="outlined" fullWidth>
            {`Seleted Outlet for this Packman is ${
              outletsAll.find((x) => x.id === outletsSelectedId)?.description || 'none'
            }`}
            <Select
              required
              label={t('available_outlet')}
              labelId="select-outlet-label"
              onChange={handleChangeOutletSelected}
              defaultValue={outletsSelectedId}
            >
              {outlets.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </FormDialog>
      <Box pt={5} pb={3}>
        <Typography component="h1" variant="h5" color="primary">
          {t('packman')}
        </Typography>
      </Box>
      <TableContainer
        haveSearchInput="true"
        handleClickOpen={() => handleClickOpen('Add new', null)}
        columns={columns}
        rows={packmanQuery.data}
        tableConfiguration={tableConf}
        setTableConf={setTableConf}
        onToggleClick={() => setShowActive(!showActive)}
        showActive={showActive}
        loading={loading}
      />
      <FormDialog
        title={`${modalType} packman`}
        open={openDialog}
        onClose={() => {
          setOpen(false)
        }}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <TextField
          defaultValue={rowData.id_packman}
          autoFocus
          variant="outlined"
          margin="normal"
          name="id_packman"
          label={t('Id')}
          type="text"
          fullWidth
          onChange={handleChange}
          required
        />
        <TextField
          defaultValue={rowData.name}
          variant="outlined"
          margin="normal"
          name="name"
          label={t('name')}
          type="text"
          fullWidth
          onChange={handleChange}
          required
        />
        <TextField
          defaultValue={rowData.description}
          variant="outlined"
          margin="normal"
          name="description"
          label={t('description')}
          type="text"
          fullWidth
          onChange={handleChange}
        />
        <FormControl margin="normal" variant="outlined" fullWidth>
          <InputLabel id="select-facility-label">{t('facility')} *</InputLabel>
          <Select
            required
            labelId="select-facility-label"
            label={t('facility')}
            name="id_facility"
            onChange={handleChange}
            defaultValue={rowData.id_facility}
          >
            {facilities.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl margin="normal" variant="outlined" fullWidth>
          <InputLabel id="select-machine-label">{t('machine')} *</InputLabel>
          <Select
            required
            labelId="select-machine-label"
            label={t('machine')}
            name="id_machine"
            onChange={handleChange}
            disabled={facilitySelectedId === 0}
            defaultValue={rowData.id_machine}
          >
            {machines.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl margin="normal" variant="outlined" fullWidth>
          {`Seleted Outlet for this Packman is ${
            outletsAll.find((x) => x.id === outletsSelectedId)?.description || 'none'
          }`}
          <InputLabel id="select-outlet-label">{t('outlet')} *</InputLabel>
          <Select
            required
            labelId="select-outlet-label"
            label={t('available_outlet')}
            name="id_outlet"
            onChange={handleChange}
            defaultValue={rowData.id_outlet}
          >
            {outlets.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {modalType === 'Edit' && (
          <ActiveToggle value={rowData.active} onToggle={() => setRowData({ ...rowData, active: !rowData.active })} />
        )}
      </FormDialog>
      <ConfirmDialog
        title={t('deactivate_machine')}
        okButton="Deactivate"
        open={openDelDialog}
        loading={loadingInactive}
        onClose={() => {
          setDelOpen(false)
          setDeletable(true)
        }}
        onOk={onClickDelConfirmation}
      >
        {t(DEFAULT_COMFIRM_INACTIVE_MESSAGE)} {rowData.name}?
        {!deletable && (
          <Alert style={{ marginTop: 10 }} severity="error">
            {t(DEFAULT_DEPENDENCIES_ERROR_MESSAGE)}
          </Alert>
        )}
      </ConfirmDialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
})

export default connect(mapStateToProps, null)(Packman)
