import React, { useContext, useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import { delay, isServiceBusError, ServiceBusClient } from '@azure/service-bus'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ReactHlsPlayer from 'react-hls-player'

import Clock from '../../components/clock/clock-component'
import useStyles from './packman_dashboard.styles'
import subscriptionSlice from '../../redux/subscription/subscription.slice'
import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import SingleData from '../../components/singledata/singledata.component'
import DashboardContext from '../../context/dashboard/DashboardContext'
import { LOG_SUBSCRIPTION_ACTIONS } from '../../shared/constants/logging'
import { AB_SUBSCRIPTION_QUERY, LOCATION_QUERY } from '../../shared/constants/queries'
import RequestService from '../../services/request/request-service'
import { PACKMAN_DASHBOARD_SUBSCRIPTION, DEFAULT_ACTIVE } from '../../shared/constants/general'

const PackmanDashboard = (props) => {
  const {
    currentFacilityId,
    currentFacility,
    updateCurrentPackManInformationDispatch,
    packmanInformation,
    userEmail,
    currentMachine,
  } = props
  const history = useHistory()
  const classes = useStyles()

  const {
    packmanClient,
    setPackmanClient,
    packmanSubscription,
    setPackmanSubscription,
    closePackmanSubscription,
    packmanReceiver,
    setPackmanReceiver,
    closePackmanReceiver,
    deleteSubscription,
    cleanUpPackmanContext,
  } = useContext(DashboardContext)

  const [activeLot, setActiveLot] = useState(DEFAULT_ACTIVE)
  const [loading, setLoading] = useState(true)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [checked, setChecked] = useState(false)

  const [locations, setLocations] = useState({})
  const [data, setData] = useState({})
  const [activeVariety, setActiveVariety] = useState(DEFAULT_ACTIVE)
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const createTopicAndSubscriptionAlert = async () => {
    try {
      console.log(packmanInformation)
      await deleteSubscription(
        packmanInformation.topicName,
        packmanInformation.subscriptionName,
        PACKMAN_DASHBOARD_SUBSCRIPTION
      )
      const url = `${AB_SUBSCRIPTION_QUERY}?id_facility=${currentFacilityId}&topic_name=4000`
      const response = await RequestService.Post(url, history, null, { isBlankInstance: true })
      await closePackmanSubscription()
      await closePackmanReceiver()
      if (response !== '') {
        await updateCurrentPackManInformationDispatch({
          topicName: response.data.topic_name,
          subscriptionName: response.data.subscription_name,
        })
      } else {
        await updateCurrentPackManInformationDispatch({
          topicName: null,
          subscriptionName: null,
        })
      }
    } catch (error) {
      await closePackmanSubscription()
      await closePackmanReceiver()
      await updateCurrentPackManInformationDispatch({
        topicName: null,
        subscriptionName: null,
      })
      console.error(error)
    }
  }
  useEffect(async () => {
    if (
      packmanClient !== null &&
      packmanInformation.subscriptionName !== null &&
      packmanInformation.topicName !== null
    ) {
      if (packmanReceiver) await closePackmanReceiver()
      await createPackmanReceiver()
    }
  }, [packmanClient, packmanInformation])
  const createPackmanReceiver = async () => {
    await setPackmanReceiver(
      packmanClient.createReceiver(packmanInformation.topicName, packmanInformation.subscriptionName, {
        receiveMode: 'receiveAndDelete',
      })
    )
  }
  const clearDataDashboard = () => {
    console.log('Clear data')
  }
  useEffect(async () => {
    // Only subscribe to alert subscription if allowed role.
    if (!packmanClient) await createPackmanClient()
  }, [])
  const createPackmanClient = async () => {
    await setPackmanClient(new ServiceBusClient(process.env.REACT_APP_SERVICE_BUS_CONNECTION_STRING))
  }
  useEffect(async () => {
    if (packmanClient !== null && currentFacilityId !== 0) {
      await createTopicAndSubscriptionAlert()
    }
  }, [packmanClient, currentFacilityId])
  useEffect(async () => {
    if (packmanClient !== null && packmanReceiver !== null) {
      if (packmanSubscription) await closePackmanSubscription()
      await startAlertSubscribe()
    }
  }, [packmanClient, packmanReceiver])

  const errorHandler = async (args, type = PACKMAN_DASHBOARD_SUBSCRIPTION) => {
    if (isServiceBusError(args.error)) {
      switch (args.error.code) {
        case 'MessagingEntityDisabled':
        case 'MessagingEntityNotFound':
        case 'UnauthorizedAccess':
          console.log(`An unrecoverable error occurred. Stopping processing. ${args.error.code}`, args.error)
          if (type === PACKMAN_DASHBOARD_SUBSCRIPTION) {
            await cleanUpPackmanContext()
            await deleteSubscription(
              packmanInformation.topicName,
              packmanInformation.subscriptionName,
              PACKMAN_DASHBOARD_SUBSCRIPTION
            )
            await updateCurrentPackManInformationDispatch({
              topicName: null,
              subscriptionName: null,
            })
          }

          break
        case 'MessageLockLost':
          console.log(`Message lock lost for message`, args.error)
          break
        case 'ServiceBusy':
          // choosing an arbitrary amount of time to wait.
          await delay(1000)
          break
        default:
          console.log(args.error, args.error.code)
      }
    }
  }

  const alertMessageHandler = async (message) => {
    console.log(message)
    if (message && message.body) {
      const bodyMessage = message.body
      console.log(bodyMessage)
      setData(bodyMessage)
    }
  }

  const startAlertSubscribe = async () => {
    console.log(packmanReceiver)
    if (!packmanReceiver._isClosed) {
      await setPackmanSubscription(
        packmanReceiver.subscribe({
          processError: async (args) => errorHandler(args, PACKMAN_DASHBOARD_SUBSCRIPTION),
          processMessage: async (message) => alertMessageHandler(message),
        })
      )
      trackEvent(LOG_SUBSCRIPTION_ACTIONS.CREATED_PACKMAN_SUBSCRIPTION_SUCCESSFULLY, {
        email: userEmail,
        topicName: packmanInformation.topicName,
        subscriptionName: packmanInformation.subscriptionName,
      })
    }
    setLoading(false)
  }

  useEffect(async () => {
    const loadData = async () => {
      setLoading(true)
      try {
        setIsInitialLoad(false)
        const url = `${LOCATION_QUERY}?id_facility=${currentFacilityId}`
        const resLocation = await RequestService.Get(url, history)

        const locations = {}
        resLocation.data.forEach((item) => {
          if (!locations[item.name]) {
            locations[item.name] = item.id
          }
        })
        setLocations(locations)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    if (currentFacilityId !== 0) {
      clearDataDashboard()
      setActiveLot(DEFAULT_ACTIVE)
      setActiveVariety(DEFAULT_ACTIVE)
      if (isInitialLoad) await loadData()
    } else {
      setLoading(false)
    }
  }, [currentFacilityId])
  const handleChange = (event) => {
    setChecked(event.target.checked)
  }
  return (
    <>
      <Box pt={3}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={12}>
            <Typography component="h1" variant="h5" color="primary">
              {currentFacility.description !== '' ? currentFacility.description : currentFacility.name}
            </Typography>
            <Typography component="h1" variant="subtitle1" color="primary">
              {currentMachine.id > 0 ? (currentMachine.name ? currentMachine.name : currentMachine.description) : null}
            </Typography>
            <Clock />
          </Grid>
        </Grid>
      </Box>
      <Box pb={3} pt={3}>
        <div style={{ border: '1px solid #eee' }} />
      </Box>
      <Grid container spacing={4}>
        {/* left part */}
        <Grid item xs={12} md={6}>
          <Typography component="h1" variant="subtitle1" color="primary">
            Current Viewing: {data?.currently_viewing || ''}
          </Typography>
          <Typography className={classes.lotText} color="primary" component="h5" variant="h5">
            Lot#{data?.id_lot || 0}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SingleData title="Variety" value={data?.id_variety || 0} />
            </Grid>
            <Grid item xs={6}>
              <SingleData title="Fruit per minute" value={data?.fruit_per_minute || 0} />
            </Grid>
            <Grid item xs={6}>
              <SingleData title="Grower" value={data?.id_variety || 0} />
            </Grid>
            <Grid item xs={6}>
              <SingleData title="Picks per minute" value={data?.picks_per_minute || 0} />
            </Grid>
            <Grid item xs={6}>
              <SingleData title="Outlet #" value={data?.id_outlet || 0} />
            </Grid>
            <Grid item xs={6}>
              <SingleData title="Miss rate" value={`${data?.miss_rate || 0}%`} />
            </Grid>
          </Grid>
          <div className={classes.videoStreamContainer}>
            <div className={classes.videoStreamHeader}>
              <Typography color="primary" component="h5" variant="h5">
                Video Stream
              </Typography>
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <Typography component="h1" variant="subtitle1" color="primary">
                  Off
                </Typography>
                <Switch onChange={handleChange} />
                <Typography component="h1" variant="subtitle1" color="primary">
                  On
                </Typography>
              </div>
            </div>
            <div className={classes.videoStreamBody}>
              {checked ? (
                <ReactHlsPlayer
                  src="https://buenafrutasolutions.com/hls/live.m3u8"
                  muted
                  autoPlay
                  width="100%"
                  height="400px"
                />
              ) : (
                <span>Video Stream will be here</span>
              )}
            </div>
          </div>
        </Grid>
        {/* right part */}
        <Grid item xs={12} md={6}>
          <div className={classes.statisticContainer}>
            <div className={classes.inboundContainer}>
              <Typography color="primary" component="h5" variant="h5" gutterBottom>
                Inbound
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography component="h1" variant="subtitle1" color="primary">
                    Correct
                  </Typography>
                  <div className={classes.statisticValueContainer}>
                    <span>{data?.packman_dashboard?.in_bound_defect?.correct || 0}%</span>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <Typography component="h1" variant="subtitle1" color="primary">
                    Minor Defect
                  </Typography>
                  <div className={classes.statisticValueContainer}>
                    <span>{data?.packman_dashboard?.in_bound_defect?.defect_minor || 0}%</span>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <Typography component="h1" variant="subtitle1" color="primary">
                    Major Defect
                  </Typography>
                  <div className={classes.statisticValueContainer}>
                    <span>{data?.packman_dashboard?.in_bound_defect?.defect_major || 0}%</span>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={classes.outboundContainer}>
              <Typography color="primary" component="h5" variant="h5" gutterBottom>
                Outbound
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography component="h1" variant="subtitle1" color="primary">
                    Correct
                  </Typography>
                  <div className={classes.statisticValueContainer}>
                    <span>{data?.packman_dashboard?.out_bound_defect?.defect_major || 0}%</span>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <Typography component="h1" variant="subtitle1" color="primary">
                    Minor Defect
                  </Typography>
                  <div className={classes.statisticValueContainer}>
                    <span>{data?.packman_dashboard?.out_bound_defect?.defect_minor || 0}%</span>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <Typography component="h1" variant="subtitle1" color="primary">
                    Major Defect
                  </Typography>
                  <div className={classes.statisticValueContainer}>
                    <span>{data?.packman_dashboard?.out_bound_defect?.defect_major || 0}%</span>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

const mapStateToProps = (state) => ({
  currentLot: state.lots.currentLot,
  currentFacilityId: state.facility.currentFacilityId,
  currentFacility: state.facility.currentFacility,
  facilities: state.facility.facilities,
  moreThanOneFacility: state.facility.moreThanOneFacility,
  growerName: state.grower.growerName,
  packmanInformation: state.subscription.packmanInformation,
  userEmail: state.user.email,
  currentMachine: state.machine,
})
const mapDispatchToProps = (dispatch) => ({
  updateCurrentPackManInformationDispatch: (packmanInformation) =>
    dispatch(subscriptionSlice.actions.setCurrentPackmanInformationAction(packmanInformation)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PackmanDashboard)
