import { ServiceBusClient } from '@azure/service-bus'
import {
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Paper as MuiPaper,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import StopIcon from '@material-ui/icons/Stop'
import { DateTime } from 'luxon'
import React, { useEffect, useRef, useState } from 'react'
import ReactHlsPlayer from 'react-hls-player'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import SingleDatePicker from '../../components/single-datepicker/simple-datepicker.component'
import PdfMakerService from '../../services/pdf-maker/pdf-maker-service'
import RequestService from '../../services/request/request-service'
import {
  AB_SUBSCRIPTION_QUERY,
  UPLOAD_PACKMAN_QUERY,
  UPLOAD_PACKMAN_TRACK_VIDEO_QUERY,
} from '../../shared/constants/queries'

const ReloadModal = ({ open, onClose }) => {
  const { t } = useTranslation()
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('warning')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('please_end_tracking_before_refresh')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const TrackingReport = () => {
  const history = useHistory()
  const [isTracking, setIsTracking] = useState(false)
  const [data, setData] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedBatch, setSelectedBatch] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const playerRef = useRef(null)
  const mediaRecorderRef = useRef(null)
  const chunksRef = useRef([])
  const timeoutRef = useRef(null)
  const isStoppingRef = useRef(false)
  const isFirstVideoRef = useRef(true)
  const isLastVideoRef = useRef(false)
  const [showReloadModal, setShowReloadModal] = useState(false)
  const [serviceBusClient, setServiceBusClient] = useState(null)
  const [sender, setSender] = useState(null)
  const [isServiceBusReady, setIsServiceBusReady] = useState(false)
  const [startDate, setStartDate] = useState(DateTime.now().startOf('day').toISO({ includeOffset: false }))
  const [endDate, setEndDate] = useState(DateTime.now().endOf('day').toISO({ includeOffset: false }))
  const [latestTracking, setLatestTracking] = useState(null)
  const [isLoadingLatest, setIsLoadingLatest] = useState(true)
  const [trackingTime, setTrackingTime] = useState(0)
  const [canEndTracking, setCanEndTracking] = useState(false)
  const [isVideoReady, setIsVideoReady] = useState(false)
  const [isProgressRunning, setIsProgressRunning] = useState(true)
  const [startBtnText, setStartBtnText] = useState('start_tracking')
  const uploadPromisesRef = useRef([])
  const [queueName] = useState(process.env.REACT_APP_SERVICE_BUS_DA_SERVER_QUEUE_NAME)
  const [topicName, setTopicName] = useState('')
  const [subscriptionName, setSubscriptionName] = useState('')
  const LIVE_CAMERA_URL = process.env.REACT_APP_LIVE_CAMERA_URL
  const VIDEO_SEGMENT_DURATION = parseInt(process.env.REACT_APP_VIDEO_SEGMENT_DURATION, 10)
  const TRACKING_COUNTDOWN = VIDEO_SEGMENT_DURATION / 1000 + 5

  useEffect(() => {
    loadData()
    loadLatestTracking()
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  useEffect(() => {
    const createQueueAndTopicAndSubscription = async () => {
      try {
        const url = `${AB_SUBSCRIPTION_QUERY}?topic_name=6000`
        const response = await RequestService.Post(url, history, null, { isBlankInstance: true })
        console.log('response.data:', response.data)
        setTopicName(response.data.topic_name)
        setSubscriptionName(response.data.subscription_name)
      } catch (error) {
        console.error(error)
      }
    }

    createQueueAndTopicAndSubscription()
  }, [history])

  useEffect(() => {
    return async () => {
      if (sender) {
        await sender.close()
      }
      if (serviceBusClient) {
        await serviceBusClient.close()
      }
    }
  }, [])

  useEffect(() => {
    if (topicName && subscriptionName) {
      setupServiceBus()
    }
  }, [topicName, subscriptionName])

  useEffect(() => {
    if (isServiceBusReady) {
      checkVideoProgress()
    }
  }, [isServiceBusReady])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isTracking])

  useEffect(() => {
    const handleBeforeUnload = async (event) => {
      if (isTracking) {
        event.preventDefault()
        event.returnValue = ''

        isStoppingRef.current = true
        isLastVideoRef.current = true
        setIsTracking(false)

        if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
          mediaRecorderRef.current.stop()
        }

        await Promise.all(uploadPromisesRef.current)
        await trackVideo('end', null, null)
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isTracking])

  useEffect(() => {
    let interval
    if (isTracking) {
      interval = setInterval(() => {
        setTrackingTime((prevTime) => {
          const newTime = prevTime + 1
          if (newTime >= TRACKING_COUNTDOWN && !canEndTracking) {
            setCanEndTracking(true)
          }
          return newTime
        })
      }, 1000)
    } else {
      setTrackingTime(0)
      setCanEndTracking(false)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [isTracking])

  const loadLatestTracking = async () => {
    setIsLoadingLatest(true)
    try {
      const url = `${UPLOAD_PACKMAN_TRACK_VIDEO_QUERY}latest`
      const response = await RequestService.Get(url, history)
      setLatestTracking(response.data.result[0])
    } catch (error) {
      console.error('Error loading latest tracking:', error)
    } finally {
      setIsLoadingLatest(false)
    }
  }

  const checkVideoProgress = async () => {
    await sendServiceBusMessage('videoprogress')
  }

  const sendServiceBusMessage = async (subject, keyRun = null, body = null) => {
    if (!sender) {
      console.error('Service Bus sender not initialized')
      return
    }

    const message = {
      body,
      subject,
      applicationProperties: {},
    }

    if (keyRun !== null) {
      message.applicationProperties.key_run = keyRun
    }

    try {
      await sender.sendMessages(message)
      console.log('Message sent successfully', message)
    } catch (error) {
      console.error('Error sending message:', error)
    }
  }

  const handleKeyDown = (event) => {
    if (isTracking && ((event.ctrlKey && event.key === 'r') || event.key === 'F5')) {
      event.preventDefault()
      setShowReloadModal(true)
    }
  }

  const setupServiceBus = async () => {
    try {
      const client = new ServiceBusClient(process.env.REACT_APP_SERVICE_BUS_CONNECTION_STRING)
      const sender = client.createSender(queueName)
      const receiver = client.createReceiver(topicName, subscriptionName)

      setServiceBusClient(client)
      setSender(sender)

      receiver.subscribe({
        processMessage: async (message) => {
          console.log('Received message:', message)
          if (message.subject === 'videoprogress') {
            const { run_flag } = message.body
            setIsProgressRunning(run_flag)
            if (run_flag) {
              setStartBtnText('in_progress')
            } else {
              setStartBtnText('start_tracking')
            }
            await receiver.completeMessage(message)
          } else if (message.subject === 'tracking_result') {
            const trackingResult = message.body
            setData((prevData) => ({
              ...prevData,
              ...trackingResult,
            }))
            setLatestTracking(trackingResult)
            setIsProgressRunning(false)
            setStartBtnText('start_tracking')
            await receiver.completeMessage(message)
          }
        },
        processError: async (err) => {
          console.error('Error processing message:', err)
        },
      })
      setIsServiceBusReady(true)
    } catch (error) {
      console.error('Error setting up Service Bus:', error)
    }
  }

  const loadData = async () => {
    setIsLoading(true)
    try {
      let dateQuery = ''
      if (startDate) {
        dateQuery += `&start_date=${startDate}`
      }
      if (endDate) {
        dateQuery += `&end_date=${endDate}`
      }

      const url = `${UPLOAD_PACKMAN_TRACK_VIDEO_QUERY}?${dateQuery}`
      const response = await RequestService.Get(url, history)
      setData(response.data.result)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    if (hours > 0) {
      return `${hours}h ${minutes}m ${remainingSeconds}s`
    }
    if (minutes > 0) {
      return `${minutes}m ${remainingSeconds}s`
    }
    return `${remainingSeconds}s`
  }

  const startRecording = () => {
    if (playerRef.current && isVideoReady) {
      isStoppingRef.current = false
      setIsTracking(true)
      isFirstVideoRef.current = true
      isLastVideoRef.current = false
      setTrackingTime(0)
      setCanEndTracking(false)
      uploadPromisesRef.current = []
      recordSegment()
    }
  }

  const recordSegment = async () => {
    if (isStoppingRef.current && !isLastVideoRef.current) return

    const stream = playerRef.current.captureStream()
    if (!(stream instanceof MediaStream)) {
      console.error('Failed to get MediaStream from video element.')
      return
    }

    const recorder = new MediaRecorder(stream, {
      mimeType: 'video/webm;codecs=vp8,opus',
    })
    mediaRecorderRef.current = recorder
    chunksRef.current = []

    const segmentStartTime = Date.now()

    recorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunksRef.current.push(event.data)
      }
    }

    recorder.onstart = () => {
      console.log('Recorder started')
    }

    recorder.onstop = async () => {
      const endTime = Date.now()
      const duration = (endTime - segmentStartTime) / 1000 // Duration in seconds
      console.log(`Segment duration: ${duration} seconds`)

      const blob = new Blob(chunksRef.current, { type: 'video/webm' })

      const isFirst = isFirstVideoRef.current
      const isLast = isLastVideoRef.current

      const uploadPromise = uploadAndTrackVideo(blob, isFirst, isLast, duration)
      uploadPromisesRef.current.push(uploadPromise)

      if (!isStoppingRef.current) {
        recordSegment()
      }

      if (isFirstVideoRef.current) {
        isFirstVideoRef.current = false
      }
    }

    recorder.start()

    timeoutRef.current = setTimeout(() => {
      if (recorder.state === 'recording') {
        recorder.stop()
      }
    }, VIDEO_SEGMENT_DURATION)
  }

  const uploadAndTrackVideo = async (blob, isFirst, isLast, duration) => {
    const filename = `video_${Date.now()}.webm`

    try {
      let videoUrl = null
      if (!isLast || (isLast && duration >= 10)) {
        const formData = new FormData()
        formData.append('batchVideo', blob, filename)

        const response = await RequestService.Post(`${UPLOAD_PACKMAN_QUERY}uploadvideos`, history, formData)
        console.log('Upload response:', response.data)
        videoUrl = response.data

        if (isFirst) {
          await trackVideo('start', videoUrl, duration)
        } else if (!isLast) {
          await trackVideo(null, videoUrl, duration)
        }
      }
    } catch (error) {
      console.error('Error processing video:', error)
    }
  }

  const stopRecording = async () => {
    console.log('Stopping recording')
    isStoppingRef.current = true
    isLastVideoRef.current = true
    setIsTracking(false)
    setTrackingTime(0)
    setCanEndTracking(false)
    setStartBtnText('in_progress')
    setIsProgressRunning(true)

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }

    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop()
    }

    await Promise.all(uploadPromisesRef.current)

    await trackVideo('end', null, null)
  }

  const trackVideo = async (keyRun, videoUrl = null, duration = null) => {
    const subject = 'tracking'
    const body = {}
    if (videoUrl) {
      body.video_url = videoUrl
    }
    if (duration) {
      body.duration = duration
    }

    await sendServiceBusMessage(subject, keyRun, body)
  }

  const exportToPDF = (id) => {
    const selectedData = data.find((item) => item.batch_id === id)
    const filename = `TrackingVideoReport_${selectedData.start_date.replace(/[:]/g, '-')}.pdf`
    const reportType = 'TrackingVideoReport'
    PdfMakerService.generateReport(filename, reportType, {
      batch_id: selectedData.batch_id,
      start_date: selectedData.start_date,
      record_time: selectedData.record_time,
      classify_count: selectedData.classify_count,
      id_stats: selectedData.id_stats,
      measure: selectedData.measure,
      total: selectedData.total,
    })
  }

  const handleViewDetail = (batch) => {
    setSelectedBatch(batch)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setSelectedBatch(null)
  }

  const handleVideoReady = () => {
    setIsVideoReady(true)
  }

  const renderDetailsTable = () => {
    if (!selectedBatch) return null

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <div>
            <Typography>Start Date: {new Date(selectedBatch.start_date).toLocaleString()}</Typography>
            <Typography>Duration: {selectedBatch.record_time} seconds</Typography>
            <Typography>Measure: {selectedBatch.measure || 'N/A'}</Typography>
            <Typography>Total: {selectedBatch.total || 'N/A'}</Typography>
          </div>
          <IconButton onClick={() => exportToPDF(selectedBatch.batch_id)}>
            <GetAppRoundedIcon />
          </IconButton>
        </div>

        <TableContainer component={MuiPaper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  CLASSIFY COUNT
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <TableCell align="center">Size</TableCell>
                <TableCell align="center">Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedBatch.classify_count && Object.keys(selectedBatch.classify_count).length > 0 ? (
                Object.entries(selectedBatch.classify_count).map(([size, count]) => (
                  <TableRow key={size}>
                    <TableCell align="center">{size}</TableCell>
                    <TableCell align="center">{count}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    No classification data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <TableContainer component={MuiPaper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={3} align="center">
                  STATS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">BboxArea</TableCell>
                <TableCell align="center">Measurement</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedBatch.id_stats && Object.keys(selectedBatch.id_stats).length > 0 ? (
                Object.entries(selectedBatch.id_stats).map(([id, stats]) => (
                  <TableRow key={id}>
                    <TableCell align="center">{id}</TableCell>
                    <TableCell align="center">{Array.isArray(stats) ? stats[0] : 'N/A'}</TableCell>
                    <TableCell align="center">{Array.isArray(stats) ? stats[1] : 'N/A'}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    No stats data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }

  return (
    <>
      <ReloadModal open={showReloadModal} onClose={() => setShowReloadModal(false)} />
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>Batch Details</DialogTitle>
        <DialogContent>{renderDetailsTable()}</DialogContent>
      </Dialog>
      <Container>
        <Box pt={5} pb={3}>
          <Typography component="h1" variant="h5" color="primary">
            {t('tracking_report')}
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} style={{ padding: '16px', height: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  alignItems: 'center',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={startRecording}
                  disabled={isTracking || !isVideoReady || isProgressRunning}
                  style={{ marginBottom: '16px', width: '80%' }}
                  startIcon={<PlayArrowIcon />}
                >
                  {t(`${startBtnText}`)}
                </Button>
                {!isVideoReady && (
                  <Box display="flex" flexDirection="column" alignItems="center" mt={2} mb={2}>
                    <CircularProgress size={24} style={{ marginBottom: '8px' }} />
                    <Typography variant="body2">{t('waiting_for_video')}</Typography>
                  </Box>
                )}
                {isTracking && (
                  <>
                    <Box style={{ width: '80%', marginBottom: '16px', display: 'flex', justifyContent: 'center' }}>
                      <CircularProgress />
                    </Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={stopRecording}
                      style={{
                        width: '80%',
                        opacity: canEndTracking ? 1 : 0.5,
                        transition: 'opacity 0.3s ease-in-out',
                      }}
                      startIcon={<StopIcon />}
                      disabled={!canEndTracking}
                    >
                      {canEndTracking
                        ? t('stop_tracking')
                        : `${t('stop_tracking')} (${TRACKING_COUNTDOWN - trackingTime}s)`}
                    </Button>
                  </>
                )}
                <Box mt={2} width="100%">
                  <Typography variant="h6" gutterBottom>
                    {t('latest_tracking')}
                  </Typography>
                  {isLoadingLatest ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                      <CircularProgress />
                    </Box>
                  ) : latestTracking ? (
                    <div style={{ width: '250px' }}>
                      <Paper style={{ padding: '20px', textAlign: 'left', position: 'relative' }}>
                        <Typography variant="h6">
                          {t('date')}: {new Date(latestTracking.start_date).toLocaleDateString()}
                        </Typography>
                        <Typography variant="subtitle1">
                          {t('time')}: {new Date(latestTracking.start_date).toLocaleTimeString()}
                        </Typography>
                        <Typography variant="subtitle1">
                          {t('duration')}: {formatDuration(latestTracking.record_time)}
                        </Typography>
                        <Chip
                          icon={<Icon>visibility</Icon>}
                          label={t('view_details')}
                          variant="outlined"
                          onClick={() => handleViewDetail(latestTracking)}
                        />
                      </Paper>
                    </div>
                  ) : (
                    <Typography variant="body2">{t('no_data')}</Typography>
                  )}
                </Box>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12} md={8}>
            <Paper elevation={3} style={{ padding: '16px' }}>
              <ReactHlsPlayer
                src={LIVE_CAMERA_URL}
                muted
                autoPlay
                width="100%"
                height="auto"
                playerRef={playerRef}
                onLoadedData={handleVideoReady}
              />
            </Paper>
          </Grid>
        </Grid>

        <Box style={{ marginTop: '20px' }}>
          <Paper elevation={3}>
            <Typography variant="h6" style={{ padding: '16px 16px 0 16px' }}>
              Tracking log entries
            </Typography>
            <div style={{ padding: '0 16px' }}>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs={12} md={3}>
                  <SingleDatePicker
                    maxDate={endDate}
                    style={{ width: '100%' }}
                    label={t('start_date')}
                    name="startDate"
                    value={startDate}
                    disableFuture
                    onChange={(date) => {
                      const startDate = DateTime.fromJSDate(date)
                      setStartDate(startDate.startOf('day').toISO({ includeOffset: false }))
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <SingleDatePicker
                    minDate={startDate}
                    style={{ width: '100%' }}
                    label={t('end_date')}
                    name="endDate"
                    value={endDate}
                    disableFuture
                    onChange={(date) => {
                      const endDate = DateTime.fromJSDate(date)
                      setEndDate(endDate.endOf('day').toISO({ includeOffset: false }))
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      loadData()
                    }}
                  >
                    {t('filter')}
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                display: 'flex',
                gap: '16px',
                padding: '16px',
                overflowX: 'auto',
                minHeight: '200px',
                width: '100%',
              }}
            >
              {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
                  <CircularProgress />
                </div>
              ) : data && data.length > 0 ? (
                data
                  .sort((a, b) => new Date(b.start_date) - new Date(a.start_date))
                  .map((batch) => (
                    <div key={batch.batch_id} style={{ flex: '0 0 auto', width: '250px' }}>
                      <Paper style={{ padding: '20px', textAlign: 'left', position: 'relative' }}>
                        <Typography variant="h6">
                          {t('date')}: {new Date(batch.start_date).toLocaleDateString()}
                        </Typography>
                        <Typography variant="subtitle1">
                          {t('time')}: {new Date(batch.start_date).toLocaleTimeString()}
                        </Typography>
                        <Typography variant="subtitle1">
                          {t('duration')}: {formatDuration(batch.record_time)}
                        </Typography>
                        <Chip
                          icon={<Icon>visibility</Icon>}
                          label={t('view_details')}
                          variant="outlined"
                          onClick={() => handleViewDetail(batch)}
                        />
                      </Paper>
                    </div>
                  ))
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Typography variant="h6">{t('no_data')}</Typography>
                </div>
              )}
            </div>
          </Paper>
        </Box>
      </Container>
    </>
  )
}

export default TrackingReport
