import React, { useEffect, useState, useContext } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import { Line } from 'react-chartjs-2'

import { DataGrid } from '@material-ui/data-grid'
import Grid from '@material-ui/core/Grid'

import { ROW_PER_PAGE_OPTIONS, DEFAULT_TABLE_CONFIGURATION } from '../../../shared/constants/table'

import useVarietyQuery from '../../../shared/hooks/useVarietyQuery'
import PrintButton from '../../../components/print-button/print-button.component'
import ReportInputsFillter from '../../../components/report-inputs-filter/report-inputs-filter.component'
import SimpleBackdrop from '../../../components/simple-backdrop/simple-backdrop.component'
import AppInsightsTrackingContext from '../../../context/app-insights-tracking/AppInsightsTrackingContext'
import RequestService from '../../../services/request/request-service'

import { BIN_SUMMARY_QUERY } from '../../../shared/constants/queries'

const BinsSummary = ({ selectedLot, selectedVariety, currentFacility, selectedSeason, userEmail }) => {
  const history = useHistory()
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [showReport, setShowReport] = useState(false)
  const [showBackdrop, setShowBackdrop] = useState(false)
  const [generalData, setGeneralData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [columns, setColumns] = useState([])
  const [rows, setRows] = useState([])
  const { trackEvent } = useContext(AppInsightsTrackingContext)
  const [roleSeries, setRoleSeries] = useState([])
  const [categoriesState, setCategoriesState] = useState([])
  const varietyQuery = useVarietyQuery()
  const [filteredVarieties, setFilteredVarieties] = useState([])

  const infoColumns = [
    { field: 'date', headerName: 'Created Date', minWidth: 140 },
    { field: 'total_bin_count', headerName: 'Total Bins Count', minWidth: 140 },
  ]

  useEffect(() => {
    if (selectedVariety === 0 || selectedLot === 0) {
      setShowReport(false)
    }
  }, [selectedLot, selectedVariety])

  const onPageSizeChange = (event) => {
    setTableConf({ page: 1, pageSize: event })
  }

  const parseResponseToHeaders = (data) => {
    return [...data].map((veriety) => {
      return {
        field: veriety.id,
        headerName: veriety.description,
        minWidth: 160,
      }
    })
  }

  useEffect(() => {
    if (varietyQuery.data && generalData.length > 0) {
      const filtered = varietyQuery.data.filter((variety) =>
        generalData.some((entry) => entry.varieties.some((v) => v.id_variety === variety.id))
      )
      setFilteredVarieties(filtered)
    }
  }, [varietyQuery.data, generalData])

  useEffect(() => {
    if (filteredVarieties.length > 0) {
      // table
      try {
        const parsedVariety = parseResponseToHeaders(filteredVarieties)
        if (parsedVariety.length === 1) {
          parsedVariety[0].minWidth = 600
          infoColumns[0].minWidth = 600
          infoColumns[1].minWidth = 600
        }
        console.log(parsedVariety)
        setColumns([...infoColumns, ...parsedVariety])
      } catch (error) {
        console.log(error)
      }

      const result = generalData?.map((entry, i) => {
        const transformedEntry = {
          id: i,
          date: entry.date ? DateTime.fromISO(entry.date).toUTC().toFormat('LL-dd-yy') : '',
          total_bin_count: entry.total_bin_count,
        }
        entry.varieties.forEach((variety) => {
          transformedEntry[variety.id_variety] = variety.bin_count
        })
        return transformedEntry
      })

      setRows(result)

      // chart
      const transformedData = []
      const date = []
      filteredVarieties.forEach((veriety, i) => {
        transformedData.push({
          label: veriety.description,
          data: [],
        })

        generalData?.forEach((entry) => {
          const variety = entry.varieties.find((variety) => variety.id_variety === veriety.id)
          if (variety) {
            transformedData[i].data.push(variety.bin_count)
          } else {
            transformedData[i].data.push(0)
          }
        })
      })
      generalData?.forEach((entry) => {
        const entryDate = DateTime.fromISO(entry.date, { zone: 'utc' })
        const formattedDate = entryDate.toFormat('MMM-dd')
        date.push(formattedDate)
      })

      setRoleSeries(transformedData)
      setCategoriesState(date)
    }
  }, [filteredVarieties])

  const loadBinReport = async () => {
    trackEvent('FILTER_CLICK', { email: userEmail })
    try {
      setShowBackdrop(true)
      // eslint-disable-next-line max-len
      const url = `${BIN_SUMMARY_QUERY}?id_facility=${currentFacility.id}&id_lot=${selectedLot}&id_variety=${selectedVariety}&season=${selectedSeason}`
      const response = await RequestService.Get(url, history)
      setGeneralData(response.data)
      setShowBackdrop(false)
    } catch (error) {
      console.log(error)
    }
  }

  // Random color generator function
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  const chartData = {
    labels: categoriesState,
    datasets: roleSeries.map((series) => ({
      label: series.label,
      data: series.data,
      fill: false,
      borderColor: getRandomColor(),
      tension: 0.1,
    })),
  }

  const options = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
    title: {
      display: true,
      text: 'Bin Summary Report',
    },
  }

  return (
    <Grid container>
      {!showReport ? null : <PrintButton />}
      <ReportInputsFillter loadData={loadBinReport} isLoading={showBackdrop} />
      {generalData.length > 0 ? (
        <>
          <Grid xs={12} item>
            <div
              style={{
                height: '40vh',
              }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
                pageSize={tableConf.pageSize}
                disableSelectionOnClick
                loading={isLoading}
                onPageSizeChange={onPageSizeChange}
              />
            </div>
          </Grid>
          <Grid xs={12} item>
            <Line options={options} data={chartData} width={100} height={50} />
          </Grid>
        </>
      ) : (
        <div />
      )}

      <SimpleBackdrop open={showBackdrop} />
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  selectedLot: state.reports.selectedLot,
  selectedLotName: state.reports.selectedLotName,
  selectedVariety: state.reports.selectedVariety,
  selectedVarietyName: state.reports.selectedVarietyName,
  selectedGrowerName: state.reports.selectedGrowerName,
  selectedStartDate: state.reports.selectedStartDate,
  selectedEndDate: state.reports.selectedEndDate,
  selectedSeason: state.reports.selectedSeason,
  currentFacility: state.facility.currentFacility,
  selectedMachine: state.reports.selectedMachine,
  userEmail: state.user.email,
})

export default connect(mapStateToProps)(BinsSummary)
