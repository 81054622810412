import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import FormDialog from '../../components/form-dialog/form-dialog.component'
import ConfirmDialog from '../../components/confirm-dialog/confirm-dialog.component'
import ActionButtons from '../../components/action-buttons/action-buttons.component'
import MultipleSelect from '../../components/multi-select/multi-select'
import TableContainer from '../../components/table-container/table-container'
import ActiveToggle from '../../components/active-toggle/active-toggle.component'
import ManyToManyInput from '../../components/many-many-input/many-many-input.component'

import { buildPayload, getManyToManyData } from '../../shared/utils/utils'
import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import RequestService from '../../services/request/request-service'

import {
  ACTIONS_COLUMN,
  BASE_TABLE_COLUMNS,
  DEFAULT_ROW_DATA,
  DEFAULT_TABLE_CONFIGURATION,
} from '../../shared/constants/table'
import { POST_METHOD, PUT_METHOD } from '../../shared/constants/requests'

import {
  CATEGORIES_DEFECTS_QUERY,
  DEFECT_QUERY,
  VARIETIES_DEFECTS_QUERY,
  LOCATIONS_DEFECTS_QUERY,
} from '../../shared/constants/queries'
import { DEFAULT_COMFIRM_INACTIVE_MESSAGE } from '../../shared/constants/messages'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'
import useHttpInactive from '../../shared/hooks/useHttpInactive'
import useDefectQuery from '../../shared/hooks/useDefectQuery'
import useVarietyQuery from '../../shared/hooks/useVarietyQuery'
import useCategoryQuery from '../../shared/hooks/useCategoryQuery'
import useLocationQuery from '../../shared/hooks/useLocationQuery'
import { SUPER_ADMIN_ROLE } from '../../shared/constants/roles'

const Defect = ({ userEmail, role }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const [openDialog, setOpen] = useState(false)
  const [openDelDialog, setDelOpen] = useState(false)
  const [openVarieties, setOpenVarieties] = useState(false)
  const [openCategory, setOpenCategory] = useState(false)
  const [openLocation, setOpenLocation] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalType, setModalType] = useState('Add new')
  const [rowData, setRowData] = useState(DEFAULT_ROW_DATA)
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [showActive, setShowActive] = useState(true)
  const [selectedVarieties, setSelectedVarieties] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedLocations, setSelectedLocations] = useState([])

  const defectQuery = useDefectQuery({ showActive })
  const varietyQuery = useVarietyQuery()
  const categoryQuery = useCategoryQuery()
  const locationQuery = useLocationQuery()
  const isLoadingQuery = defectQuery.isFetching || varietyQuery.isFetching || categoryQuery.isFetching

  // columns definition for table
  const translatedColumns = BASE_TABLE_COLUMNS.map((column) => ({
    ...column,
    headerName: t(column.headerName),
  }))

  const columns = [
    ...translatedColumns,
    {
      field: 'varieties',
      headerName: t('varieties'),
      flex: 1,
      minWidth: 135,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id })
            setOpenVarieties(true)
          }}
          style={{ textTransform: 'none' }}
        >
          {t('see_varieties')}
        </Button>
      ),
    },
    {
      field: 'categories',
      headerName: t('categories'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id })
            setOpenCategory(true)
          }}
          style={{ textTransform: 'none' }}
        >
          {t('see_categories')}
        </Button>
      ),
    },
    {
      field: 'locations',
      headerName: 'Locations',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id })
            setOpenLocation(true)
          }}
          style={{ textTransform: 'none' }}
        >
          See Locations
        </Button>
      ),
    },
    {
      ...ACTIONS_COLUMN,
      headerName: t(ACTIONS_COLUMN.headerName),
      hide: role !== SUPER_ADMIN_ROLE,
      renderCell: (params) => ActionButtons(params.row, handleClickOpen, handleClickDelOpen),
    },
  ]

  useEffect(() => {
    trackEvent(LOG_PAGE_LOADS.LOAD_DEFECT_PAGE, { email: userEmail })
  }, [])

  const onError = (errorMessage) => {
    console.error(errorMessage)
    setLoading(false)
  }

  // insert and edit
  const insertEditData = async (method, data) => {
    setLoading(true)
    setOpen(false)

    let url = `${DEFECT_QUERY}`
    let response
    if (method === POST_METHOD) response = await RequestService.Post(url, history, data)
    if (method === PUT_METHOD) response = await RequestService.Put(url, history, data)

    if (response?.hasError) {
      onError(response.errorMessage)
      return
    }

    let id
    if (method === PUT_METHOD) id = response.data.id
    if (method === POST_METHOD) id = response.data.id

    const defectsVarietiesPayload = buildPayload(id, selectedVarieties, null, true)
    url = `${VARIETIES_DEFECTS_QUERY}?filterByFirst=false`
    response = await RequestService.Put(url, history, defectsVarietiesPayload, { isBlankInstance: true })
    if (response?.hasError) {
      onError(response.errorMessage)
      return
    }

    const defectsCategoriesPayload = buildPayload(
      id,
      selectedCategories,
      null,
      true,
      'id_category_type',
      'id_category_type'
    )
    url = `${CATEGORIES_DEFECTS_QUERY}?filterByFirst=false`
    response = await RequestService.Put(url, history, defectsCategoriesPayload, { isBlankInstance: true })
    if (response?.hasError) {
      onError(response.errorMessage)
      return
    }

    const defectsLocationsPayload = buildPayload(id, selectedLocations, null, true)
    url = `${LOCATIONS_DEFECTS_QUERY}?filterByFirst=false`
    response = await RequestService.Put(url, history, defectsLocationsPayload, { isBlankInstance: true })
    if (response?.hasError) {
      onError(response.errorMessage)
      return
    }

    setSelectedCategories([])
    setSelectedVarieties([])
    setSelectedLocations([])
    setLoading(false)
    defectQuery.refetch()
  }

  // Form Dialog handler
  const handleClickOpen = (type, params) => async () => {
    setLoading(true)
    if (type === 'Edit') {
      trackEvent('EDIT_CLICK', { email: userEmail })
      const defectsVarieties = await getManyToManyData(
        `${VARIETIES_DEFECTS_QUERY}?id=${params.id}&filterByFirst=false`,
        history,
        varietyQuery.data,
        false
      )
      const defectsCategories = await getManyToManyData(
        `${CATEGORIES_DEFECTS_QUERY}?id=${params.id}&filterByFirst=false`,
        history,
        categoryQuery.data
      )
      const defectsLocations = await getManyToManyData(
        `${LOCATIONS_DEFECTS_QUERY}?id=${params.id}&filterByFirst=false`,
        history,
        locationQuery.data,
        false
      )

      setSelectedVarieties(defectsVarieties)
      setSelectedCategories(defectsCategories)
      setSelectedLocations(defectsLocations)

      setRowData({
        id: params.id,
        name: params.name,
        description: params.description,
        active: params.active,
      })
    } else {
      trackEvent('ADD_CLICK', { email: userEmail })
      setRowData(DEFAULT_ROW_DATA)
      setSelectedVarieties([])
      setSelectedCategories([])
      setSelectedLocations([])
    }
    setModalType(type)
    setOpen(true)
    setLoading(false)
  }

  const handleSubmit = (event) => {
    trackEvent('SUBMIT_CLICK', { email: userEmail })
    event.preventDefault()
    const data = { ...rowData }
    const method = modalType === 'Edit' ? PUT_METHOD : POST_METHOD
    insertEditData(method, data)
  }
  const { executeInactive, loadingInactive } = useHttpInactive(DEFECT_QUERY)
  // Confirm Dialog handlers
  const handleClickDelOpen = (params) => () => {
    trackEvent('DELETE_CLICK', { email: userEmail })
    setRowData({
      id: params.id,
      name: params.name,
      description: params.description,
      active: !params.active,
    })
    setDelOpen(true)
  }

  const onClickDelConfirmation = async () => {
    const { id } = rowData
    const { success } = await executeInactive(id)
    if (success) {
      setDelOpen(false)
      defectQuery.refetch()
    }
  }

  // Form on change
  const handleChange = (event) => {
    const { name, value } = event.target
    setRowData({
      ...rowData,
      [name]: value,
    })
  }

  return (
    <div>
      <Box pt={5} pb={3}>
        <Typography component="h1" variant="h5" color="primary">
          {t('defects')}
        </Typography>
      </Box>
      <TableContainer
        haveSearchInput="true"
        handleClickOpen={() => handleClickOpen('Add new', null)}
        columns={columns}
        rows={defectQuery.data}
        tableConfiguration={tableConf}
        setTableConf={setTableConf}
        onToggleClick={() => setShowActive(!showActive)}
        showActive={showActive}
        loading={isLoadingQuery}
      />
      <MultipleSelect
        open={openVarieties}
        onClose={() => {
          setOpenVarieties(false)
        }}
        title={t('varieties')}
        getUrl="variety"
        getRelationUrl="varietiesdefects"
        useLast
        mainId={rowData.id}
        lefttoright
        isAdminShow
      />
      <MultipleSelect
        open={openCategory}
        onClose={() => {
          setOpenCategory(false)
        }}
        title={t('categories')}
        getUrl="category"
        getRelationUrl="categoriesdefects"
        useLast
        getAdditionalData="id_category_type"
        deleteRelationKey="id_category_type"
        mainId={rowData.id}
        lefttoright
        isAdminShow
      />
      <MultipleSelect
        open={openLocation}
        onClose={() => {
          setOpenLocation(false)
        }}
        title="Locations"
        getUrl="location"
        getRelationUrl="locationsdefects"
        useLast
        mainId={rowData.id}
        lefttoright
        isAdminShow
      />
      <FormDialog
        title={`${modalType} defect`}
        open={openDialog}
        onClose={() => {
          setOpen(false)
        }}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <TextField
          defaultValue={rowData.name}
          autoFocus
          variant="outlined"
          margin="normal"
          name="name"
          label={t('name')}
          type="text"
          fullWidth
          onChange={handleChange}
          required
        />
        <TextField
          defaultValue={rowData.description}
          variant="outlined"
          margin="normal"
          name="description"
          label={t('description')}
          type="text"
          fullWidth
          onChange={handleChange}
        />
        <ManyToManyInput
          inputLabel={t('varieties')}
          name="id_variety"
          selectedValues={selectedVarieties}
          allValues={varietyQuery.data}
          setSelectedValues={setSelectedVarieties}
        />
        <ManyToManyInput
          inputLabel={t('categories')}
          name="id_category"
          selectedValues={selectedCategories}
          allValues={categoryQuery.data}
          setSelectedValues={setSelectedCategories}
        />
        <ManyToManyInput
          inputLabel="Locations"
          name="id_location"
          selectedValues={selectedLocations}
          allValues={locationQuery.data}
          setSelectedValues={setSelectedLocations}
        />
        {modalType === 'Edit' && (
          <ActiveToggle value={rowData.active} onToggle={() => setRowData({ ...rowData, active: !rowData.active })} />
        )}
      </FormDialog>
      <ConfirmDialog
        title={t('deactivate_defect')}
        okButton="Deactivate"
        open={openDelDialog}
        loading={loadingInactive}
        onClose={() => {
          setDelOpen(false)
        }}
        onOk={onClickDelConfirmation}
      >
        {t(DEFAULT_COMFIRM_INACTIVE_MESSAGE)} {rowData.name}?
      </ConfirmDialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
  role: state.user.role,
})

export default connect(mapStateToProps, null)(Defect)
