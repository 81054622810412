import { makeStyles } from '@material-ui/core/styles'

const imageCaptureStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    height: '100vh',
    padding: '20px',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    width: '17%',
    marginRight: '20px',
  },
  imageArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dialog_bg: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  dialog: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    minWidth: '300px',
  },
  videoStreamBody: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '8px',
    marginTop: '20px',
    width: '100%',
    border: '2px solid black',
  },
  imageScrollArea: {
    display: 'flex',
    overflowX: 'auto',
    width: '80vw',
    paddingBottom: '100px',
    maxHeight: '400px',
    marginTop: '20px',
  },
  selectedImage: {
    border: '3px solid #2A3858',
    borderRadius: '8px',
  },
}))

export default imageCaptureStyles
