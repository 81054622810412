import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import * as XLSXStyle from 'xlsx-js-style'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import { ImageList, ImageListItem } from '@material-ui/core'
import PrintButton from '../../../components/print-button/print-button.component'
import ReportInputs from '../../../components/report-inputs/report-inputs.component'
import SimpleBackdrop from '../../../components/simple-backdrop/simple-backdrop.component'
import AppInsightsTrackingContext from '../../../context/app-insights-tracking/AppInsightsTrackingContext'

import PdfMakerService from '../../../services/pdf-maker/pdf-maker-service'
import RequestService from '../../../services/request/request-service'

import makeStyles from '../reports.styles'
import { numberToPercentage } from '../../../shared/utils/utils'

import { QUALITY_SUMMARY_QUERY } from '../../../shared/constants/queries'

const Quality = ({
  selectedLot,
  selectedLotName,
  selectedVariety,
  selectedVarietyName,
  selectedGrowerName,
  currentFacility,
  selectedStartDate,
  selectedEndDate,
  selectedMachine,
  desTemplates,
  userEmail,
}) => {
  const classes = makeStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const [showReport, setShowReport] = useState(false)
  const [showBackdrop, setShowBackdrop] = useState(false)
  const [reportDate] = useState(DateTime.now().toFormat('LL-dd-yy'))

  const [generalData, setGeneralData] = useState({})
  const [upstreamSummaryData, setUpstreamSummaryData] = useState({})
  const [inlineSummaryData, setInlineSummaryData] = useState({})
  const [finishedBoxSummaryData, setFinishedBoxSummaryData] = useState({})
  const [cullsSummaryData, setCullsSummaryData] = useState({})
  const [peddlerSummaryData, setPeddlerSummaryData] = useState({})
  const [finishedboxAttachmentSamples, setFinishedboxAttachmentSamples] = useState([])
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  useEffect(() => {
    if (selectedVariety === 0 || selectedLot === 0) {
      setShowReport(false)
    }
  }, [selectedLot, selectedVariety])

  useEffect(() => {
    return () => {
      // Clear the array when the component unmounts
      setGeneralData({})
      setUpstreamSummaryData({})
      setInlineSummaryData({})
      setFinishedBoxSummaryData({})
      setCullsSummaryData({})
      setFinishedboxAttachmentSamples([])
    }
  }, [])

  const loadQualityReport = async () => {
    try {
      setShowBackdrop(true)
      // eslint-disable-next-line max-len
      const url = `${QUALITY_SUMMARY_QUERY}?id_lot=${selectedLot}&id_variety=${selectedVariety}&id_facility=${currentFacility.id}&start_date=${selectedStartDate}Z&end_date=${selectedEndDate}Z&id_machine=${selectedMachine.id}`
      const response = await RequestService.Get(url, history)
      const {
        pick_date,
        receiving_grade,
        receiving_colors,
        estimated_packout,
        average_firmness,
        brix,
        stemcondition,
        upstream_grade,
        upstream_defects,
        upstream_sample_count,
        upstream_sample_total,
        inline_grade,
        inline_defects,
        inline_sample_total,
        inline_sample_count,
        finishedbox_grade,
        finishedbox_defects,
        finishedbox_sample_total,
        finishedbox_sample_count,
        cull_sample_total,
        peddler_sample_total,
        cull_grade,
        peddler_grade,
        finishedbox_attachment_samples,
      } = response.data
      const pickDateFormatted =
        pick_date !== '0001-01-01T00:00:00' ? DateTime.fromISO(pick_date).toUTC().toFormat('LL-dd-yy') : '-'
      let generalMinorDefect = 0
      let generalMajorDefect = 0

      receiving_grade.forEach((defect) => {
        const { description, percentage } = defect
        const number = ((percentage ?? 0) * 100).toFixed(2)
        if (description === 'Major') generalMajorDefect = number
        if (description === 'Minor') generalMinorDefect = number
      })
      const colorDataArray = []
      receiving_colors.forEach((colorData) => {
        const { percentage, description } = colorData
        colorDataArray.push({
          name: description,
          value: numberToPercentage(((percentage ?? 0) * 100).toFixed(2)),
        })
      })

      let upstreamMinorDefect = 0
      let upstreamMajorDefect = 0
      upstream_grade.forEach((grade) => {
        const { percentage, description } = grade
        const number = (percentage ?? 0) * 100
        if (description === 'Major') upstreamMajorDefect = number
        if (description === 'Minor') upstreamMinorDefect = number
      })

      const upstreamSummaryMinorDefects = []
      const upstreamSummaryMajorDefects = []
      upstream_defects.forEach((defect) => {
        const { name, description, percentage } = defect
        const defectObject = { name, percentage: ((percentage ?? 0) * 100).toFixed(2) }
        if (description === 'Major') upstreamSummaryMajorDefects.push(defectObject)
        if (description === 'Minor') upstreamSummaryMinorDefects.push(defectObject)
      })

      let inlineMinorDefect = 0
      let inlineMajorDefect = 0
      inline_grade.forEach((grade) => {
        const { percentage, description } = grade
        const number = (percentage ?? 0) * 100
        if (description === 'Major') inlineMajorDefect = number
        if (description === 'Minor') inlineMinorDefect = number
      })

      const inlineSummaryMinorDefects = []
      const inlineSummaryMajorDefects = []
      inline_defects.forEach((defect) => {
        const { name, description, percentage } = defect
        const defectObject = { name, percentage: ((percentage ?? 0) * 100).toFixed(2) }
        if (description === 'Major') inlineSummaryMajorDefects.push(defectObject)
        if (description === 'Minor') inlineSummaryMinorDefects.push(defectObject)
      })

      let finishedBoxMinorDefect = 0
      let finishedBoxMajorDefect = 0
      finishedbox_grade.forEach((grade) => {
        const { percentage, description } = grade
        const number = percentage * 100
        if (description === 'Major') finishedBoxMajorDefect = number
        if (description === 'Minor') finishedBoxMinorDefect = number
      })

      const finishedBoxSummaryMinorDefects = []
      const finishedBoxSummaryMajorDefects = []
      finishedbox_defects.forEach((defect) => {
        const { name, description, percentage } = defect
        const defectObject = { name, percentage: ((percentage ?? 0) * 100).toFixed(2) }
        if (description === 'Major') finishedBoxSummaryMajorDefects.push(defectObject)
        if (description === 'Minor') finishedBoxSummaryMinorDefects.push(defectObject)
      })

      let cullsDoubleSpurs = { name: '', percentage: 0 }
      let cullsCulls = { name: '', percentage: 0 }
      let cullSummary = { name: '', percentage: 0 }
      cull_grade.forEach((cull) => {
        const { name, description, percentage } = cull
        const cullObject = { name, percentage: ((percentage ?? 0) * 100).toFixed(2) }
        if (description === 'Doubles/Spurs') cullsDoubleSpurs = cullObject
        if (description === 'Bad') cullsCulls = cullObject
        if (description === 'Good') cullSummary = cullObject
      })

      let peddlerGood = { name: '', percentage: 0 }
      let peddlerBad = { name: '', percentage: 0 }
      let peddlerPeddler = { name: '', percentage: 0 }
      peddler_grade.forEach((peddler) => {
        const { name, description, percentage } = peddler
        const peddlerObject = { name, percentage: ((percentage ?? 0) * 100).toFixed(2) }
        if (description === 'Peddler') peddlerPeddler = peddlerObject
        if (description === 'Bad') peddlerBad = peddlerObject
        if (description === 'Good') peddlerGood = peddlerObject
      })

      setGeneralData({
        general: {
          facility: currentFacility?.description,
          aveFirmness: average_firmness.toFixed(2),
          lotNumber: selectedLotName,
          grower: selectedGrowerName,
          stemCondition: stemcondition !== '' ? stemcondition : '-',
          aveBrix: brix.toFixed(2),
          variety: selectedVarietyName,
          reportDate,
          pickDate: pickDateFormatted,
          estimatedPO: numberToPercentage(((estimated_packout ?? 0) * 100).toFixed(2)),
          minorDefect: numberToPercentage(generalMinorDefect),
          majorDefect: numberToPercentage(generalMajorDefect),
          color: colorDataArray,
          machine: selectedMachine?.name
            ? selectedMachine?.name
            : selectedMachine?.description
            ? selectedMachine?.description
            : '',
        },
      })

      setUpstreamSummaryData({
        upstreamSummary: {
          numberSamples: upstream_sample_count,
          sampleSize: upstream_sample_total,
          minorDefect: upstreamMinorDefect,
          majorDefect: upstreamMajorDefect,
          upstreamSummaryMinorDefects,
          upstreamSummaryMajorDefects,
        },
      })

      setInlineSummaryData({
        inlineSummary: {
          numberSamples: inline_sample_count,
          sampleSize: inline_sample_total,
          minorDefect: inlineMinorDefect,
          majorDefect: inlineMajorDefect,
          inlineSummaryMinorDefects,
          inlineSummaryMajorDefects,
        },
      })

      setFinishedBoxSummaryData({
        finishedBoxSummary: {
          numberSamples: finishedbox_sample_count,
          sampleSize: finishedbox_sample_total,
          minorDefect: finishedBoxMinorDefect,
          majorDefect: finishedBoxMajorDefect,
          finishedBoxSummaryMinorDefects,
          finishedBoxSummaryMajorDefects,
        },
      })

      setCullsSummaryData({
        cullsSummary: {
          sampleSize: cull_sample_total,
          cullsDoubleSpurs,
          cullsCulls,
          cullSummary,
        },
      })

      setPeddlerSummaryData({
        peddlersSummary: {
          sampleSize: peddler_sample_total,
          peddlerGood,
          peddlerBad,
          peddlerPeddler,
        },
      })
      setFinishedboxAttachmentSamples(finishedbox_attachment_samples)

      setShowBackdrop(false)
      setShowReport(true)
      trackEvent('FILTE_BUTTON_CLICK', { email: userEmail })
    } catch (error) {
      setShowBackdrop(false)
      console.log(error)
    }
  }

  const printDocument = () => {
    if (!generalData?.general) return
    trackEvent('PRINT_REPORT_QUALITY_SUMMARY_CLICK', { email: userEmail })
    PdfMakerService.generateReport(
      `QualitySummary_${generalData?.general?.lotNumber?.trim() ?? ''}_${generalData?.general?.variety?.trim() ?? ''}`,
      'QualitySummary',
      {
        ...generalData,
        ...upstreamSummaryData,
        ...inlineSummaryData,
        ...finishedBoxSummaryData,
        ...cullsSummaryData,
        ...peddlerSummaryData,
        desTemplates,
      }
    )
  }
  const colorBreakDownExcel = generalData?.general?.color.map((color) => {
    const { name, value } = color
    return [
      {
        t: 's',
        s: { font: { sz: 11 } },
        v: name,
      },
      {
        t: 's',
        s: { font: { bold: true, sz: 11 } },
        v: value,
      },
    ]
  })

  const upstreamSummaryMinorDefectsExcel = upstreamSummaryData?.upstreamSummary?.upstreamSummaryMinorDefects?.map(
    (defect) => {
      const { name, percentage } = defect
      return [
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: name,
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: numberToPercentage(percentage),
        },
      ]
    }
  )
  const upstreamSummaryMajorDefectsExcel = upstreamSummaryData?.upstreamSummary?.upstreamSummaryMajorDefects?.map(
    (defect) => {
      const { name, percentage } = defect
      return [
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: name,
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: numberToPercentage(percentage),
        },
      ]
    }
  )

  const inlineSummaryMinorDefectsExcel = inlineSummaryData?.inlineSummary?.inlineSummaryMinorDefects?.map((defect) => {
    const { name, percentage } = defect
    return [
      {
        t: 's',
        s: { font: { sz: 11 } },
        v: name,
      },
      {
        t: 's',
        s: { font: { bold: true, sz: 11 } },
        v: numberToPercentage(percentage),
      },
    ]
  })
  const inlineSummaryMajorDefectsExcel = inlineSummaryData?.inlineSummary?.inlineSummaryMajorDefects?.map((defect) => {
    const { name, percentage } = defect
    return [
      {
        t: 's',
        s: { font: { sz: 11 } },
        v: name,
      },
      {
        t: 's',
        s: { font: { bold: true, sz: 11 } },
        v: numberToPercentage(percentage),
      },
    ]
  })

  const finishedBoxSummaryMinorDefectsExcel =
    finishedBoxSummaryData?.finishedBoxSummary?.finishedBoxSummaryMinorDefects?.map((defect) => {
      const { name, percentage } = defect
      return [
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: name,
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: numberToPercentage(percentage),
        },
      ]
    })
  const finishedBoxSummaryMajorDefectsExcel =
    finishedBoxSummaryData?.finishedBoxSummary?.finishedBoxSummaryMajorDefects?.map((defect) => {
      const { name, percentage } = defect
      return [
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: name,
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: numberToPercentage(percentage),
        },
      ]
    })

  const printfReportExcel = () => {
    trackEvent('EXPORT_EXCEL_REPORT_CLICK', { email: userEmail })
    const sizeDistributionData = [
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Facility',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: generalData.general?.facility ?? '',
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Machine',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: generalData?.general?.machine ?? '-',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Ave. Firmness',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: generalData.general?.aveFirmness ?? '',
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Lot #',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: generalData?.general?.lotNumber ?? '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Stem Condition',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: generalData.general?.stemCondition ?? '',
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Grower',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: generalData?.general?.grower ?? '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Ave. Brix',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: generalData.general?.aveBrix ?? '',
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Variety',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: generalData?.general?.variety ?? '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Packable Fruit',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: generalData.general?.estimatedPO ?? '',
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Report Date',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: generalData.general?.reportDate ?? '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Minor Defect',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: generalData.general?.minorDefect ?? '',
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Pick Date',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: generalData?.general?.pickDate ?? '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Major Defect',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: generalData.general?.majorDefect ?? '',
        },
      ],
      [],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Color Breakdown',
        },
      ],
      ...colorBreakDownExcel,
      [],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: `${desTemplates.TEMPLATE_LOC_UPS || 'Upstream'} Summary: ${numberToPercentage(
            (
              (upstreamSummaryData?.upstreamSummary?.minorDefect || 0) +
              (upstreamSummaryData?.upstreamSummary?.majorDefect || 0)
            ).toFixed(2)
          )}  (B.I.G)`,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '# of Samples',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: upstreamSummaryData?.upstreamSummary?.numberSamples,
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Sample Size',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: upstreamSummaryData?.upstreamSummary?.sampleSize,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Minor Defect',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: numberToPercentage(upstreamSummaryData?.upstreamSummary?.minorDefect.toFixed(2)),
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Major Defect',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: numberToPercentage(upstreamSummaryData?.upstreamSummary?.majorDefect.toFixed(2)),
        },
      ],
      [],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: `${desTemplates.TEMPLATE_LOC_UPS || 'Upstream'} Minor Defects`,
        },
      ],
      ...upstreamSummaryMinorDefectsExcel,
      [],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: `${desTemplates.TEMPLATE_LOC_UPS || 'Upstream'} Major Defects`,
        },
      ],
      ...upstreamSummaryMajorDefectsExcel,
      [],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: `${desTemplates.TEMPLATE_LOC_INL || 'In-Line'} Summary: 
          ${numberToPercentage(
            (
              (inlineSummaryData?.inlineSummary?.minorDefect || 0) +
              (inlineSummaryData?.inlineSummary?.majorDefect || 0)
            ).toFixed(2)
          )}
          (B.I.G)`,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '# of Samples',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: inlineSummaryData?.inlineSummary?.numberSamples,
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Sample Size',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: inlineSummaryData?.inlineSummary?.sampleSize,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Minor Defect',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: numberToPercentage(inlineSummaryData?.inlineSummary?.minorDefect.toFixed(2)),
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Major Defect',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: numberToPercentage(inlineSummaryData?.inlineSummary?.majorDefect.toFixed(2)),
        },
      ],
      [],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: `${desTemplates.TEMPLATE_LOC_INL || 'In-Line'} Minor Defects`,
        },
      ],
      ...inlineSummaryMinorDefectsExcel,
      [],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: `${desTemplates.TEMPLATE_LOC_INL || 'In-Line'} Major Defects`,
        },
      ],
      ...inlineSummaryMajorDefectsExcel,
      [],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: `${desTemplates.TEMPLATE_LOC_FIN || 'Finished Box'} Summary: 
          ${numberToPercentage(
            (
              (finishedBoxSummaryData?.finishedBoxSummary?.majorDefect || 0) +
              (finishedBoxSummaryData?.finishedBoxSummary?.minorDefect || 0)
            ).toFixed(2)
          )}
          (B.I.G)`,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '# of Samples',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: finishedBoxSummaryData?.finishedBoxSummary?.numberSamples,
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Sample Size',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: finishedBoxSummaryData?.finishedBoxSummary?.sampleSize,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Minor Defect',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: numberToPercentage(finishedBoxSummaryData?.finishedBoxSummary?.minorDefect.toFixed(2)),
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Major Defect',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: numberToPercentage(finishedBoxSummaryData?.finishedBoxSummary?.majorDefect.toFixed(2)),
        },
      ],
      [],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: `${desTemplates.TEMPLATE_LOC_FIN || 'Finished Box'} Minor Defects`,
        },
      ],
      ...finishedBoxSummaryMinorDefectsExcel,
      [],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: `${desTemplates.TEMPLATE_LOC_FIN || 'Finished Box'} Major Defects`,
        },
      ],
      ...finishedBoxSummaryMajorDefectsExcel,
      [],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: `${desTemplates.TEMPLATE_LOC_CUL || 'Culls'} Summary: 
          ${numberToPercentage(cullsSummaryData?.cullsSummary?.cullSummary?.percentage)}
          (G.I.B)`,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Sample Size',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: cullsSummaryData?.cullsSummary?.sampleSize,
        },
      ],

      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Culls',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: numberToPercentage(cullsSummaryData?.cullsSummary?.cullsCulls?.percentage),
        },
      ],

      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Dubs/Spur',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: numberToPercentage(cullsSummaryData?.cullsSummary?.cullsDoubleSpurs?.percentage),
        },
      ],
      [],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: `${desTemplates.TEMPLATE_LOC_PED || 'Peddler'} Summary:
          ${numberToPercentage(peddlerSummaryData?.peddlersSummary?.peddlerGood?.percentage)}(G.I.P)`,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Sample Size',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: peddlerSummaryData?.peddlersSummary?.sampleSize,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Peddler',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: numberToPercentage(peddlerSummaryData?.peddlersSummary?.peddlerPeddler?.percentage),
        },
      ],

      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Bad',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: numberToPercentage(peddlerSummaryData?.peddlersSummary?.peddlerBad?.percentage),
        },
      ],
    ]

    const sizeDistribution = XLSXStyle.utils.aoa_to_sheet(sizeDistributionData)
    const sizeDistributionCols = [{ wch: 50 }, { wch: 18 }, { wch: 18 }]
    sizeDistribution['!cols'] = sizeDistributionCols
    const excelFile = XLSXStyle.utils.book_new()
    XLSXStyle.utils.book_append_sheet(excelFile, sizeDistribution, 'Quality Summary')
    XLSXStyle.writeFile(
      excelFile,
      `QualitySummary_${generalData?.general?.lotNumber?.trim() ?? ''}_${
        generalData?.general?.variety?.trim() ?? ''
      }.xlsx`
    )
  }

  return (
    <Grid container>
      {!showReport ? null : <PrintButton printDocument={printDocument} printfReportExcel={printfReportExcel} />}
      <ReportInputs loadData={loadQualityReport} isLoading={showBackdrop} />
      {!showReport ? null : (
        <>
          <Grid xs={12} item>
            <Typography align="center" variant="h6">
              {t('quality_summary')}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow key="facility">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('facility')}</TableCell>
                    <TableCell className={classes.tableCell} colSpan={3}>
                      {generalData.general?.facility}
                    </TableCell>
                  </TableRow>
                  <TableRow key="machine">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('machine')}</TableCell>
                    <TableCell className={classes.tableCell}>{generalData.general?.machine ?? '-'}</TableCell>
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      Ave. {t('firmness')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{generalData.general?.aveFirmness}</TableCell>
                  </TableRow>
                  <TableRow key="lotNumber">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('lot')} #</TableCell>
                    <TableCell className={classes.tableCell}>{generalData.general?.lotNumber}</TableCell>
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('stem_condition')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{generalData.general?.stemCondition}</TableCell>
                  </TableRow>
                  <TableRow key="grower">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('grower')}</TableCell>
                    <TableCell className={classes.tableCell}>{generalData.general?.grower}</TableCell>
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Ave. Brix</TableCell>
                    <TableCell className={classes.tableCell}>{generalData.general?.aveBrix}</TableCell>
                  </TableRow>
                  <TableRow key="variety">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('variety')}</TableCell>
                    <TableCell className={classes.tableCell}>{generalData.general?.variety}</TableCell>
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('packaged_fruit')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{generalData.general?.estimatedPO}</TableCell>
                  </TableRow>
                  <TableRow key="reportDate">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('report_date')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{generalData.general?.reportDate}</TableCell>
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('minor_defect')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{generalData.general?.minorDefect}</TableCell>
                  </TableRow>
                  <TableRow key="pickDate">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('pick_date')}</TableCell>
                    <TableCell className={classes.tableCell}>{generalData.general?.pickDate}</TableCell>
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('major_defect')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{generalData.general?.majorDefect}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid xs={12} className={classes.grid} item>
            <Typography variant="h6">{t('color_breakdown')}</Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {generalData?.general?.color.map((color) => {
                    const { name, value } = color
                    return (
                      <TableRow key={color.name}>
                        <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{name}</TableCell>
                        <TableCell className={classes.tableCell}>{value}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid xs={12} className={classes.grid} item>
            <Typography variant="h6">
              {desTemplates.TEMPLATE_LOC_UPS || t('upstream')} {t('summary')}:{' '}
              {numberToPercentage(
                (
                  (upstreamSummaryData?.upstreamSummary?.minorDefect || 0) +
                  (upstreamSummaryData?.upstreamSummary?.majorDefect || 0)
                ).toFixed(2)
              )}{' '}
              (B.I.G)
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow key="numberSamples">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      # {t('of_samples')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {upstreamSummaryData?.upstreamSummary?.numberSamples}
                    </TableCell>
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('sample_size')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {upstreamSummaryData?.upstreamSummary?.sampleSize}
                    </TableCell>
                  </TableRow>
                  <TableRow key="minorDefect">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('minor_defect')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {numberToPercentage(upstreamSummaryData?.upstreamSummary?.minorDefect.toFixed(2))}
                    </TableCell>
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('major_defect')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {numberToPercentage(upstreamSummaryData?.upstreamSummary?.majorDefect.toFixed(2))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid xs={6} className={classes.grid} item>
            <Typography variant="h6">
              {desTemplates.TEMPLATE_LOC_UPS || t('upstream')} {t('minor_defects')}
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {upstreamSummaryData?.upstreamSummary?.upstreamSummaryMinorDefects?.map((defect) => {
                    const { name, percentage } = defect
                    return (
                      <TableRow key={name}>
                        <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{name}</TableCell>
                        <TableCell className={classes.tableCell}>{numberToPercentage(percentage)}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid xs={6} className={classes.grid} item>
            <Typography variant="h6">
              {desTemplates.TEMPLATE_LOC_UPS || t('upstream')} {t('major_defects')}
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {upstreamSummaryData?.upstreamSummary?.upstreamSummaryMajorDefects?.map((defect) => {
                    const { name, percentage } = defect
                    return (
                      <TableRow key={name}>
                        <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{name}</TableCell>
                        <TableCell className={classes.tableCell}>{numberToPercentage(percentage)}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid xs={12} className={classes.grid} item>
            <Typography variant="h6">
              {desTemplates.TEMPLATE_LOC_INL || t('in_line')} {t('summary')}:{' '}
              {numberToPercentage(
                (
                  (inlineSummaryData?.inlineSummary?.minorDefect || 0) +
                  (inlineSummaryData?.inlineSummary?.majorDefect || 0)
                ).toFixed(2)
              )}{' '}
              (B.I.G)
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow key="numberSamples">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      # {t('of_samples')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {inlineSummaryData?.inlineSummary?.numberSamples}
                    </TableCell>
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('sample_size')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{inlineSummaryData?.inlineSummary?.sampleSize}</TableCell>
                  </TableRow>
                  <TableRow key="minorDefect">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('minor_defect')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {numberToPercentage(inlineSummaryData?.inlineSummary?.minorDefect.toFixed(2))}
                    </TableCell>
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('major_defect')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {numberToPercentage(inlineSummaryData?.inlineSummary?.majorDefect.toFixed(2))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid xs={6} className={classes.grid} item>
            <Typography variant="h6">
              {desTemplates.TEMPLATE_LOC_INL || t('in_line')} {t('minor_defects')}
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {inlineSummaryData?.inlineSummary?.inlineSummaryMinorDefects?.map((defect) => {
                    const { name, percentage } = defect
                    return (
                      <TableRow key={name}>
                        <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{name}</TableCell>
                        <TableCell className={classes.tableCell}>{numberToPercentage(percentage)}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid xs={6} className={classes.grid} item>
            <Typography variant="h6">
              {desTemplates.TEMPLATE_LOC_INL || t('in_line')} {t('major_defects')}
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {inlineSummaryData?.inlineSummary?.inlineSummaryMajorDefects?.map((defect) => {
                    const { name, percentage } = defect
                    return (
                      <TableRow key={name}>
                        <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{name}</TableCell>
                        <TableCell className={classes.tableCell}>{numberToPercentage(percentage)}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid xs={12} className={classes.grid} item>
            <Typography variant="h6">
              {desTemplates.TEMPLATE_LOC_FIN || t('finished_box')} {t('summary')}:{' '}
              {numberToPercentage(
                (
                  (finishedBoxSummaryData?.finishedBoxSummary?.majorDefect || 0) +
                  (finishedBoxSummaryData?.finishedBoxSummary?.minorDefect || 0)
                ).toFixed(2)
              )}{' '}
              (B.I.G)
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow key="numberSamples">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      # {t('of_samples')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {finishedBoxSummaryData?.finishedBoxSummary?.numberSamples}
                    </TableCell>
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('sample_size')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {finishedBoxSummaryData?.finishedBoxSummary?.sampleSize}
                    </TableCell>
                  </TableRow>
                  <TableRow key="minorDefect">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('minor_defect')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {numberToPercentage(finishedBoxSummaryData?.finishedBoxSummary?.minorDefect.toFixed(2))}
                    </TableCell>
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('major_defect')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {numberToPercentage(finishedBoxSummaryData?.finishedBoxSummary?.majorDefect.toFixed(2))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid xs={6} className={classes.grid} item>
            <Typography variant="h6">
              {desTemplates.TEMPLATE_LOC_FIN || t('finished_box')} {t('minor_defects')}
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {finishedBoxSummaryData?.finishedBoxSummary?.finishedBoxSummaryMinorDefects?.map((defect) => {
                    const { name, percentage } = defect
                    return (
                      <TableRow key={name}>
                        <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{name}</TableCell>
                        <TableCell className={classes.tableCell}>{numberToPercentage(percentage)}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid xs={6} className={classes.grid} item>
            <Typography variant="h6">
              {desTemplates.TEMPLATE_LOC_FIN || t('finished_box')} {t('major_defects')}
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {finishedBoxSummaryData?.finishedBoxSummary?.finishedBoxSummaryMajorDefects?.map((defect) => {
                    const { name, percentage } = defect
                    return (
                      <TableRow key={name}>
                        <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{name}</TableCell>
                        <TableCell className={classes.tableCell}>{numberToPercentage(percentage)}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid xs={12} className={classes.grid} item>
            <Typography variant="h6">
              {desTemplates.TEMPLATE_LOC_FIN || t('finished_box')} {t('attachments')}
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow key="location">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('users')}</TableCell>
                    <TableCell className={classes.tableCell}>
                      {desTemplates.TEMPLATE_LOC_FIN || t('finished_box')} {t('attachments')}
                    </TableCell>
                  </TableRow>
                  {finishedboxAttachmentSamples?.map((sp) => (
                    <TableRow key={sp.created}>
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} title={sp?.user?.email}>
                        {sp?.user?.name}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <ImageList cols={6} rowHeight={164}>
                          {sp?.attachments?.map((att) => (
                            <ImageListItem
                              key={att}
                              onClick={() => window.open(att, '_blank')}
                              style={{ cursor: 'pointer' }}
                            >
                              <img src={att} alt={att} loading="lazy" />
                            </ImageListItem>
                          ))}
                        </ImageList>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid xs={12} md={6} className={classes.grid} item>
            <Typography variant="h6">
              {desTemplates.TEMPLATE_LOC_CUL || t('culls')} {t('summary')}:{' '}
              {numberToPercentage(cullsSummaryData?.cullsSummary?.cullSummary?.percentage)} (G.I.B)
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow key="sampleSize">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('sample_size')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{cullsSummaryData?.cullsSummary?.sampleSize}</TableCell>
                  </TableRow>
                  <TableRow key="minorDefect">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('culls')}</TableCell>
                    <TableCell className={classes.tableCell}>
                      {numberToPercentage(cullsSummaryData?.cullsSummary?.cullsCulls?.percentage)}
                    </TableCell>
                  </TableRow>
                  <TableRow key="bruise">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Dubs/Spur</TableCell>
                    <TableCell className={classes.tableCell}>
                      {numberToPercentage(cullsSummaryData?.cullsSummary?.cullsDoubleSpurs?.percentage)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid xs={12} md={6} className={classes.grid} item>
            <Typography variant="h6">
              {desTemplates.TEMPLATE_LOC_PED || t('peddler')} {t('summary')}:{' '}
              {numberToPercentage(peddlerSummaryData?.peddlersSummary?.peddlerGood?.percentage)}(G.I.P)
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow key="sampleSize">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('sample_size')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {peddlerSummaryData?.peddlersSummary?.sampleSize}
                    </TableCell>
                  </TableRow>
                  <TableRow key="minorDefect">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('peddler')}</TableCell>
                    <TableCell className={classes.tableCell}>
                      {numberToPercentage(peddlerSummaryData?.peddlersSummary?.peddlerPeddler?.percentage)}
                    </TableCell>
                  </TableRow>
                  <TableRow key="bruise">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('bad')}</TableCell>
                    <TableCell className={classes.tableCell}>
                      {numberToPercentage(peddlerSummaryData?.peddlersSummary?.peddlerBad?.percentage)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      )}
      <SimpleBackdrop open={showBackdrop} />
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  selectedLot: state.reports.selectedLot,
  selectedLotName: state.reports.selectedLotName,
  selectedVariety: state.reports.selectedVariety,
  selectedVarietyName: state.reports.selectedVarietyName,
  selectedGrowerName: state.reports.selectedGrowerName,
  selectedStartDate: state.reports.selectedStartDate,
  selectedEndDate: state.reports.selectedEndDate,
  currentFacility: state.facility.currentFacility,
  selectedMachine: state.reports.selectedMachine,
  userEmail: state.user.email,
})

export default connect(mapStateToProps)(Quality)
