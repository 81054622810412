import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { DateTime } from 'luxon'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import FormDialog from '../../components/form-dialog/form-dialog.component'
import ConfirmDialog from '../../components/confirm-dialog/confirm-dialog.component'
import ActionButtons from '../../components/action-buttons/action-buttons.component'
import DialogAlert from '../../components/alert/alert.component'
import TableContainer from '../../components/table-container/table-container'
import MultipleSelect from '../../components/multi-select/multi-select'
import ActiveToggle from '../../components/active-toggle/active-toggle.component'
import ManyToManyInput from '../../components/many-many-input/many-many-input.component'

import { buildPayload, getManyToManyData } from '../../shared/utils/utils'

import RequestService from '../../services/request/request-service'

import {
  DEFAULT_DEPENDENCIES_ERROR_MESSAGE,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from '../../shared/constants/messages'
import { DELETE_METHOD, POST_METHOD, PUT_METHOD } from '../../shared/constants/requests'
import { ALERT_TYPES, INCLUDE_INACTIVE_FLAG } from '../../shared/constants/general'
import {
  ACTIONS_COLUMN,
  BASE_TABLE_COLUMNS,
  DEFAULT_ROW_DATA,
  DEFAULT_TABLE_CONFIGURATION,
} from '../../shared/constants/table'
import { SHIFT_QUERY, USERS_SHIFT_QUERY } from '../../shared/constants/queries'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'

const Shift = ({ userEmail }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const [openDialog, setOpen] = useState(false)
  const [openDelDialog, setDelOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalType, setModalType] = useState('Add new')
  const [rowData, setRowData] = useState(DEFAULT_ROW_DATA)
  const [rows, setRows] = useState([])
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [openAlert, setOpenAlert] = useState(false)
  const [severity, setSeverity] = useState(ALERT_TYPES.success)
  const [deletable, setDeletable] = useState(true)
  const [showActive, setShowActive] = useState(true)

  const tableWithDate = [
    { field: 'name', headerName: 'name', minWidth: 115, flex: 1 },
    { field: 'description', headerName: 'description', minWidth: 150, flex: 1 },
    {
      field: 'active',
      headerName: 'status',
      minWidth: 120,
      renderCell: (params) => (params.value ? 'Active' : 'Inactive'),
      flex: 1,
    },
    {
      field: 'start_time',
      headerName: 'start_time',
      minWidth: 115,
      flex: 1,
      renderCell: (params) => (params.value ? DateTime.fromISO(params.value).toFormat('hh:mm') : ''),
    },
    {
      field: 'end_time',
      headerName: 'end_time',
      minWidth: 115,
      flex: 1,
      renderCell: (params) => (params.value ? DateTime.fromISO(params.value).toFormat('hh:mm') : ''),
    },
  ]
  // columns definition for table
  const translatedColumns = tableWithDate.map((column) => ({
    ...column,
    headerName: t(column.headerName),
  }))

  const columns = [
    ...translatedColumns,
    {
      ...ACTIONS_COLUMN,
      headerName: t(ACTIONS_COLUMN.headerName),
      renderCell: (params) => ActionButtons(params.row, handleClickOpen, handleClickDelOpen),
    },
  ]

  useEffect(() => {
    trackEvent(LOG_PAGE_LOADS.LOAD_LOCATION_PAGE, { email: userEmail })
  }, [])

  // load data
  useEffect(() => {
    loadData()
  }, [tableConf, showActive])

  const onError = (errorMessage) => {
    console.error(errorMessage)
    setOpenAlert(true)
    setSeverity(ALERT_TYPES.error)
    setLoading(false)
  }

  const loadData = async () => {
    try {
      setLoading(true)
      setRows([])
      const url = `${SHIFT_QUERY}?${!showActive ? INCLUDE_INACTIVE_FLAG : ''}&startRowIndex=${
        tableConf.page - 1
      }&pageSize=10000`
      const response = await RequestService.Get(url, history)
      setRows(response.data)
    } catch (error) {
      onError(error)
    } finally {
      setLoading(false)
    }
  }

  // insert and edit
  const insertEditData = async (method, data) => {
    setLoading(true)

    let url = `${SHIFT_QUERY}`
    let response
    if (method === DELETE_METHOD) {
      url = `${url}?id=${data.id}`
      response = await RequestService.Delete(url, history)
      if (response?.hasError) {
        onError(response.errorMessage)
        return
      }
    } else {
      if (method === POST_METHOD) {
        response = await RequestService.Post(url, history, data)
      }
      if (method === PUT_METHOD) {
        response = await RequestService.Put(url, history, data)
      }

      if (response?.hasError) {
        onError(response.errorMessage)
        return
      }
    }

    setTableConf({ ...tableConf })
    setSeverity(ALERT_TYPES.success)
    setOpenAlert(true)
    setLoading(false)
    setOpen(false)
  }

  // Form Dialog handler
  const handleClickOpen = (type, params) => async () => {
    setLoading(true)
    if (type === 'Edit') {
      trackEvent('EDIT_CLICK', { email: userEmail })
      setRowData({
        id: params.id,
        name: params.name,
        description: params.description,
        active: params.active,
        start_time: params.start_time,
        end_time: params.end_time,
      })
    } else {
      trackEvent('ADD_CLICK', { email: userEmail })
      setRowData(DEFAULT_ROW_DATA)
    }
    setModalType(type)
    setOpen(true)
    setLoading(false)
  }

  const handleSubmit = (event) => {
    trackEvent('SUBMIT_CLICK', { email: userEmail })
    event.preventDefault()
    const data = { ...rowData }
    const method = modalType !== 'Edit' ? POST_METHOD : PUT_METHOD
    insertEditData(method, data)
  }

  // Confirm Dialog handlers
  const handleClickDelOpen = (params) => () => {
    trackEvent('DELETE_CLICK', { email: userEmail })
    setRowData({
      id: params.id,
      name: params.name,
      description: params.description,
      active: !params.active,
      start_time: params.start_time,
      end_time: params.end_time,
    })
    setDelOpen(true)
  }

  const onClickDelConfirmation = async () => {
    const data = { ...rowData }
    const url = `${USERS_SHIFT_QUERY}?id=${data.id}`
    const response = await RequestService.Get(url, history)
    if (response?.hasError) {
      setSeverity(ALERT_TYPES.error)
      setOpenAlert(true)
    } else if (response.data.length === 0) {
      insertEditData(DELETE_METHOD, data)
      setDelOpen(false)
    } else {
      setDeletable(false)
    }
  }

  // Form on change
  const handleChange = (event) => {
    const { name, value } = event.target
    setRowData({
      ...rowData,
      [name]: value,
    })
  }

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') return
    setOpenAlert(false)
  }

  return (
    <div>
      <Box pt={5} pb={3}>
        <Typography component="h1" variant="h5" color="primary">
          {t('shift')}
        </Typography>
      </Box>
      <TableContainer
        haveSearchInput="true"
        handleClickOpen={() => handleClickOpen('Add new', null)}
        columns={columns}
        rows={rows}
        tableConfiguration={tableConf}
        setTableConf={setTableConf}
        onToggleClick={() => setShowActive(!showActive)}
        showActive={showActive}
        loading={loading}
      />
      <FormDialog
        title={`${modalType} shift`}
        open={openDialog}
        onClose={() => {
          setOpen(false)
        }}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <TextField
          defaultValue={rowData.name}
          variant="outlined"
          margin="normal"
          name="name"
          label={t('name')}
          type="text"
          fullWidth
          required
          onChange={handleChange}
        />
        <TextField
          defaultValue={rowData.description}
          variant="outlined"
          margin="normal"
          name="description"
          label={t('description')}
          type="text"
          fullWidth
          onChange={handleChange}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
            value={rowData.start_time}
            variant="outlined"
            margin="normal"
            name="start_time"
            label={t('start_time')}
            fullWidth
            inputVariant="outlined"
            onChange={(date) => {
              const startDate = DateTime.fromJSDate(date)
              handleChange({
                target: { name: 'start_time', value: startDate.toISO({ includeOffset: false }) },
              })
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <Icon>calendar_today</Icon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
            value={rowData.end_time}
            variant="outlined"
            margin="normal"
            name="end_time"
            label={t('end_time')}
            fullWidth
            inputVariant="outlined"
            onChange={(date) => {
              const endDate = DateTime.fromJSDate(date)
              handleChange({
                target: { name: 'end_time', value: endDate.toISO({ includeOffset: false }) },
              })
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <Icon>calendar_today</Icon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </MuiPickersUtilsProvider>

        {modalType === 'Edit' && (
          <ActiveToggle value={rowData.active} onToggle={() => setRowData({ ...rowData, active: !rowData.active })} />
        )}
      </FormDialog>

      <ConfirmDialog
        title={t('deactivate_shift')}
        okButton={t('deactivate')}
        open={openDelDialog}
        onClose={() => {
          setDelOpen(false)
          setDeletable(true)
        }}
        onOk={onClickDelConfirmation}
      >
        {t('do_you_really_want_to_deactivate')} {rowData.name} ?
        {!deletable && (
          <Alert style={{ marginTop: 10 }} severity="error">
            {t(DEFAULT_DEPENDENCIES_ERROR_MESSAGE)}
          </Alert>
        )}
      </ConfirmDialog>
      <DialogAlert onClose={handleErrorClose} open={openAlert} severity={severity}>
        {severity === ALERT_TYPES.success ? t(DEFAULT_SUCCESS_MESSAGE) : t(DEFAULT_ERROR_MESSAGE)}
      </DialogAlert>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
})

export default connect(mapStateToProps, null)(Shift)
