export const LOG_PAGE_LOADS = {
  LOAD_FORGOT_PASSWORD_PAGE: 'LOAD_FORGOT_PASSWORD_PAGE',
  LOAD_LOGIN_PAGE: 'LOAD_LOGIN_PAGE',
  LOAD_DASHBOARD_PAGE: 'LOAD_DASHBOARD_PAGE',
  LOAD_STAFF_PAGE: 'LOAD_STAFF_PAGE',
  LOAD_STAFF_OVERVIEW_PAGE: 'LOAD_STAFF_OVERVIEW_PAGE',
  LOAD_LOT_INFORMATION_PAGE: 'LOAD_LOT_INFORMATION_PAGE',
  LOAD_DEFECT_PARAMETERS_PAGE: 'LOAD_DEFECT_PARAMETERS_PAGE',
  LOAD_CATEGORY_TYPE_PAGE: 'LOAD_CATEGORY_TYPE_PAGE',
  LOAD_CATEGORY_PAGE: 'LOAD_CATEGORY_PAGE',
  LOAD_UNIT_TYPE_PAGE: 'LOAD_UNIT_TYPE_PAGE',
  LOAD_UNIT_PAGE: 'LOAD_UNIT_PAGE',
  LOAD_REGION_PAGE: 'LOAD_REGION_PAGE',
  LOAD_FACILITY_PAGE: 'LOAD_FACILITY_PAGE',
  LOAD_LOCATION_PAGE: 'LOAD_LOCATION_PAGE',
  LOAD_OUTLET_PAGE: 'LOAD_OUTLET_PAGE',
  LOAD_GROWER_PAGE: 'LOAD_GROWER_PAGE',
  LOAD_SIZE_PAGE: 'LOAD_SIZE_PAGE',
  LOAD_DEFECT_PAGE: 'LOAD_DEFECT_PAGE',
  LOAD_VARIETY_PAGE: 'LOAD_VARIETY_PAGE',
  LOAD_COLOR_PAGE: 'LOAD_COLOR_PAGE',
  LOAD_STEM_CONDITION_PAGE: 'LOAD_STEM_CONDITION_PAGE',
  LOAD_SAMPLE_TYPE: 'LOAD_SAMPLE_TYPE_PAGE',
  LOAD_USERS_PAGE: 'LOAD_USERS_PAGE',
  LOAD_REPORTS_PAGE: 'LOAD_REPORTS_PAGE',
  LOAD_NOT_FOUND_PAGE: 'LOAD_NOT_FOUND_PAGE',
}

export const LOG_ACTIONS = {
  LOGIN_SUBMITTED: 'LOGIN_SUBMITTED',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGIN_SUCCESSFUL: 'LOGIN_SUCCESSFUL',
  LOGOUT_SUCCESSFUL: 'LOGOUT_SUCCESSFUL',
}

export const LOG_SUBSCRIPTION_ACTIONS = {
  CREATED_DASHBOARD_SUBSCRIPTION_SUCCESSFULLY: 'CREATED_DASHBOARD_SUBSCRIPTION_SUCCESSFULLY',
  CREATED_ALERT_SUBSCRIPTION_SUCCESSFULLY: 'CREATED_ALERT_SUBSCRIPTION_SUCCESSFULLY',
  CREATED_PACKMAN_SUBSCRIPTION_SUCCESSFULLY: 'CREATED_PACKMAN_SUBSCRIPTION_SUCCESSFULLY',
}

export const LOG_MULTISELECT_UPDATE = {
  UPDATED_VIA_MULTISELECT: 'UPDATED_VIA_MULTISELECT',
}

export const LOG_API_ACTIONS = {
  CALLED_GET_METHOD: 'CALLED_GET_METHOD',
  CALLED_POST_METHOD: 'CALLED_POST_METHOD',
  CALLED_PUT_METHOD: 'CALLED_PUT_METHOD',
  CALLED_DELETE_METHOD: 'CALLED_DELETE_METHOD',
  API_ERROR: 'API_ERROR',
}
