import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'

import reportsSlice from '../../redux/reports/reports.slice'

import SearchableInput from '../searchable-input/searchable-input.component'
import SingleDateTimePicker from '../single-datetimepicker/simple-datetimepicker.component'
import PrimaryButton from '../button/button.component'

import { GROWER_QUERY, LOTS_FACILITIES_QUERY, LOTS_VARIETIES_QUERY } from '../../shared/constants/queries'
import useMachineQuery from '../../shared/hooks/useMachineQuery'
import RequestService from '../../services/request/request-service'
import useVarietyQuery from '../../shared/hooks/useVarietyQuery'

const ReportInputs = React.memo(
  ({
    selectedLot,
    setSelectedLot,
    setSelectedLotName,
    selectedVariety,
    setSelectedVariety,
    setSelectedVarietyName,
    setSelectedGrower,
    setSelectedGrowerName,
    currentFacility,
    selectedStartDate,
    setSelectedStartDate,
    selectedStartDateISO,
    setSelectedStartDateISO,
    selectedEndDate,
    setSelectedEndDate,
    selectedEndDateISO,
    setSelectedEndDateISO,
    loadData,
    isLoading,
    selectedMachine,
    setSelectedMachine,
    currentMachine,
    currentLot,
  }) => {
    const history = useHistory()
    const [lots, setLots] = useState([])
    const [varieties, setVarieties] = useState([])
    const { t } = useTranslation()

    const [growers, setGrowers] = useState([])
    const machineQuery = useMachineQuery({
      byFacility: true,
      filter: { id_facility: currentFacility.id },
    })
    const varietyQuery = useVarietyQuery()

    useEffect(() => {
      if (selectedMachine) {
        if (selectedMachine.id === 0 || selectedLot === 0) {
          if (currentMachine) {
            setSelectedMachine(currentMachine)
          }
          if (currentLot) {
            setSelectedLot(currentLot.lot_number ?? 0)
            setSelectedVariety(currentLot.id_variety ?? 0)
          }
        }
      }
    }, [currentMachine, currentLot])

    useEffect(async () => {
      try {
        if (selectedLot && lots && lots.length > 0 && varieties && varieties.length > 0) {
          const lot = lots.find((lot) => lot.id === selectedLot)
          const idGrower = lot?.id_grower || 0
          setSelectedLotName(lot?.name || '-')
          const url = `${GROWER_QUERY}?id=${idGrower}&showInactive=false`
          const response = await RequestService.Get(url, history)
          setGrowers(response.data)
          const growerName = response.data.find((grower) => grower.id === idGrower)?.description || '-'
          setSelectedGrower(idGrower)
          setSelectedGrowerName(growerName)
          setSelectedVarietyName(varieties.find((varieties) => varieties.id === selectedVariety)?.name)
        }
      } catch (error) {
        console.error(error)
      }
    }, [varieties, lots, selectedLot])

    useEffect(async () => {
      const loadData = async () => {
        try {
          // eslint-disable-next-line max-len
          let url = `${LOTS_FACILITIES_QUERY}?id=${currentFacility.id}&filterByFirst=false&startRowIndex=0&pageSize=100000&includeInactive=false`
          const tskLots = RequestService.Get(url, history)

          machineQuery.refetch()

          const [resLots] = await Promise.all([tskLots])

          const dataLots = resLots.data.map((lot) => lot.first).filter((x) => x.active === true)
          dataLots.unshift({
            description: 'All',
            id: 0,
          })
          await setLots(dataLots)

          if ((selectedLot !== 0, lots.length > 0)) {
            const lot = lots.find((lot) => lot.id === selectedLot)
            const idGrower = lot?.id_grower || 0
            url = `${GROWER_QUERY}?id=${idGrower}&showInactive=false`
            const response = await RequestService.Get(url, history)
            setGrowers(response.data)
            const growerName = response.data.find((grower) => grower.id === idGrower)?.description || '-'
            setSelectedGrowerName(growerName)
          }
        } catch (error) {
          console.error(error)
        }
      }
      await loadData()
    }, [])

    useEffect(async () => {
      const loadData = async () => {
        try {
          let url = `${LOTS_VARIETIES_QUERY}?id=${selectedLot}&startRowIndex=0&pageSize=100000`
          let response = await RequestService.Get(url, history)
          const dataVarieties = response.data.map((variety) => variety.second).filter((x) => x.active === true)
          dataVarieties.unshift({
            description: 'All',
            id: 0,
          })
          setVarieties(dataVarieties)
          if ((selectedLot !== 0, lots.length > 0)) {
            const lot = lots.find((lot) => lot.id === selectedLot)
            const idGrower = lot?.id_grower || 0
            url = `${GROWER_QUERY}?id=${idGrower}&showInactive=false`
            response = await RequestService.Get(url, history)
            setGrowers(response.data)
            const growerName = response.data.find((grower) => grower.id === idGrower)?.description || '-'
            setSelectedGrowerName(growerName)
          }
        } catch (error) {
          console.error(error)
        }
      }
      if (selectedLot) await loadData()
    }, [selectedLot])

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={2}>
          <SearchableInput
            disabled={machineQuery.data.length === 0}
            value={machineQuery?.data?.find((machine) => machine.id === selectedMachine?.id) ?? 0}
            options={machineQuery.data}
            onChange={(event) => {
              const { value } = event.target
              const machine = machineQuery?.data?.find((x) => x.id === value)
              setSelectedMachine(machine)
            }}
            label={t('machine')}
            id="id_machine"
            name="id_machine"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <SearchableInput
            label={t('lot_number')}
            id="lots"
            options={lots}
            onChange={(event) => {
              setSelectedLot(event.target.value)
              if (event.target.value === 0) {
                setSelectedLotName('-')
                setSelectedGrower(0)
                setSelectedGrowerName('-')
                setSelectedVariety(0)
                setSelectedVarietyName('-')
              } else {
                setSelectedVariety(0)
                setSelectedVarietyName('-')
                const lot = lots.find((lot) => lot.id === event.target.value)
                const idGrower = lot?.id_grower || 0
                const growerName = growers.find((grower) => grower.id === idGrower)?.description || '1'
                setSelectedLotName(lot?.name || '-')
                setSelectedGrower(idGrower)
                setSelectedGrowerName(growerName)
              }
            }}
            value={lots.find((lot) => lot.id === selectedLot) ?? 0}
            disabled={false}
            showName
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <SearchableInput
            label={t('varieties')}
            id="varieties"
            options={selectedLot === 0 ? varietyQuery.data : varieties}
            onChange={(event) => {
              setSelectedVariety(event.target.value)
              if (event.target.value === 0) {
                setSelectedVarietyName('-')
              } else {
                const arr = selectedLot === 0 ? varietyQuery.data : varieties
                setSelectedVarietyName(arr.find((varieties) => varieties.id === event.target.value).name)
              }
            }}
            value={varieties.find((variety) => variety.id === selectedVariety) ?? 0}
            // disabled={selectedLot === 0}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SingleDateTimePicker
            isLoadingdisabled={isLoading}
            style={{ width: '100%' }}
            label={t('start_date')}
            name="startDate"
            value={selectedStartDate}
            disableFuture
            onChange={(date) => {
              const startDate = DateTime.fromJSDate(date)
              setSelectedStartDateISO(startDate.toISODate())
              setSelectedStartDate(startDate.toISO({ includeOffset: false }))
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SingleDateTimePicker
            disabled={isLoading}
            style={{ width: '100%' }}
            label={t('end_date')}
            name="endDate"
            value={selectedEndDate}
            disableFuture
            onChange={(date) => {
              const endDate = DateTime.fromJSDate(date)
              setSelectedEndDateISO(endDate.toISODate())
              setSelectedEndDate(endDate.toISO({ includeOffset: false }))
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
          <PrimaryButton
            variant="contained"
            disabled={
              varietyQuery.isFetching ||
              isLoading ||
              selectedEndDateISO < selectedStartDateISO ||
              selectedLot === null ||
              selectedVariety === null
            }
            onClick={() => loadData()}
          >
            {t('filter')}
          </PrimaryButton>
        </Grid>
      </Grid>
    )
  }
)

const mapStateToProps = (state) => ({
  selectedLot: state.reports.selectedLot,
  selectedVariety: state.reports.selectedVariety,
  selectedStartDate: state.reports.selectedStartDate,
  selectedEndDate: state.reports.selectedEndDate,
  selectedStartDateISO: state.reports.selectedStartDateISO,
  selectedEndDateISO: state.reports.selectedEndDateISO,
  currentFacility: state.facility.currentFacility,
  selectedMachine: state.reports.selectedMachine,
  currentMachine: state.machine,
  currentLot: state.lots.currentLot,
})

const mapDispatchToProps = (dispatch) => ({
  setSelectedLot: (lot) => dispatch(reportsSlice.actions.updateSelectedLot(lot)),
  setSelectedLotName: (lotName) => dispatch(reportsSlice.actions.updateSelectedLotName(lotName)),
  setSelectedVariety: (variety) => dispatch(reportsSlice.actions.updateSelectedVariety(variety)),
  setSelectedVarietyName: (varietyName) => dispatch(reportsSlice.actions.updateSelectedVarietyName(varietyName)),
  setSelectedGrower: (grower) => dispatch(reportsSlice.actions.updateSelectedGrower(grower)),
  setSelectedGrowerName: (growerName) => dispatch(reportsSlice.actions.updateSelectedGrowerName(growerName)),
  setSelectedStartDate: (startDate) => dispatch(reportsSlice.actions.updateSelectedStartDate(startDate)),
  setSelectedEndDate: (endDate) => dispatch(reportsSlice.actions.updateSelectedEndDate(endDate)),
  setSelectedStartDateISO: (startDateISO) => dispatch(reportsSlice.actions.updateSelectedStartDateISO(startDateISO)),
  setSelectedEndDateISO: (endDateISO) => dispatch(reportsSlice.actions.updateSelectedEndDateISO(endDateISO)),
  setSelectedMachine: (machine) => dispatch(reportsSlice.actions.updateSelectedMachine(machine)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportInputs)
